import React from 'react'
import BargainTicketEvent from './bargain-ticket-event'
import { addTicketToBasket } from '../../actions/shopping-basket'
import { Translate } from 'react-redux-i18n'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import withSortHeader from '../../components/with-sort-header'
import moment from 'moment'

const BargainTicketList = ({
  tickets,
  dispatch,
  events,
  removeTicketFromListWrapper,
  sortKey
}) => {
  const addToBasket = (eventInfo, dispatch) => {
    return ticket => {
      dispatch(addTicketToBasket(eventInfo, ticket))
    }
  }
  const eventsContent = Object.entries(tickets).map(
    ([eventId, { event: eventInfo, tickets }]) => {
      if (tickets.length === 0) return false
      if (moment(eventInfo.last_sales_date).isBefore(moment())) return null

      let image = eventInfo.image
      if (!image && eventInfo.parent_child_type === 'CHILD') {
        image = find(events, event => event.id === eventInfo.parent_id)
        image = image && image.image
      }

      const sortedTickets = sortTickets(sortKey, tickets)

      return (
        <BargainTicketEvent
          tickets={sortedTickets}
          // this is an abomination
          addToBasket={removeTicketFromListWrapper(
            dispatch,
            eventInfo,
            addToBasket
          )}
          eventInfo={eventInfo}
          dispatch={dispatch}
          image={image}
          key={eventId}
          bargains
        />
      )
    }
  )

  return <React.Fragment>{eventsContent}</React.Fragment>
}

const sortTickets = (sortKey, tickets) => {
  if (sortKey === 'bargain') {
    return orderBy(
      tickets,
      [
        t => {
          const org = parseInt(t.original_price, 10)

          const percent = (org - t.price_number) / org

          return percent
        }
      ],
      ['desc']
    )
  }

  if (sortKey === 'price') {
    return orderBy(tickets, ['price_number'], ['asc'])
  }

  return tickets
}

const Header = (onClick, active) => {
  return (
    <div className="detail-title">
      <div className="text-center">
        <div className="sort--header sort--header--bargains">
          <div className="row">
            <div className="col-sm-2" /> {/* image */}
            <div className="col-sm-3 col-xs-4 text-left">
              <Translate value="tickets.ticket" />
            </div>
            <div className="col-sm-7 col-xs-8">
              <div className="row">
                <div className="col-sm-3" />
                <div className="col-sm-3 col-xs-6 text-left">
                  <div
                    className={`cursor-pointer text-left ${
                      active === 'bargain' ? 'sort--header-active' : ''
                    }`}
                    onClick={() => onClick('bargain')}
                  >
                    <Translate value="tickets.bargain_percent" />
                  </div>
                </div>

                <div className="col-sm-3 col-xs-6">
                  <div
                    className={`cursor-pointer text-left ${
                      active === 'price' ? 'sort--header-active' : ''
                    }`}
                    onClick={() => onClick('price')}
                  >
                    <Translate value="shopping_basket.price" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withSortHeader(Header)(BargainTicketList)
