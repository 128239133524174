import React from 'react'
import GlobalLoadingIndicator from '../../../components/global-loading-indicator'
import { Translate } from 'react-redux-i18n'
import './styles.scss'

const SubscriptionButton = ({
  handleSetSubscription,
  handleDeleteSubscription,
  id,
  fetching,
  userSubscriptions,
  parentEventId,
  parent_childType
}) => {
  const loading = (
    <div
      style={{
        height: '50px',
        width: '100px'
      }}
    >
      <GlobalLoadingIndicator center />
    </div>
  )
  const parentEventSubscribed =
    userSubscriptions && userSubscriptions.includes(parentEventId)
  const subscribed = userSubscriptions && userSubscriptions.includes(id)

  const parentEventHasSubscription = (
    <div onClick={() => {}}>
      <i className="fa-regular fa-bell" />
      <Translate value="tickets.subscribed_parent" />
    </div>
  )

  const noSubscription = (
    <div onClick={() => handleSetSubscription(id, parent_childType)}>
      <i className="fa-regular fa-bell" />
      <Translate value="tickets.subscribe" />
    </div>
  )

  const hasSubscription = (
    <div onClick={() => handleDeleteSubscription(id)}>
      <i className="fa-regular fa-bell" />
      <Translate value="tickets.subscribed" />
    </div>
  )

  const visible = fetching
    ? loading
    : parentEventSubscribed
    ? parentEventHasSubscription
    : subscribed
    ? hasSubscription
    : noSubscription

  const alarmButton = (
    <div className="ticket-alarm-container">
      <div className="ticket-alarm cursor-pointer text-center">{visible}</div>
      <div className="ticket-alarm-explanation">
        <Translate value="tickets.explanation" />
      </div>
    </div>
  )

  return alarmButton
}

export default SubscriptionButton
