import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Translate, I18n } from 'react-redux-i18n'
import * as API from '../utility/api'
class CopyTextButton extends React.Component {
  onCopy = () => {
    API.apiNotification({
      value: I18n.t('general.copied_ticket_url'),
      status: 'success'
    })
  }

  render() {
    return (
      <CopyToClipboard text={this.props.text} onCopy={() => this.onCopy()}>
        <button className="secondary-button">
          <Translate value="general.copy_ticket_url" />
        </button>
      </CopyToClipboard>
    )
  }
}

export default CopyTextButton
