import {
  EVENT_INFO_FETCH_SUCCESS,
  START_EVENT_INFO_FETCH,
  EVENT_INFO_FETCH_FAIL,
  CHANGE_SELECTED_EVENT,
  SELECT_PREVIOUS_EVENT,
  TOGGLE_MAINPAGE_DROPDOWN_HOVER,
  SET_MAP,
  SET_CATEGORIES_COUNTER,
  EVENTS_FETCH_SUCCESS,
  EVENT_IDS_AND_NAMES,
  START_EVENTS_FETCH,
  REMOVE_TICKET_FROM_LISTS
} from '../actionTypes'
import produce from 'immer'
import moment from 'moment'

moment.locale('fi')

export default function events(
  state = {
    isFetchingEventInfo: false,
    isFetching: false,
    upcomingEvents: [],
    categoriesList: [],
    eventSearchInputValue: '',
    selectedEventInfo: null,
    eventInfoError: null,
    previousEvent: null,
    mainpageDropdownHover: false,
    events: [],
    allEventsIdsAndNames: [],
    categoriesCounter: { categories: [], expires: moment().add(1, 'day') },
    eventsFetchedAt: null,
    mostViewed: []
  },
  action
) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        return {
          ...action.payload.events,
          eventInfoError: false,
          isFetchingEventInfo: false,
          categoriesCounter: {
            ...action.payload.events.categoriesCounter,
            expires: moment(action.payload.events.categoriesCounter.expires)
          },
          eventsFetchedAt: action.payload.events.eventsFetchedAt
        }
      }

      return state
    case SET_CATEGORIES_COUNTER:
      return {
        ...state,
        categoriesCounter: action.categoriesCounter
      }
    case EVENT_IDS_AND_NAMES:
      return {
        ...state,
        allEventsIdsAndNames: action.data.events,
        categoriesList: [{id: -1, name: 'katsotuimmat', name_en: 'most viewed'}].concat(action.data.categories)
      }
    case REMOVE_TICKET_FROM_LISTS:
      if (!state.selectedEventInfo) return state

      const eventInfo = action.eventInfo
      const eventType = eventInfo.parent_child_type
      let newTickets = []

      if (eventType === 'NORMAL') {
        newTickets = state.selectedEventInfo.tickets.filter(
          ticket => ticket.id !== action.ticketId
        )

        return {
          ...state,
          selectedEventInfo: { ...state.selectedEventInfo, tickets: newTickets }
        }
      }

      if (eventType === 'CHILD') {
        const thisEventID = eventInfo.id
        const thisParentEventID = eventInfo.parent_id

        const newTickets = state.selectedEventInfo.tickets.filter(
          ticket => ticket.id !== action.ticketId
        )

        return produce(state, draft => {
          //remove tickets from previousEvent if its there
          if (draft.previousEvent) {
            if (draft.previousEvent.event.id === thisParentEventID) {
              draft.previousEvent.child_events.find(
                o => o.event.id === thisEventID
              ).tickets = newTickets
            }
          }

          //remove tickets from selectedEvent
          draft.selectedEventInfo.tickets = newTickets
        })
      }

      return state
    case SET_MAP:
      return {
        ...state,
        selectedEventInfo: { ...state.selectedEventInfo, map: action.data }
      }
    case TOGGLE_MAINPAGE_DROPDOWN_HOVER:
      return {
        ...state,
        mainpageDropdownHover: !state.mainpageDropdownHover
      }
    case EVENT_INFO_FETCH_FAIL:
      return {
        ...state,
        eventInfoError: 'No such event found',
        isFetchingEventInfo: false
      }
    case EVENTS_FETCH_SUCCESS:
      const { allEvents, upcomingEvents, mostViewed } = action

      return {
        ...state,
        events: allEvents,
        upcomingEvents: upcomingEvents,
        categoriesList: [{id: -1, name: 'katsotuimmat', name_en: 'most viewed'}],
        mostViewed: mostViewed,
        isFetching: false
      }
    case START_EVENTS_FETCH:
      return {
        ...state,
        isFetching: true,
        eventsFetchedAt: moment().unix()
      }
    case START_EVENT_INFO_FETCH:
      return {
        ...state,
        isFetchingEventInfo: true,
        eventInfoError: null
      }
    case EVENT_INFO_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingEventInfo: false,
        selectedEventInfo: action.eventInfo,
        eventInfoError: null
      }
    case CHANGE_SELECTED_EVENT:
      return {
        ...state,
        previousEvent: state.selectedEventInfo,
        selectedEventInfo: {
          event: action.event,
          tickets: action.tickets
        }
      }
    case SELECT_PREVIOUS_EVENT:
      return {
        ...state,
        selectedEventInfo: state.previousEvent
      }
    default:
      return state
  }
}
