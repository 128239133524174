import React from 'react'
import RunningExports from './running-exports'

const SepaPage = () => {
  return (
    <div>
      <h2>Juokseva SEPA</h2>
      <RunningExports />
    </div>
  )
}

export default SepaPage
