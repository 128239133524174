import React from 'react'
import * as API from '../utility/api'
import EventInfo from '../components/event/event-info'
import BuyableTicketList from '../components/event-buyable-ticket/event-buyable-ticket-list'
import { addTicketToBasket } from '../actions/shopping-basket'
import { connect } from 'react-redux'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { Translate } from 'react-redux-i18n'
import { toggleModal } from '../actions/modal'
import { push } from 'connected-react-router'

class SingleBatchSellPage extends React.Component {
  state = {
    parentEvent: null,
    childEvent: null,
    tickets: null,
    fetching: true,
    flashMessage: null
  }

  componentDidMount() {
    const batchId = this.props.match.params.batchId

    this.setState({
      fetching: true
    })

    API.GET(`share/${batchId}`)
      .then(({ data }) => {
        this.setState({
          fetching: false,
          parentEvent: data.parent_event,
          childEvent: data.child_event,
          tickets: data.tickets
        })

        const flashMessage = this.flashMessage()

        if (flashMessage) {
          this.setState({ flashMessage })

          setTimeout(() => {
            this.setState({ flashMessage: null })
          }, 6000)
        }
      })
      .catch(() => this.props.dispatch(push('/')))
  }

  eventImage = () => {
    const events = this.props.events
    const eventInfo = this.props.eventInfo && this.props.eventInfo.event
    if (eventInfo) {
      for (let parentEvent of events) {
        // TODO: why undefied?
        if (parentEvent?.id && parentEvent.id === eventInfo.parent_id && parentEvent.image) {
          return parentEvent.image
        }
      }
    }
  }

  addToBasket = (eventInfo, dispatch) => {
    const { isSignedIn, location } = this.props

    if (eventInfo.image === null) {
      const image = this.eventImage()
      eventInfo = { ...eventInfo, image: image }
    }

    return ticket => {
      if (!isSignedIn) {
        dispatch(toggleModal(true, location.pathname))
        return
      }

      this.setState({
        tickets: this.state.tickets.filter(fTicket => fTicket.id !== ticket.id)
      })

      dispatch(addTicketToBasket(eventInfo, ticket))
    }
  }

  eventTicketlist = tickets => (
    <div className="row">
      <BuyableTicketList
        isFetching={this.props.ticketsFetching}
        tickets={tickets}
        addToBasket={this.addToBasket(
          this.state.childEvent,
          this.props.dispatch
        )}
        eventInfo={{ event: this.state.childEvent }}
        userId={this.props.userId}
      />
    </div>
  )

  fetchedContent = () => {
    const { parentEvent, childEvent } = this.state

    // slice removes double "/"
    const pathname = this.props.location.pathname.slice(1)
    const baseUrl = process.env.REACT_APP_URL
    const shareUrl = `${baseUrl}/${pathname}`

    return (
      <React.Fragment>
        <div className="event-info-container">
          <EventInfo
            {...childEvent}
            previousEvent={parentEvent}
            events={[parentEvent]}
            hideAlarm
            shareUrl={shareUrl}
            signedIn={this.props.signedIn}
          />
        </div>

        {this.eventTicketlist(this.state.tickets)}
      </React.Fragment>
    )
  }

  flashMessage = () => {
    const { location } = this.props
    const message = location.state && location.state.message

    if (message) {
      return message
    } else {
      return false
    }
  }

  sellerSuccess = () => {
    const { fetching } = this.state
    if (fetching) return null

    const { location } = this.props
    const isSeller = location.state && location.state.sellerSuccess

    if (!isSeller) return null

    return (
      <div className="seller-success-container">
        <img
          src="/seller-success-icon.svg"
          alt=""
          className="seller-success-icon"
        />
        <h2>
          <Translate value={'seller.success.title'} />
        </h2>
        <p>
          <Translate value={'seller.success.description'} />
        </p>
        <p>
          <Translate value={'seller.success.social'} />
        </p>
        <div className="clearfix" />
      </div>
    )
  }

  render() {
    const { fetching, flashMessage } = this.state

    return (
      <div className="section">
        {flashMessage && (
          <div className="batch-sell-page--flash-message">
            <i className="fa-solid fa-square-check" />{' '}
            <Translate value={flashMessage} />
          </div>
        )}

        {this.sellerSuccess()}

        {fetching ? <GlobalLoadingIndicator center /> : this.fetchedContent()}
      </div>
    )
  }
}

const dispatchMapsToProps = state => ({
  isSignedIn: state.users.signedIn || false,
  userId: state.users && state.users.userInfo && state.users.userInfo.sub
})

export default connect(dispatchMapsToProps)(SingleBatchSellPage)
