import React from 'react'
import './styles.scss'

const SubmitButton = props => {
  const { disabled, children, full } = props

  return (
    <button
      className={`submit-button ${full ? 'submit-button-full' : ''}`}
      type="submit"
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default SubmitButton
