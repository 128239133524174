import React, { useState, useEffect } from 'react'
import RepresentMoney from '../../utility/represent-money'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/sortBy'

const RaportsTable = ({ raportData }) => {
  const { type: raportType, data } = raportData
  const table = raportType === 'debt' ? debtTable(data) : salesTable(data)

  return table
}

const salesTable = data => {
  return (
    <div className="raport-table--container">
      <table id="salesTable">
        <thead>
          <tr>
            <th>Järjestäjä</th>
            <th>Tapahtuma</th>
            <th>Myynti kpl</th>
            <th>Myynti eur yht</th>
            <th>Lipunmyyjä</th>
            <th>Järjestäjä provisio 5% (ALV 0%)</th>
            <th>Tiketin provisio 5% (Sis. alv)</th>
          </tr>
        </thead>

        <tbody>
          {generateSalesTableRows(data)}
          {generateSalesSummaryRow(data)}
        </tbody>
      </table>
    </div>
  )
}

const generateSalesTableRows = data => {
  //const sortedData = sortBy(data, ['event', 'organizer'])

  return data.map(row => {
    const { event, organizer, tickets_sold, total_sold_amount, seller_amount, organizer_amount_wo_vat, tiketti_amount_w_vat } = row

    return (
      <tr key={organizer + 'salesTable'}>
        <td>{organizer}</td>
        <td>{event}</td>
        <td className="numeric">{tickets_sold}</td>
        <td className="numeric">{<RepresentMoney sum={total_sold_amount} eurSuffix />}</td>
        <td className="numeric">{<RepresentMoney sum={seller_amount} eurSuffix />}</td>
        <td className="numeric">{<RepresentMoney sum={organizer_amount_wo_vat} eurSuffix />}</td>
        <td className="numeric">{<RepresentMoney sum={tiketti_amount_w_vat} eurSuffix />}</td>
      </tr>
    )
  })
}

const generateSalesSummaryRow = data => {

  const totalSoldCount = data.reduce((acc, row) => {
    console.log(acc, row.tickets_sold)
    return row.tickets_sold + acc
  }, 0)
  const totalSoldAmount = data
    .reduce((acc, row) => row.total_sold_amount + acc, 0)
    .toFixed(2)

  const totalSellerAmount = data
    .reduce((acc, row) => row.seller_amount + acc, 0)
    .toFixed(2)

  const totalFivePercentProvisionWoVat = data
    .reduce((acc, row) => row.organizer_amount_wo_vat + acc, 0)
    .toFixed(2)

  const totalFivePercentProvisionWVat = data
    .reduce((acc, row) => row.tiketti_amount_w_vat + acc, 0)
    .toFixed(2)

  return (
    <tr>
      <th />
      <th>Yhteensä</th>
      <th className="numeric">{totalSoldCount}</th>
      <td className="numeric">{<RepresentMoney sum={totalSoldAmount} eurSuffix />}</td>
      <td className="numeric">{<RepresentMoney sum={totalSellerAmount} eurSuffix />}</td>
      <td className="numeric">
        {<RepresentMoney sum={totalFivePercentProvisionWoVat} eurSuffix />}
      </td>
      <td className="numeric">
        {<RepresentMoney sum={totalFivePercentProvisionWVat} eurSuffix />}
      </td>
    </tr>
  )
}

const debtTable = data => {
  return (
    <div className="raport-table--container">
      <RaportHider topic={'Asiakkaat'} data={generateTop(data.top)} />

      <div className="break-lg" />

      <RaportHider topic={'Järjestäjät'} data={generateMiddle(data.middle)} />

      <div className="break-lg" />

      <RaportHider topic={'Aikaväli'} data={generateBottom(data.bottom)} />
    </div>
  )
}

const generateTop = data => {
  const userSortedData = sortBy(data, ['name'])

  const rows = userSortedData.map(row => {
    const {
      name,
      tickets_sold,
      total_not_sepaed_user,
      total_sepaed_user,
      total_not_sepaed_org,
      total_sepaed_org,
      total_sold_amount
    } = row

    return (
      <tr key={'debt-' + name}>
        <td>{name}</td>
        <td>{<RepresentMoney sum={total_sold_amount} eurSuffix />}</td>
        <td>{tickets_sold}</td>
        <td>{<RepresentMoney sum={total_sepaed_user} eurSuffix />}</td>
        <td>{<RepresentMoney sum={total_sepaed_org} eurSuffix />}</td>
        <td>
          {
            <RepresentMoney
              sum={total_sepaed_user + total_sepaed_org}
              eurSuffix
            />
          }
        </td>
        <td>{<RepresentMoney sum={total_not_sepaed_user} eurSuffix />}</td>
        <td>{<RepresentMoney sum={total_not_sepaed_org} eurSuffix />}</td>
        <td>
          {
            <RepresentMoney
              sum={total_not_sepaed_user + total_not_sepaed_org}
              eurSuffix
            />
          }
        </td>
        <td>{<RepresentMoney sum={total_sold_amount * 0.05} eurSuffix />}</td>
      </tr>
    )
  })

  const topSummary = () => {
    const totalSold = data.reduce((a, b) => a + b.total_sold_amount, 0)
    const totalTicketsSold = data.reduce((a, b) => a + b.tickets_sold, 0)
    const totalSepaedCustomer = data.reduce(
      (a, b) => a + b.total_sepaed_user,
      0
    )
    const totalSepaedOrganizer = data.reduce(
      (a, b) => a + b.total_sepaed_org,
      0
    )
    const totalSepaedCustomerAndOrganizer = data.reduce(
      (a, b) => a + b.total_sepaed_user + b.total_sepaed_org,
      0
    )
    const totalNotSepaedCustomer = data.reduce(
      (a, b) => a + b.total_not_sepaed_user,
      0
    )
    const totalNotSepaedOrganizer = data.reduce(
      (a, b) => a + b.total_not_sepaed_org,
      0
    )
    const totalNotSepaedCustomerAndOrganizer = data.reduce(
      (a, b) => a + b.total_not_sepaed_org + b.total_not_sepaed_user,
      0
    )
    const totalSoldAmountForTiketti = totalSold * 0.05

    return (
      <tr style={{ fontWeight: 'bold' }}>
        <td>Yhteensä</td>
        <td>{<RepresentMoney sum={totalSold} eurSuffix />}</td>
        <td>{totalTicketsSold}</td>
        <td>{<RepresentMoney sum={totalSepaedCustomer} eurSuffix />}</td>
        <td>{<RepresentMoney sum={totalSepaedOrganizer} eurSuffix />}</td>
        <td>
          {<RepresentMoney sum={totalSepaedCustomerAndOrganizer} eurSuffix />}
        </td>
        <td>{<RepresentMoney sum={totalNotSepaedCustomer} eurSuffix />}</td>
        <td>{<RepresentMoney sum={totalNotSepaedOrganizer} eurSuffix />}</td>
        <td>
          {
            <RepresentMoney
              sum={totalNotSepaedCustomerAndOrganizer}
              eurSuffix
            />
          }
        </td>
        <td>{<RepresentMoney sum={totalSoldAmountForTiketti} eurSuffix />}</td>
      </tr>
    )
  }

  return (
    <table style={{ width: '60vw' }} id="salesTable">
      <thead>
        <tr>
          <th>Lipunmyyjä</th>
          <th colSpan="2">Kokonaismyynti</th>
          <th colSpan="3">Tilitetty</th>
          <th colSpan="3">Velka</th>
          <th>Tiketin provisio</th>
        </tr>

        <tr>
          <th>nimi</th>
          <th>eur</th>
          <th>kpl</th>
          <th>Lipunmyyjä</th>
          <th>Järjestäjä</th>
          <th>Tilitetty yhteensä</th>
          <th>Lipunmyyjä</th>
          <th>Järjestäjä</th>
          <th>Velka yhteensä</th>
        </tr>
      </thead>

      <tbody>
        {rows}
        {topSummary()}
      </tbody>
    </table>
  )
}

const generateMiddle = data => {
  const sortedData = sortBy(data, ['organizer_name'])

  const rows = sortedData.map(row => {
    const { organizer_name, total_not_sepaed, total_sales, total_sepaed } = row

    return (
      <tr>
        <td>{organizer_name}</td>
        <td>
          <RepresentMoney sum={total_sales} eurSuffix />
        </td>
        <td>
          <RepresentMoney sum={total_sepaed} eurSuffix />
        </td>
        <td>
          <RepresentMoney sum={total_not_sepaed} eurSuffix />
        </td>
      </tr>
    )
  })

  const summary = () => {
    const totalSales = data.reduce((a, b) => a + b.total_sales, 0)
    const totalSepaed = data.reduce((a, b) => a + b.total_sepaed, 0)
    const totalNotSepaed = data.reduce((a, b) => a + b.total_not_sepaed, 0)

    return (
      <tr style={{ fontWeight: 'bold' }}>
        <td>Yhteensä</td>
        <td>{<RepresentMoney sum={totalSales} eurSuffix />}</td>
        <td>{<RepresentMoney sum={totalSepaed} eurSuffix />}</td>
        <td>{<RepresentMoney sum={totalNotSepaed} eurSuffix />}</td>
      </tr>
    )
  }

  return (
    <table
      style={{ width: '60vw' }}
      id="salesTable"
      className="admin-panel-table"
    >
      <thead>
        <tr>
          <th>Järjestäjä</th>
          <th>Kokonaismyynti</th>
          <th>Tilitetty</th>
          <th>Velka</th>
        </tr>
      </thead>

      <tbody>
        {rows}
        {summary()}
      </tbody>
    </table>
  )
}

const generateBottom = data => {
  const sortedData = orderBy(data, ['year_number', 'month_number'], ['asc'])

  const rows = sortedData.map(row => {
    const {
      basket_amount,
      month,
      total,
      total_not_sepaed_user,
      total_not_sepaed_org,
      total_sepaed_user,
      total_sepaed_org
    } = row

    return (
      <tr key={'debt-bottom' + month}>
        <td>{month}</td>
        <td>
          <RepresentMoney sum={total} eurSuffix />
        </td>
        <td>{basket_amount}</td>
        <td>
          <RepresentMoney sum={total_sepaed_user} eurSuffix />
        </td>
        <td>
          <RepresentMoney sum={total_sepaed_org} eurSuffix />
        </td>
        <td>
          <RepresentMoney
            sum={total_sepaed_org + total_sepaed_user}
            eurSuffix
          />
        </td>
        <td>
          <RepresentMoney sum={total_not_sepaed_user} eurSuffix />
        </td>
        <td>
          <RepresentMoney sum={total_not_sepaed_org} eurSuffix />
        </td>
        <td>
          <RepresentMoney
            sum={total_not_sepaed_org + total_not_sepaed_user}
            eurSuffix
          />
        </td>
        <td>
          <RepresentMoney sum={total * 0.05} eurSuffix />
        </td>
      </tr>
    )
  })

  const summary = () => {
    const totalSold = data.reduce((a, b) => a + b.total, 0)
    const totalTicketsSold = data.reduce((a, b) => a + b.basket_amount, 0)
    const totalSepaedCustomer = data.reduce(
      (a, b) => a + b.total_sepaed_user,
      0
    )
    const totalSepaedOrganizer = data.reduce(
      (a, b) => a + b.total_sepaed_org,
      0
    )
    const totalSepaedCustomerAndOrganizer = data.reduce(
      (a, b) => a + b.total_sepaed_user + b.total_sepaed_org,
      0
    )
    const totalNotSepaedCustomer = data.reduce(
      (a, b) => a + b.total_not_sepaed_user,
      0
    )
    const totalNotSepaedOrganizer = data.reduce(
      (a, b) => a + b.total_not_sepaed_org,
      0
    )
    const totalNotSepaedCustomerAndOrganizer = data.reduce(
      (a, b) => a + b.total_not_sepaed_user + b.total_not_sepaed_org,
      0
    )
    const totalSoldAmountForTiketti = totalSold * 0.05

    return (
      <tr style={{ fontWeight: 'bold' }}>
        <td>Yhteensä</td>
        <td>{<RepresentMoney sum={totalSold} eurSuffix />}</td>
        <td>{totalTicketsSold}</td>
        <td>{<RepresentMoney sum={totalSepaedCustomer} eurSuffix />}</td>
        <td>{<RepresentMoney sum={totalSepaedOrganizer} eurSuffix />}</td>
        <td>
          {<RepresentMoney sum={totalSepaedCustomerAndOrganizer} eurSuffix />}
        </td>
        <td>{<RepresentMoney sum={totalNotSepaedCustomer} eurSuffix />}</td>
        <td>{<RepresentMoney sum={totalNotSepaedOrganizer} eurSuffix />}</td>
        <td>
          {
            <RepresentMoney
              sum={totalNotSepaedCustomerAndOrganizer}
              eurSuffix
            />
          }
        </td>
        <td>{<RepresentMoney sum={totalSoldAmountForTiketti} eurSuffix />}</td>
      </tr>
    )
  }

  return (
    <table
      style={{ width: '60vw' }}
      id="salesTable"
      className="admin-panel-table"
    >
      <thead>
        <tr>
          <th>Jakso</th>
          <th colSpan="2">Kokonaismyynti</th>
          <th colSpan="3">Tilitetty</th>
          <th colSpan="3">Velka</th>
          <th>Tikettii provisio</th>
        </tr>

        <tr>
          <th />
          <th>Eur</th>
          <th>Kpl</th>
          <th>Lipunmyyjä</th>
          <th>Järjestäjä</th>
          <th>Tilitetty yhteensä</th>
          <th>Lipunmyyjä</th>
          <th>Järjestäjä</th>
          <th>Velka yhteensä</th>
        </tr>
      </thead>

      <tbody>
        {rows}
        {summary()}
      </tbody>
    </table>
  )
}

const RaportHider = ({ topic, data }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [topic, data])

  return (
    <div className="row">
      <div className="col-sm-10">{topic}</div>
      <div className="col-sm-2">
        <button onClick={() => setOpen(!open)}>
          {open ? 'sulje' : 'avaa'}
        </button>
      </div>

      {open && data}
    </div>
  )
}

export default RaportsTable
