import React from 'react'
import * as API from '../utility/api'
import BankButton from '../components/bank-button'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { addError } from '../actions/shopping-basket'
import GlobalLoadingIndicator from '../components/global-loading-indicator'

class PaymentWall extends React.Component {
  state = {
    banks: [],
    loading: false
  }

  componentDidMount() {
    const { tickets, email, dispatch, namedInfos } = this.props
    this.setState({ loading: true })

    const ticketsToBackend = tickets.map(ticket => {
      const tid = ticket.id
      if (namedInfos[tid]) {
        return { id: tid, ...namedInfos[tid] }
      }

      return { id: tid }
    })

    console.log('payment/new', ticketsToBackend, email);

    API.POST('payment/new', {
      tickets: ticketsToBackend,
      email
    })
      .then(({ data }) => {
        this.setState({          
          banks: data.banks,
          loading: false
        })
      })
      .catch(e => {
        if (e && e.response) {
          console.log('payment/new',e);
          const response = e.response
          this.setState({ loading: false })
          dispatch(push('/basket'))
          dispatch(addError(response.data.invalid_tickets))
          API.apiNotification({
            value: 'notifications.invalid_tickets',
            status: 'error'
          })
        }
      })
  }

  render() {
    const { banks, loading } = this.state

    const bankButtons = banks.map(bank => <BankButton key={bank.name} {...bank} />)
    
    return (
      <div className='section'>
        <h2>
          {loading ? (
            <Translate value='payment.handling_tickets' />
          ) : (
            <Translate value='payment.select_provider' />
          )}
        </h2>
        <div className='break-md' />
        <div className='row'>
          {loading && (
            <div className='col-sm-12'>
              <GlobalLoadingIndicator center />
            </div>
          )}
          {bankButtons}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tickets: state.shoppingBasket.ticketList,
  namedInfos: state.shoppingBasket.namedInfos,
  email: state.shoppingBasket.email
})

export default connect(mapStateToProps)(PaymentWall)
