import React, { Component } from 'react'

class CategoryCheckbox extends Component {
  handle = () => this.props.onClick(this.props.filter)

  render() {
    const { filter, checked } = this.props
    return (
      <div
        className={`recommendedEvent text-center cursor-pointer ${
          checked ? 'recommendedEvent-checked' : ''
        }`}
        onClick={() => this.handle()}
      >
        {filter.name}
      </div>
    )
  }
}

export default CategoryCheckbox
