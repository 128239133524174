import React, { useState } from 'react'
import RepresentMoney from '../../utility/represent-money'
import TicketRow from './ticket-row'
import InfoRow from './info-row'
import * as API from '../../utility/api'

const BASKETS_URL = 'admin/orders'

const BasketBox = (props) => {
  const [open, setOpen] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(props.payment.state)
  const [fetching, setFetching] = useState({ update: false, process: false })
  const [errors, setErrors] = useState([])

  const {
    payment,
    id,
    customer,
    products,
    tiketti_order_code,
    tiketti_order_id,
    order_tiketti_map
  } = props

  const toggleOpen = () => {
    setOpen(!open)
  }

  const updateBasket = () => {
    setFetching({ ...fetching, update: true })

    API.PUT(`${BASKETS_URL}/${id}`, {
      paymentStatus,
      reference: payment.reference,
      stamp: payment.stamp
    })
      .then(() => {
        setFetching({ ...fetching, update: false })
      })
      // eslint-disable-next-line
      .catch((e) => {
        setFetching({ ...fetching, update: false })
      })
  }

  const manualProcess = () => {
    setFetching({ ...fetching, process: true })

    API.PUT(`${BASKETS_URL}/${id}/process`, {})
      .then(() => {
        setFetching({ ...fetching, process: false })
      })
      .catch(({ response }) => {

        if (response.data?.message) {
          API.apiNotification({
            value: response.data.message,
            status: response.status !== 200 ? 'error' : 'success'
          })
        }

        setErrors(response.data)
        setFetching({ ...fetching, process: false })
      })
  }

  const openedContent = (
    <React.Fragment>
      <div className="break-md" />

      {products &&
        products.map((product) => (
          <TicketRow
            key={product.ticket.id}
            ticket={product.ticket}
            basket={{ reference: payment.reference, stamp: payment.stamp }}
          />
        ))}
    </React.Fragment>
  )

  const paymentStatusInput = (
    <select
      value={paymentStatus}
      onChange={(e) => setPaymentStatus(e.target.value)}
    >
      <option value="" />
      <option value="REJECTED" disabled="disabled">Hylätty</option>
      <option value="PAID">Maksettu</option>
      <option value="VERIFIED">Vahvistettu</option>
      <option value="PENDING" disabled="disabled">Ei maksettu</option>
      <option value="CANCELLED">Peruttu</option>
      <option value="FAILED" disabled="disabled">Epäonnistunut</option>
      <option value="REFUNDED">Hyvitetty</option>
    </select>
  )

  const getOrderTikettiLinks = () => {
    let order_tiketti_links = tiketti_order_id ? <a href={`https://pipetti.tiketti.fi/tilaus/${tiketti_order_id}`} target="_blank" rel="noreferrer">{tiketti_order_code}</a> : '-'
    if (order_tiketti_map.length) {
      order_tiketti_links = order_tiketti_map.map((tiketti_order) => {
        return (
          <div><a href={`https://pipetti.tiketti.fi/tilaus/${tiketti_order.tiketti_order_id}`} target="_blank" rel="noreferrer">{tiketti_order.tiketti_order_code}</a></div>
        )
      })
    }
    return order_tiketti_links;
  }

  const errorContent = () =>
    errors.map((e) => (
      <div>
        {e}
        <div className="break-sm" />
      </div>
    ))

  return (
    <div
      key={`basket-${id}`}
      style={{
        backgroundColor: 'floralwhite',
        border: '1px solid',
        marginBottom: '1em'
      }}
    >
      <InfoRow
        first={['Checkout-viite', payment.reference || '-']}
        second={['Summa', <RepresentMoney sum={payment.amount} eurSuffix /> || '-']}
        third={['Payment status', paymentStatusInput || '-']}
      />

      <InfoRow
        first={['IBAN', customer.iban || 'Tilinumero puuttuu']}
        second={['Nimi', `${customer.first_name} ${customer.last_name}` || '-']}
        third={['Email', customer.email || 'Käyttäjällä ei emailia']}
      />

      <InfoRow
        first={['Tiketti tilauskoodi', getOrderTikettiLinks()]}
        second={['Lippujen määrä', products.length ? products.length : 0]}
        third={[
          'Maksu',
          `${payment.payed_at ? payment.payed_at + ', ' : ''}status=${payment.state}` || '-'
        ]}
      />

      {open && openedContent}

      <div className="basket--management--row">
        <button
          style={{ flex: 1, margin: '10px' }}
          onClick={() => toggleOpen()}
        >
          {open ? 'SULJE' : 'AVAA'}
        </button>

        <button style={{ flex: 1, margin: '10px' }} onClick={updateBasket}>
          {fetching.update ? 'Tallennetaan…' : 'Tallenna'}
        </button>

        <button style={{ flex: 1, margin: '10px' }} onClick={manualProcess}>
          {fetching.process ? 'PROCESSING………' : 'MANUAL PROCESS'}
        </button>
      </div>
      {errors.length > 0 && (
        <div
          style={{
            color: 'tomato',
            border: '1px solid',
            borderRadius: '5px',
            margin: '10px',
            padding: '0 10px'
          }}
        >
          <div className="break-sm" />
          {errorContent()}
        </div>
      )}
    </div>
  )
}

export default BasketBox
