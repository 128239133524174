import React from 'react'
import { Translate } from 'react-redux-i18n'
import { changeStep, steps } from '../../actions/ticket-sale-steps'
import BasePrice from './ticket-base-price'
import LastSellDate from './ticket-sell-date'
import MaxPrice from './ticket-max-price'
import SummaryFooter from './ticket-sell-summary-footer'

const SellForm = props => {
  const {
    boundary,
    currentPhase,
    dispatch,
    eventInfo,
    pricingPolicy,
    selectedDate,
    sellPrice,
    sellIndividually,
    tickets,
    ticketCode,
    ticket,
    ticketsToSell
  } = props

  const endSaleDate = eventInfo && eventInfo.last_sales_date

  const footerSellPrice = sellIndividually ? sellPrice : sellPrice * tickets.length

  const TICKET_MIN_PRICE = 1.0
  const TICKET_MAX_PRICE = Math.round(ticket.sum * 100 * 1.2) / 100

  return (
    <React.Fragment>
      {sellIndividually ? (
        <h3 className="text-center">
          <Translate value="tickets.ticket_id" id={ticketCode} />
        </h3>
      ) : null}
      <BasePrice
        thisPhase={() => dispatch(changeStep(steps.basePrice))}
        nextPhase={() => dispatch(changeStep(steps.lastSellDate))}
        visible={currentPhase === steps.basePrice}
        ticketPrice={ticket.sum}
        dispatch={dispatch}
        sellPrice={sellPrice}
        sellIndividually={sellIndividually}
        pricingPolicy={pricingPolicy}
        ticketCode={ticketCode}
        tickets={tickets}
        ticketMaxPrice={TICKET_MAX_PRICE}
        ticketMinPrice={TICKET_MIN_PRICE}
      />

      <MaxPrice
        dispatch={dispatch}
        ticketCode={ticketCode}
        sellPrice={sellPrice}
        ticketPrice={ticket.sum}
        pricingPolicy={pricingPolicy}
        visible={currentPhase === steps.minMaxPrice}
        tickets={tickets}
        sellIndividually={sellIndividually}
        boundary={
          sellIndividually
            ? ticketsToSell[ticketCode] && ticketsToSell[ticketCode].boundary
            : boundary
        }
      />

      <LastSellDate
        thisPhase={() => dispatch(changeStep(steps.lastSellDate))}
        nextPhase={() => dispatch(changeStep(steps.accountNumber))}
        visible={currentPhase === steps.lastSellDate}
        dispatch={dispatch}
        selectedDate={selectedDate}
        datePickerFocused={
          ticketsToSell[ticketCode]
            ? ticketsToSell[ticketCode].datePickerFocused
            : false
        }
        endSaleDate={endSaleDate}
        ticketCode={ticketCode}
        tickets={tickets}
        sellIndividually={sellIndividually}
      />

      <SummaryFooter sellPrice={footerSellPrice} selectedDate={selectedDate} ticketMinPrice={TICKET_MIN_PRICE} ticketMaxPrice={TICKET_MAX_PRICE} />
    </React.Fragment>
  )
}

export default SellForm
