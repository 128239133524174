import axios from 'axios'
import { store } from '../utility/createStore'
import { refreshTokens } from '../actions/users'
import { invokeNotification } from '../actions/global-notification'
import { toggleModal } from '../actions/modal'
import { signOut } from '../actions/users'

const baseUrl = process.env.REACT_APP_API_URL

const instance = axios.create({
  withCredentials: true
})

export const apiNotification = params => {
  store.dispatch(invokeNotification(params))
}

// adds headers to all requests if they exists
instance.interceptors.request.use(
  config => {
    const tokens = store.getState().users.tokens
    const locale = store.getState().i18n.locale || 'fi'
    const addTokens = config.url.includes(baseUrl)

    // Send locale to api in every request
    if (locale) {
      config.headers['X-Locale'] = locale;
    }

    if (tokens && addTokens) {
      config.headers['Authorization'] = 'Bearer ' + tokens.access_token
    }

    return config
  },
  error => Promise.reject(error)
)

// handles token refreshing if they are expired
instance.interceptors.response.use(
  response => response,
  error => {
    const refresh_token = store.getState().users.tokens.refresh_token

    const originalRequest = error.config

    // Check if refresh token fails
    if (originalRequest.url === `${baseUrl}auth/refresh`) {
      store.dispatch(signOut())
      store.dispatch(invokeNotification({
        value: 'registration.invalid_login',
        status: 'error'
      }));
      return Promise.reject(error)
    }

    // intercepts request if token is expired
    if (
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      // redirect to login if not logged in
      if (!store.getState().users.signedIn || !refresh_token) {
        const currentLocation = store.getState().router.location.pathname

        store.dispatch(toggleModal(true, currentLocation))
        return Promise.reject()
      }

      // make a refresh request for tokens
      // put refreshed tokens to store
      return instance
        .post(`${baseUrl}auth/refresh`, {
          refresh_token,
        })
        .then(({ data }) => {
          store.dispatch(
            refreshTokens({
              access_token: data.tokens.access_token,
              refresh_token: data.tokens.refresh_token
            })
          )

          instance.defaults.headers.common['Authorization'] =
            'Bearer ' + data.access_token
          originalRequest.headers['Authorization'] = data.access_token

          // redo original request
          return instance(originalRequest)
        })
    }

    // user is logged out
    if (
      error.response.status === 401
    ) {
      const currentLocation = store.getState().router.location.pathname

      store.dispatch(toggleModal(true, currentLocation))
    }

    // reject all else scenarios
    return Promise.reject(error)
  }
)

/*  baseurl ends to /
*   using GET and POST url should be in like path/to/; not /path/to/

/*  queryparams should be an object
*   {param: value}
*   evaluating to url?param=value
*/
export const GET = (url, queryParams = {}, useBaseUrl = true) => {
  const urlPrefix = useBaseUrl ? baseUrl : ''

  return instance.get(urlPrefix + url, {
    params: queryParams
  })
}

export const DELETE = (url, queryParams = {}, useBaseUrl = true) => {
  const urlPrefix = useBaseUrl ? baseUrl : ''

  return instance.delete(urlPrefix + url, {
    params: queryParams
  })
}

/*
 *   body should be an object
 */
export const POST = (url, body, useBaseUrl = true) => {
  const urlPrefix = useBaseUrl ? baseUrl : ''

  return instance.post(urlPrefix + url, body)
}



/*
 *   body should be an object
 */
export const PUT = (url, body, useBaseUrl = true) => {
  const urlPrefix = useBaseUrl ? baseUrl : ''

  return instance.put(urlPrefix + url, body)
}
