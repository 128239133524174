import { TOGGLE_MODAL, SET_DESIRED_PATH } from '../actionTypes'

export default function modal(
  state = {
    modalOpen: false,
    desiredPath: null
  },
  action
) {
  switch (action.type) {
    case SET_DESIRED_PATH:
      return {
        ...state,
        desiredPath: action.path
      }
    case TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: action.modalOpen
      }
    default:
      return state
  }
}
