import React, { useState } from 'react'
import * as API from '../../utility/api'
import TicketRow from './ticket-row'

const TICKETS_URL = 'admin/tickets'

const FindTickets = () => {
  const [data, setData] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [tel, setTel] = useState('')
  const [eventId, setEventId] = useState('')
  const [saleStatus, setSaleStatus] = useState('')
  const [SEPAStatus, setSEPAStatus] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState([])

  const getData = () => {
    setLoading(true)

    API.GET(TICKETS_URL, {
      code,
      name,
      email,
      tel,
      saleStatus,
      eventId,
      SEPAStatus
    })
      .then(({ data }) => {
        setData(data.tickets)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(['Tilausten hakeminen epäonnistui', ...error])
      })
  }

  const submitForm = e => {
    e.preventDefault()

    getData()
  }

  const ticketRows = tickets =>
    tickets.map(t => <TicketRow key={t.id} ticket={t} />)

  return (
    <div>
      <div>
        <h2>Etsi lippuja</h2>

        <form onSubmit={submitForm}>
          <div
            className="management--form--box"
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'baseline'
            }}
          >
            <div>
              <h3>Lipun tiedot</h3>
            </div>

            <p>Määrittele, millaisia lippuja haluat hakea.</p>

            <div style={{ display: 'flex' }}>
              <div>
                <label>Lippukoodi</label>
                <input
                  type="text"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                />
              </div>

              <div>
                <label>Tapahtuma ID</label>
                <input
                  type="number"
                  value={eventId}
                  onChange={e => setEventId(e.target.value)}
                />
              </div>

              <div>
                <label>Lipun status</label>
                <select
                  value={saleStatus}
                  onChange={e => setSaleStatus(e.target.value)}
                >
                  <option value="" />
                  <option value="EXPIRED">Vanhentunut</option>
                  <option value="REJECTED">Hylätty</option>
                  <option value="FOR_SALE">Myynnissä</option>
                  <option value="SOLD">Myyty</option>
                  <option value="FROZEN">Jäädytetty</option>
                  <option value="DELETED">Poistettu</option>
                  <option value="FAILED">Prosessiontivirhe</option>
                </select>
              </div>

              <div>
                <label>SEPA status</label>
                <select
                  value={SEPAStatus}
                  onChange={e => setSEPAStatus(e.target.value)}
                >
                  <option value="" />
                  <option value="SEPAED">Luotu</option>
                  <option value="NOT_SEPAED">Ei luotu</option>
                </select>
              </div>
            </div>

            <div>
              <h3>Myyjän tiedot</h3>
            </div>

            <p>
              Määrittele kenen myymiä lippuja haluat hakea. Jos kentät ovat
              tyhjät, tulee kaikki liput.
            </p>

            <div style={{ display: 'flex' }}>
              <div>
                <label>Nimi</label>
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>

              <div>
                <label>Sähköposti</label>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label>Puhelinnumero</label>
                <input
                  type="number"
                  value={tel}
                  onChange={e => setTel(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="break-md" />

          <div>
            <button disabled={loading}>
              {loading ? 'haetaan lippuja' : 'etsi lippu'}
            </button>
          </div>
        </form>
      </div>

      <div className="break-lg" />

      {data && (
        <div>
          <p
            className="bold"
            style={{ marginLeft: '15px' }}
          >{`${data.length} lippua`}</p>
          {ticketRows(data)}
        </div>
      )}

      {data && data.length === 0 && <div>Lippuja ei löytynyt</div>}
    </div>
  )
}

const TicketsManagement = () => {
  return <div>{FindTickets()}</div>
}

export default TicketsManagement
