import React from 'react'
import moment from 'moment'
import {
  killBasket,
  addTimer,
  addTimeLeft,
  resetTimers
} from '../actions/shopping-basket'
import { connect } from 'react-redux'

class ShoppingBasketTimer extends React.Component {
  constructor(props) {
    super(props)

    moment.locale('fi')
  }

  componentDidMount() {
    const timer = setInterval(this.tick, 1000)
    this.props.dispatch(addTimer(timer))
  }

  componentWillUnmount() {
    this.clearTimers()
  }

  clearTimers() {
    this.props.timers.forEach(timer => clearInterval(timer))
    this.props.dispatch(resetTimers())
  }

  tick = () => {
    const now = moment()
    const endTime = this.props.endTime
    const isTime = moment().isAfter(moment.unix(endTime))
    if (isTime) {
      this.props.dispatch(killBasket())
    }

    const timeLeft = moment(moment.unix(endTime).diff(now)).format('mm:ss')

    if (!isTime) {
      this.props.dispatch(addTimeLeft(timeLeft))
    }
  }

  render() {
    const { className, timeLeft } = this.props
    return <div className={className}>{timeLeft}</div>
  }
}

const mapStateToProps = state => {
  return {
    timers: state.shoppingBasket.timers,
    timeLeft: state.shoppingBasket.timeLeft
  }
}

export default connect(mapStateToProps)(ShoppingBasketTimer)
