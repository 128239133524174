import React from 'react'
import { Link } from 'react-router-dom'

const HeaderButton = ({ onClick, children, border, path, aria }) => {
  const HeaderButtonWithLink = () => (
    <Link
      to={path}
      className={`nav-link ${border ? 'nav-link-border' : ''}`}
      aria-label={aria}
      onClick={onClick}
    >
      {children}
    </Link>
  )

  const HeaderButtonWithoutLink = () => (
    <div
      aria-label={aria}
      onClick={onClick}
      className={`nav-link ${border ? 'nav-link-border' : ''}`}
    >
      {children}
    </div>
  )

  return <>{path ? HeaderButtonWithLink() : HeaderButtonWithoutLink()}</>
}

export default HeaderButton
