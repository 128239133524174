import React from 'react'
import { Translate } from 'react-redux-i18n'
import { push } from 'connected-react-router'
import { addSearchCategory } from '../actions/search'

class MenuDropdown extends React.Component {
  state = {
    hover: false
  }

  handleOpen = () => {
    this.setState({
      hover: true
    })
  }

  handleClose = () => {
    this.setState({
      hover: false
    })
  }

  render() {
    const { dispatch, categories, className } = this.props

    const eventSearchDropdown = () =>
      categories.map((cat) => (
        <div
          key={cat.id}
          onClick={() => {
            dispatch(addSearchCategory(cat, true))
            dispatch(push('/events'))
          }}
          className="dropdown-button"
        >
          {cat.name}
        </div>
      ))

    return (
      <div
        className={`${className ? className : 'menu-button menu-button-link'}`}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        onTouchStart={this.handleOpen}
        onTouchCancel={this.handleClose}
      >
        <Translate
          value="events.search_events"
          style={{ textTransform: 'uppercase' }}
        />
        {(this.state.hover || this.state.open) && (
          <div className="mainpage-dropdown">{eventSearchDropdown()}</div>
        )}
      </div>
    )
  }
}

export default MenuDropdown
