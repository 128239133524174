import React from "react";
import { Link } from "react-router-dom";
import liputonLogo from "../assets/Liputon-logo-2019.svg";

const Logo = () => {
  return (
    <div id="logoContainer" className="header-logo">
      <Link to="/">
        <img src={liputonLogo} alt="liputonLogo" />
      </Link>
    </div>
  );
};

export default Logo;
