import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as API from '../utility/api';

function UnsubscribeByLink() {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('unsubscribe')) {
    
        return API.DELETE('subscriptions/bylink', {
            hash: queryParams.get("unsubscribe")
        })
        .then(() => {
            API.apiNotification({
                value: 'notifications.unsubscribe_ticket_watch',
                status: 'success'
            })
        })
        .catch(() => {
            API.apiNotification({
                value: 'notifications.unsubscribe_ticket_watch_failure',
                status: 'error'
            })
        });
    }
  }, [location.search]);

  return null;
}

export default UnsubscribeByLink;