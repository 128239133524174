import React, { useState } from 'react'
import * as API from '../../utility/api'

const BASE_URL = 'admin/customers'
const ADMINS_URL = 'admin/customers/admins'

const UserRow = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const [enabled, setEnabled] = useState(user.active)
  const [deleted, setDeleted] = useState(false)
  //const [additionalInfo, setAdditionalInfo] = useState(null)
  const [adminSetTo, setAdminSetTo] = useState(null)

  const {
    customer_id,
    first_name,
    last_name,
    created_at,
    updated_at,
    status,
    email,
    email_verified,
    group
  } = user

  const allow_marketing = user.allow_marketing === 1
  const iban = user.iban
  const phone = user.phone_number

  const disableUser = () => {
    setLoading('disable')
    API.PUT(`${BASE_URL}/${customer_id}/status`, { action: 'disable' })
      .then(() => {
        setLoading(false)
        setEnabled(!enabled)
      })
      .catch(({ response }) => {
        setLoading(false)
        console.log(response);
      })
  }

  const enableUser = () => {
    setLoading('enable')
    API.POST(`${BASE_URL}/${customer_id}/status`, { action: 'enable' })
      .then(() => {
        setLoading(false)
        setEnabled(!enabled)
      })
      .catch(({ response }) => {
        setLoading(false)
        console.log(response);
      })
  }

  const deleteUser = () => {
    const confirm = window.confirm(
      `HUOM! Käyttäjän poistamista ei voi perua. Tahdotko todella poistaa käyttäjän ${email}`
    )

    if (confirm) {
      setLoading('delete')

      API.DELETE(`${BASE_URL}/${customer_id}`)
        .then(() => {
          setLoading(false)
          setDeleted(true)
        })
        .catch(e => {
          setLoading(false)
          console.log(e);
        })
    }
  }

  const checkIfAdmin = () => {
    if (adminSetTo !== null) {
      return adminSetTo
    }

    return (
      group?.name === 'admin'
    )
  }

  const toggleAdminButton = () => {
    const isAdmin = checkIfAdmin()

    return isAdmin ? (
      <button
        style={{ marginLeft: '15px', flex: 1 }}
        onClick={() => removeAdmin()}
      >
        {loading === 'admin' ? 'Muutetaan....' : 'Poista admineista'}
      </button>
    ) : (
      <button
        style={{ marginLeft: '15px', flex: 1 }}
        onClick={() => makeAdmin()}
      >
        {loading === 'admin' ? 'Muutetaan....' : 'Tee käyttäjästä admin'}
      </button>
    )
  }

  const makeAdmin = () => {
    const confirm = window.confirm(
      `Tahdotko varmasti muuttaa adminiksi käyttäjän ${email}?`
    )

    if (!confirm) {
      return
    }

    setLoading('admin')
    API.POST(`${ADMINS_URL}/${customer_id}/change`, {
      action: 'add_admin',
    })
      .then(() => {
        setAdminSetTo(true)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.log(e);
      })
  }

  const removeAdmin = () => {
    const confirm = window.confirm(
      `Tahdotko poistaa käyttäjän ${email} admineista?`
    )

    if (!confirm) {
      return
    }

    setLoading('admin')
    API.POST(`${ADMINS_URL}/${customer_id}/change`, {
      action: 'remove_admin',
    })
      .then(() => {
        setAdminSetTo(false)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.log(e);
      })
  }

  const smallView = (
    <div
      key={customer_id}
      style={{
        marginBottom: '15px',
        display: 'flex',
        flexFlow: 'column nowrap',
        border: 'solid 1px',
        padding: '15px',
        backgroundColor: 'floralwhite'
      }}
    >
      <div style={{ display: 'flex', marginLeft: '15px' }}>
        <div style={{ fontSize: 'large' }}>{first_name}</div>
        <div style={{ marginLeft: '5px', fontSize: 'large' }}>
          {last_name}
        </div>
        <div style={{ marginLeft: '15px', fontSize: 'large' }}>{email}</div>
      </div>

      <div className="break-md" />

      <div style={{ display: 'flex' }}>
        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>IBAN</div>
          {iban}
        </label>

        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Status</div>
          {status}
        </label>

        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>user id</div>
          {customer_id}
        </label>
      </div>

      <div className="break-sm" />

      <div style={{ display: 'flex' }}>
        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Puhelin</div>
          {phone}
        </label>

        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Luotu</div>
          {created_at}
        </label>

        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Viimeksi muokattu</div>
          {updated_at}
        </label>
      </div>

      <div className="break-sm" />

      <div style={{ display: 'flex' }}>
        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Estetty</div>
          {!enabled ? 'KYLLÄ' : 'EI'}
        </label>

        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Sähköposti varmennettu</div>
          {email_verified ? 'KYLLÄ' : 'EI'}
        </label>

        <label style={{ marginLeft: '15px', flex: 1 }}>
          <div>Markkinointi sallittu</div>
          {allow_marketing ? 'KYLLÄ' : 'EI'}
        </label>
      </div>

      <div className="break-sm" />

      {group && (
        <div style={{ display: 'flex' }}>
          <label style={{ marginLeft: '15px', flex: 1 }}>
            <div>Admin</div>
            {checkIfAdmin() ? 'KYLLÄ' : 'EI'}
          </label>

          <label style={{ marginLeft: '15px', flex: 1 }} />

          <label style={{ marginLeft: '15px', flex: 1 }} />
        </div>
      )}

      <div className="break-md" />

      <div style={{ display: 'flex' }}>
        {group && toggleAdminButton()}

        {enabled && (
          <button
            style={{ marginLeft: '15px', flex: 1 }}
            onClick={() => disableUser()}
          >
            {loading === 'disable' ? 'Estetään...' : 'Estä käyttäjä'}
          </button>
        )}
        {!enabled && (
          <button
            style={{ marginLeft: '15px', flex: 1 }}
            onClick={() => enableUser()}
          >
            {loading === 'enable' ? 'Palautetaan...' : 'Palauta käyttäjä'}
          </button>
        )}

        <button
          style={{ marginLeft: '15px', flex: 1 }}
          onClick={() => deleteUser()}
        >
          {loading === 'delete'
            ? 'Poistetaan käyttäjää....'
            : 'Poista käyttäjä'}
        </button>
      </div>
    </div>
  )

  if (deleted) return <div />
  return smallView
}

export default UserRow
