import React from 'react'
import placeholder_image from '../../images/placeholder.png'
import Moment from 'moment'
import RepresentMoney from '../../utility/represent-money'
import { Translate, I18n } from 'react-redux-i18n'
import { FacebookShareButton, FacebookIcon } from 'react-share'
import CopyTextButton from '../copy-text-button'
import SubscriptionButton from './subscription-button/'
import EventInfoBox from './event-info-box/'

const EventInfo = props => {
  const {
    age_info,
    event_date,
    last_sales_date,
    name,
    image,
    link,
    location,
    subevent_name,
    ticketsAmount,
    parent_child_type
  } = props

  const checkEventImage = () => {
    if (props.events) {
      for (let parentEvent of props.events) {
        if (parentEvent?.id && parentEvent.id === props.parent_id && parentEvent.image) {
          return parentEvent.image
        }
      }
    } else {
      return placeholder_image
    }
  }

  const checkIfParent = () => {
    return parent_child_type === 'PARENT'
  }

  const eventImage = image ? image : checkEventImage()

  const date = () => {
    const { end_date, start_date } = props

    if (
      start_date &&
      end_date &&
      start_date !== end_date
    ) {
      return `${Moment(start_date).format('DD.MM.YYYY')} - ${Moment(
        end_date
      ).format('DD.MM.YYYY')}`
    }

    if (start_date === end_date) {
      return Moment(start_date).format('DD.MM.YYYY')
    }

    if (event_date) {
      return Moment(event_date).format('DD.MM.YYYY [klo] HH')
    }

    return ''
  }

  const end_sale_date_format = () => {
    return Moment(last_sales_date
      ).utc()
      .format('DD.MM.YYYY [klo] HH')
  }

  const eventName = subevent_name
    ? `${name}: ${subevent_name}`
    : name

  const mapUrl = () => {
    let url = props.map.main_map_fi

    url = url.replace(/\/(\d+)_/, '/_')

    return url
  }

  const {
    cheapest_ticket,
    hideAlarm,
    shareUrl,
    ticket_amount,
    map,
    signedIn
  } = props

  return (
    <React.Fragment>
      <div className="break-md" />

      <div className="row">
        <div className="col-sm-5 col-md-4">
          <img
            alt={I18n.t('images.event_image')}
            src={eventImage}
            className="event-show-image"
          />
        </div>

        <div className={`col-sm-7 ${props.map ? 'col-md-4' : 'col-md-8'}`}>
          <h2 className="event-show-title">{eventName}</h2>
          <div className="event-show-date">{date()}</div>

          {!checkIfParent() && (
            <div className="event-show-last-sales-date">{I18n.t('events.last_sales_date')} {end_sale_date_format()}</div>
          )}

          <div className="event-show-location">
            {`${location && location.name ? `${location.name} / ` : ''} ${
              location && location.city ? location.city : ''
            }`}
          </div>
          <div className="event-show-location">{age_info}</div>

          <a
            href={link}
            target="_blank"
            className="link-like"
            rel="noopener noreferrer"
          >
            <Translate value="events.link_to_event" />
          </a>
        </div>

        {/* sharebutton only for single batch page */}
        {shareUrl && (
          <React.Fragment>
            <div className="col-sm-12" />
            <div className="break-md" />
            <div className="col-sm-6 col-md-2 col-lg-2 col-xs-6">
              <FacebookShareButton
                quote={I18n.t('tickets.fb_share')}
                url={shareUrl}
                className="fb-share-button"
              >
                <FacebookIcon size={36} round />
                <Translate value="tickets.share_tickets" />
              </FacebookShareButton>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2 col-xs-6 justify-content-left">
              <CopyTextButton text={shareUrl} />
            </div>
          </React.Fragment>
        )}

        {map && (
          <div className="col-sm-12 col-md-4">
            <img
              src={mapUrl()}
              alt={I18n.t('images.event_map')}
              className="event-section-map"
            />

            <div className="break-md" />
          </div>
        )}
      </div>

      <div className="">
        {!hideAlarm && signedIn && (
          <SubscriptionButton
            handleSetSubscription={props.handleSetSubscription}
            handleDeleteSubscription={props.handleDeleteSubscription}
            id={props.id}
            parentEventId={props.parent_id}
            fetching={props.subscriptionFetch}
            userSubscriptions={props.userSubscriptions}
            parent_childType={props.parent_child_type}
          />
        )}

        {!shareUrl && cheapest_ticket && ticketsAmount > 0 && (
          <EventInfoBox
            title={I18n.t('tickets.starting_at_short')}
            value={<RepresentMoney sum={cheapest_ticket} eurSuffix />}
          />
        )}

        {!shareUrl && ticket_amount > 0 && ticketsAmount > 0 && (
          <EventInfoBox
            title={I18n.t('tickets.selling_title')}
            value={I18n.t('tickets.number_of_tickets', {
              amount: ticket_amount
            })}
          />
        )}
      </div>

      <div className="break-md" />
    </React.Fragment>
  )
}

export default EventInfo
