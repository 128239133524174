import React, { useState } from 'react'
import * as API from '../../utility/api'

const ACTIONS = {
  SALES: 'sales',
  DEBT: 'debt'
}

const Raports = ({ startDate, endDate, setRaportData }) => {
  const [eventID, setEventID] = useState(undefined)
  const [organizerID, setOrganizerID] = useState(undefined)
  const [action, setAction] = useState(ACTIONS.SALES)
  const [loading, setLoading] = useState(false)

  const fetchData = e => {
    e.preventDefault()

    setLoading(true)

    API.POST(`admin/reports/${action}`, { start_date: startDate, end_date: endDate, organizerID, eventID })
      .then(({ data }) => {
        setLoading(false)
        setRaportData(data)
      })
      .catch(e => console.error(e))
  }

  return (
    <form className="admin-panel--raports--form-container" onSubmit={fetchData}>
      <div className="admin-panel--raports--form">
        <label htmlFor="eventID">Tapahtuman tunnus</label>

        <input
          id="eventID"
          type="number"
          placeholder="Tapahtuman ID"
          value={eventID}
          onChange={e => setEventID(e.target.value)}
        />

        <div className="break-sm" />

        <label htmlFor="orgID">Organisaation tunnus</label>

        <input
          id="orgID"
          type="number"
          placeholder="Organisaation ID"
          value={organizerID}
          onChange={e => setOrganizerID(e.target.value)}
        />

        <div className="break-sm" />

        <label htmlFor="debt">
          <input
            id="debt"
            className="raport-radio"
            type="radio"
            checked={action === ACTIONS.DEBT}
            onChange={() => setAction(ACTIONS.DEBT)}
          />
          <span style={{ marginLeft: '5px' }}>Velka (sis. alv)</span>
        </label>

        <label htmlFor="sales">
          <input
            id="sales"
            className="raport-radio"
            type="radio"
            checked={action === ACTIONS.SALES}
            onChange={() => setAction(ACTIONS.SALES)}
          />
          <span style={{ marginLeft: '5px' }}>Myynti</span>
        </label>

        <div className="break-sm" />

        <button disabled={loading} type="submit">
          {loading ? 'Ladataan....' : 'Lataa raportit'}
        </button>
      </div>
    </form>
  )
}

export default Raports
