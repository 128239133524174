import React from 'react'
import TicketDetails from './event-ticket-details/event-ticket-details'
import { Translate } from 'react-redux-i18n'
import { push } from 'connected-react-router'
import moment from 'moment'
import RepresentMoney from '../../utility/represent-money'

class TicketListBatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chosenTickets: 0,
      ticketDetails: false,
      seats: false
    }
  }
  render() {
    const { batch, addToBasket, eventInfo, dispatch, userId, tickets } = this.props

    let resaleEndTime = null
    if(!batch && tickets) {
      resaleEndTime = eventInfo.tickets.map((timeDL) => {
        return moment(new Date(timeDL.last_sales_time))
          .utc()
          .format('D.M.yyyy HH:mm')
      })
    } else if(batch) {
      resaleEndTime = batch.map((timeDL) => {
        return moment(new Date(timeDL.last_sales_time))
          .utc()
          .format('D.M.yyyy HH:mm')
      })
    }

    const priceSum = () => {
      let sum = 0
      Object.values(batch).forEach((ticket) => {
        sum += parseInt(ticket.price, 10)
      })
      return sum
    }

    const singleSell =
      batch.length < 2 || (batch[0].batch && batch[0].batch.single_sell)

    const hideBuyAllButton =
      batch.filter((ticket) => ticket.seller_id === userId).length > 0

    const addAllToBasket = () => {
      Object.values(batch).forEach((ticket) => {
        addToBasket(ticket)
      })
    }

    const backToParent = () => {
      const parentId = eventInfo && eventInfo.event && eventInfo.event.parent_id
      dispatch(push('/events/' + parentId))
    }

    const eventTicketDate = () => {
      if (!eventInfo) return null

      const { start_date, time_info } = eventInfo.event
      return (
        <div className="event-ticket-date">
          {eventInfo && moment(start_date).format('D.M.YYYY')}{' '}
          {!!time_info && (
            <div>
              <small>{time_info}</small>
            </div>
          )}
        </div>
      )
    }

    return (
      <div className="event-ticket-container">
        <div className="row event-ticket-content">
          <div className="col-sm-3 event-ticket-info">
            <div
              className="event-ticket-title cursor-pointer"
              onClick={backToParent}
            >
              {eventInfo && eventInfo.event.name} <br />
            </div>
            {eventTicketDate()}
            <div className="event-ticket-resale">
              <Translate value="tickets.last_sales_time" /> <br />
              <small>{resaleEndTime && resaleEndTime[0]}</small>
              <br />
            </div>
            {!singleSell && (
              <div className="event-ticket-price">
                <Translate value="tickets.sum" />{' '}
                <RepresentMoney sum={priceSum()} eurSuffix />
              </div>
            )}
            {!singleSell && !hideBuyAllButton && (
              <div className="event-ticket-buy-all-container">
                <div
                  className="event-ticket-buy-all cursor-pointer"
                  onClick={() => addAllToBasket()}
                >
                  <Translate value="tickets.buy_all" />
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-8">
            <TicketDetails
              batch={batch}
              addToBasket={addToBasket}
              seats={eventInfo && eventInfo.event.section_map_id !== '0'}
              singleSell={singleSell}
              userId={userId}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TicketListBatch
