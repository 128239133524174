import React from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Translate } from 'react-redux-i18n'
import moment from 'moment'

const showIcon = props => {
  const { status } = props
  if (status === 'success') {
    return (
      <div className="global-notification-icon-container">
        <div className="global-notification-icon">
        <i className="fa-solid fa-circle-xmark fa-2x" style={{color:'#4fc273'}} />
        </div>
      </div>
    )
  } else if (status === 'error') {
    return (
      <div className="global-notification-icon-container">
        <div className="global-notification-icon">
          <i className="fa-solid fa-circle-exclamation fa-2x" style={{color:'#cc2d2d'}}></i>
        </div>
      </div>
    )
  }
  return <React.Fragment />
}

const GlobalNotification = props => {
  const { show } = props
  const params = props.params

  const notification = show ? (
    <CSSTransition
      key={`${moment().format('x')}-${params.value}`}
      classNames="global-notification"
      timeout={{ enter: 500, exit: 500 }}
    >
      <div
        className="global-notification"
        key={`${moment().format('x')}-${params.value}`}
      >
        <div className="global-notification-content">
          {showIcon({ status: params.status })}
          <Translate {...params} className="global-notification-text" />
        </div>
      </div>
    </CSSTransition>
  ) : null

  return (
    <div>
      <div>
        <TransitionGroup>{notification}</TransitionGroup>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    params: state.notification.params,
    show: state.notification.show
  }
}

export default connect(mapStateToProps)(GlobalNotification)
