import React from 'react'
import { Translate } from 'react-redux-i18n'
import RepresentMoney from '../../../utility/represent-money'

const EventTicketDetails = ({
  singleSell,
  batch,
  seats,
  addToBasket,
  userId
}) => {
  console.log(batch, seats, singleSell)
  return (
    <React.Fragment>
      <div className="row detail-title hidden-mobile">
        <div className="col-sm-3">
          <Translate value="tickets.ticket" />
        </div>
        <div className="col-sm-2">
          <Translate value="tickets.base_price" />
        </div>
        <div className="col-sm-2">
          <Translate value="tickets.orig_price" />
        </div>
        {seats && (
          <React.Fragment>
            <div className="col-sm-1">
              <Translate value="tickets.row" />
            </div>
            <div className="col-sm-1">
              <Translate value="tickets.seat" />
            </div>
          </React.Fragment>
        )}
      </div>
      {batch.map((ticket) => {
        const {
          id,
          category,
          section,
          price,
          original_price,
          row_name,
          seat_name
        } = ticket

        console.log(ticket)

        const hideBuyButton = ticket.seller_id === userId

        return (
          <div className="row event-ticket-detail" key={`details${id}`}>
            <div className="col-sm-3">
              {category && category.name}
              <small>{section && section.name}</small>
            </div>

            <div className="col-sm-2">
              <Translate
                value="tickets.base_price"
                className="mobile-category"
              />
              <div className="data-block">
                <RepresentMoney sum={price} eurSuffix />
              </div>
            </div>
            <div className="col-sm-2">
              <Translate
                value="tickets.orig_price"
                className="mobile-category"
              />
              <div className="data-block">
                <RepresentMoney sum={original_price} eurSuffix />
              </div>
            </div>
            {seats && (
              <React.Fragment>
                <div className="col-sm-1">
                  <Translate value="tickets.row" className="mobile-category" />
                  {row_name}
                </div>
                <div className="col-sm-2">
                  <Translate value="tickets.seat" className="mobile-category" />
                  {seat_name}
                </div>
              </React.Fragment>
            )}
            {singleSell && !hideBuyButton && (
              <div className="col-sm-2">
                <div
                  className="pull-right event-ticket-buy cursor-pointer"
                  onClick={() => addToBasket(ticket)}
                >
                  <Translate value="tickets.buy" />
                </div>
              </div>
            )}
          </div>
        )
      })}
    </React.Fragment>
  )
}

export default EventTicketDetails
