import React from 'react'
import { Link } from 'react-router-dom'
import liputonLogo from '../assets/Liputon-orange.svg'

const FooterLogo = () => {
  return (
    <div className="row">
      <div className="col-sm-3 text-center">
        <div id="logoContainer" className="footer-logo inline-block text-left">
          <Link to="/">
            <img src={liputonLogo} alt="liputonLogo" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FooterLogo
