import React from 'react'
import { Translate } from 'react-redux-i18n'
import {
  initiateVerification,
  resendConfirmationCode
} from '../../actions/users'
import GlobalLoadingIndicator from '../../components/global-loading-indicator'

class ConfirmationForm extends React.Component {
  state = {
    email: this.props.email,
    verificationCode: '',
    resent: false,
    fromUrl: false
  }

  constructor(props) {
    super(props)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('code') && urlParams.has('email')) {
      this.state.fromUrl = true
      this.state.email = urlParams.get('email').replace(' ', '+')
      this.state.verificationCode = urlParams.get('code')
    }
  }

  handleChange = target => {
    return e => {
      this.setState({
        [target]: e.target.value
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const { dispatch } = this.props
    const { email, verificationCode } = this.state

    dispatch(initiateVerification(email, verificationCode))
  }

  handleResend = () => {
    const { dispatch } = this.props

    this.setState({ resent: true })

    setTimeout(() => this.setState({ resent: false }), 5000)

    dispatch(resendConfirmationCode(this.state.email))
  }

  render() {
    return (
      <div className="user-info-form">
        <div className="row">
          <div className="bold">
            <Translate value="registration.confirmation_code_sent" />
          </div>
        </div>
        <div className="break-lg" />
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <label htmlFor="verificationEmail">
              <Translate value="registration.email" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <input
              id="verificationEmail"
              type="email"
              readOnly={this.state.fromUrl}
              value={this.state.email}
              onChange={this.handleChange('email')}
              className="user-info"
              required
            />
          </div>

          <div className="break-lg" />

          <div className="row">
            <label htmlFor="verificationCode">
              <Translate value="registration.confirmation_code" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <input
              type="text"
              id="verificationCode"
              value={this.state.verificationCode}
              onChange={this.handleChange('verificationCode')}
              className="user-info"
              required
            />

            {this.props.error && (
              <div className="row">
                <div className="error-message">{this.props.error}</div>
              </div>
            )}
          </div>

          <div className="break-lg" />
          <div className="break-md" />

          <div
            className="row description-text cursor-pointer"
            onClick={this.handleResend}
          >
            {!this.state.resent && (
              <Translate value="registration.resend_code" />
            )}
            {this.state.resent && <Translate value="tickets.code_resended" />}
          </div>

          <div className="break-sm" />

          <button type="submit">
            {this.props.verificationFetching ? (
              <GlobalLoadingIndicator center />
            ) : (
              <Translate value="registration.continue" />
            )}
          </button>
        </form>
      </div>
    )
  }
}

export default ConfirmationForm
