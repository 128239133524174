import React from 'react'
import { Translate } from 'react-redux-i18n'

const UserPageInfo = props => {
  const { last_name, first_name, email, group } = props
  const admin = group?.name === 'admin'
  const phone = props.phone_number || ''
  const allow_marketing = props.allow_marketing || false

  return (
    <React.Fragment>
      <h2>
        <Translate value="user_page.info.title" />
      </h2>

      <div className="row">
        <div className="col-sm-4 user-page--user-info--header">
          <div className="setting-label">
            <Translate value="registration.given_name" />
          </div>
          <div>{first_name}</div>
        </div>

        <div className="col-sm-4 user-page--user-info--header">
          <div className="setting-label">
            <Translate value="registration.family_name" />
          </div>
          <div>{last_name}</div>
        </div>

        <div className="col-sm-4 user-page--user-info--header">
          <div className="setting-label">
            <Translate value="registration.email" />
          </div>
          <div>{email}</div>
        </div>

        <div className="col-sm-4 user-page--user-info--header">
          <div className="setting-label">
            <Translate value="user.phone_number" />
          </div>
          <div>{phone}</div>
        </div>

        <div className="col-sm-12 user-page--user-info--header">
          <div className="setting-label">
            <Translate value="registration.marketing_licence" />
          </div>
          <div>
            <Translate
              value={
                allow_marketing
                  ? 'registration.marketing_allow'
                  : 'registration.marketing_denied'
              }
            />
          </div>
        </div>
      </div>

      <div className="break-lg" />
      <div className="break-lg" />

      <div className="row">
        <div
          className={`col-sm-6 col-md-5 col-lg-4 ${
            admin ? 'col-sm-push-6 col-md-push-5 col-lg-push-4' : ''
          }`}
        >
          <button onClick={props.changeToUpdate} className="button-wide">
            <Translate
              value="general.update_information"
              style={{ textTransform: 'uppercase' }}
            />
          </button>
        </div>
        {admin && (
          <div className="col-sm-6 col-md-5 col-md-pull-5 col-sm-pull-6 col-lg-4 col-lg-pull-4">
            <button
              className="secondary-button button-wide"
              onClick={props.adminConsole}
            >
              ADMIN CONSOLE
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default UserPageInfo
