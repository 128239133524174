import { SET_BASKET, SET_ERROR, CLEAR_ERRORS } from '../actionTypes'

export const setBasket = basket => {
  return dispatch => {
    dispatch({
      type: SET_BASKET,
      basket
    })
  }
}

export const setError = error => {
  return dispatch => {
    dispatch({ type: SET_ERROR, error })
  }
}

export const clearErrors = () => {
  return dispatch => dispatch({ type: CLEAR_ERRORS })
}
