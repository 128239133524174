import React from 'react'
import { connect } from 'react-redux'
import * as API from '../utility/api'
import { Translate } from 'react-redux-i18n'
import { paymentSuccessToServer, killBasket } from '../actions/shopping-basket'
import trim from 'lodash/trim'
import fromPairs from 'lodash/fromPairs'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { pdfjs, Document, Page } from 'react-pdf'
import download from '../lib/downloader'

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`

// there are two possible outcomes with the payment:
// cancel and success
// cancel happens when any error or actual user cancel happens
// success happens only if the payment is succesful
class PaymentResult extends React.Component {
  state = {
    pdfData: [],
    pageNumber: 1
  }

  componentDidMount() {
    if (this.props.match.params.result === 'cancel') {
      // uncomment to test killbasket after bank without buying a ticket
      //this.props.dispatch(killBasket())
      API.apiNotification({ value: 'payment.cancel', status: 'success' })
    }

    if (this.props.match.params.result === 'success') {
      this.props.dispatch(killBasket(false))

      this.dispatchSuccessToServer()

      // this.checkIfLoadNeeded()
    }
  }

  componentDidUpdate() {
    this.checkIfLoadNeeded()
  }

  checkIfLoadNeeded = () => {
    const { basket } = this.props

    if (this.props.basket && this.state.pdfData.length === 0) {
      this.loadPDFData(basket.ticket_pdf_names)
    }
  }

  loadPDFData = pdfNames => {
    const pdfFetches = pdfNames.map(file_name => {
      const url = `tickets/${file_name}/pdf`.replace(/"/g, '')

      return API.GET(url)
    })

    Promise.all(pdfFetches)
      .then(requests => {
        const pdfData = requests.map(req => req.data.pdf_info)
        this.setState({
          pdfData
        })
      })
      .catch(() => {
        console.log('Fetch failed')
      })
  }

  dispatchSuccessToServer = () => {
    const { dispatch, location } = this.props

    const returnParameters = fromPairs(
      location.search.split('&').map(e => trim(e, '?').split('='))
    )

    dispatch(paymentSuccessToServer(returnParameters))
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  pdfs = () => {
    const { pdfData } = this.state
    if (Object.values(pdfData).length === 0) {
      return (
        <div className="payment-result-loading-container">
          <GlobalLoadingIndicator center />
          <Translate value="payment.handling_tickets" />
        </div>
      )
    }

    return pdfData.map(d => (
      <div
        key={d.pdf_name}
        styles={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <button
          onClick={() => {
            const data = `data:application/octet-stream;charset=utf-16be;base64,${d.pdf_data}`
            download(data, d.pdf_name)
          }}
        >
          <Translate value="payment.download_pdf" />
        </button>
        <br />
        <br />

        <Document
          file={`data:application/pdf;base64,${d.pdf_data}`}
          onLoadSuccess={this.onDocumentLoadSuccess}
          loading={<GlobalLoadingIndicator center />}
        >
          <Page pageNumber={this.state.pageNumber} />
        </Document>
      </div>
    ))
  }

  render() {
    const { errors } = this.props
    const result = this.props.match.params.result

    const cancelContent = (
      <div className="col-sm-8 col-sm-offset-2">
        <div className="break-lg" />

        <div className="row">
          <Translate value="payment.cancel" />
        </div>

        <div className="break-lg" />

        <div className="row">
          <Translate value="payment.no_charge" />
        </div>
      </div>
    )

    const successContent = (
      <div className="col-sm-8 col-sm-offset-2">
        <div className="break-lg" />
        <div className="break-lg" />

        <h2>
          <Translate
            style={{ textTransform: 'uppercase' }}
            value="payment.thanks"
          />
        </h2>
        <Translate value="payment.success" />

        <div className="break-lg" />
        {/* TODO: how this should be handled */}
        {/* this.pdfs() */}
      </div>
    )

    const errorContent = (
      <div className="col-sm-8 col-sm-offset-2">
        <div className="break-lg" />
        <div className="break-lg" />

        <h2>
          <Translate value="payment.problem_in_process_header" />
        </h2>

        {errors.map(e => (
          <div key={e}>
            <Translate value={e.message} />
          </div>
        ))}
      </div>
    )

    return (
      <div className="row">
        {errors && !!errors.length && errorContent}
        {errors.length === 0 && result === 'cancel' && cancelContent}
        {errors.length === 0 && result === 'success' && successContent}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  basket: state.payment.basket,
  errors: state.payment.errors
})

export default connect(mapStateToProps)(PaymentResult)
