import { HIDE_NOTIFICATION, INVOKE_NOTIFICATION } from '../actionTypes'

export const invokeNotification = params => {
  return dispatch => {
    dispatch({
      type: INVOKE_NOTIFICATION,
      params
    })

    setTimeout(() => {
      dispatch(hideNotification())
    }, 7000)
  }
}

const hideNotification = () => {
  return {
    type: HIDE_NOTIFICATION
  }
}
