import React from 'react'
import { connect } from 'react-redux'
import {
  initiatePasswordRecovery,
  confirmPasswordRecovery
} from '../actions/users'
import { Translate, I18n } from 'react-redux-i18n'
import * as API from '../utility/api'

class PasswordRecovery extends React.Component {
  state = {
    step: 0,
    code: '',
    email: '',
    password: '',
    passwordConfirm: '',
    codeSent: false
  }

  handleInitiateSubmit = e => {
    e.preventDefault()

    const { dispatch } = this.props
    const { email } = this.state

    dispatch(initiatePasswordRecovery(email))

    this.setState({
      step: 1,
      email: '',
      codeSent: true
    })
  }

  handleConfirmSubmit = e => {
    e.preventDefault()

    const { dispatch } = this.props
    const { email, code, password, passwordConfirm } = this.state

    if (password !== passwordConfirm) {
      API.apiNotification({
        value: 'notifications.passwords_not_matching',
        status: 'error'
      })
      return
    }

    dispatch(confirmPasswordRecovery(email, code, password))
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('code') && urlParams.has('email')) {
      this.setState({
        email: urlParams.get('email'),
        code: urlParams.get('code'),
        step: 1
      });
    }
  }

  render() {
    const { codeSent } = this.state

    const initiate = (
      <React.Fragment>
        <div className="row description-text">
          <Translate value="recovery.info" />
        </div>

        <div className="break-lg" />

        <form onSubmit={this.handleInitiateSubmit}>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <input
                type="email"
                placeholder={I18n.t('recovery.email')}
                required
                className="user-info"
                value={this.state.email}
                onChange={e => {
                  this.setState({
                    email: e.target.value
                  })
                }}
              />
            </div>
          </div>

          <div className="break-lg" />

          <div className="row">
            <button type="submit">
              {codeSent ? (
                <Translate value="recovery.ask_new_code" />
              ) : (
                <Translate value="recovery.ask" />
              )}
            </button>
          </div>

          <div className="break-sm" />

          <div
            className="row cursor-pointer description-text"
            onClick={() => this.setState({ step: 1 })}
          >
            <Translate value="recovery.user_already_have_code" />
          </div>
        </form>
      </React.Fragment>
    )

    const confirm = (
      <React.Fragment>
        <div className="row description-text">
          <Translate value="recovery.instructions" />
        </div>

        <div className="break-lg" />

        <form onSubmit={this.handleConfirmSubmit}>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <input
                type="email"
                placeholder={I18n.t('recovery.email')}
                required
                className="user-info"
                value={this.state.email}
                onChange={e => {
                  this.setState({
                    email: e.target.value
                  })
                }}
              />
            </div>

            <div className="break-lg" />

            <div className="col-sm-10 col-sm-offset-1">
              <input
                type="text"
                placeholder={I18n.t('recovery.confirmation_code')}
                required
                className="user-info"
                value={this.state.code}
                onChange={e => {
                  this.setState({
                    code: e.target.value.trim()
                  })
                }}
              />
            </div>

            <div className="break-lg" />

            <div className="col-sm-10 col-sm-offset-1">
              <input
                type="password"
                placeholder={I18n.t('recovery.password')}
                required
                className="user-info"
                value={this.state.password}
                onChange={e => {
                  this.setState({
                    password: e.target.value
                  })
                }}
                autoComplete={'new-password'}
              />
            </div>

            <div className="break-lg" />

            <div className="col-sm-10 col-sm-offset-1">
              <input
                type="password"
                placeholder={I18n.t('recovery.password_confirmation')}
                className="user-info"
                required
                value={this.state.passwordConfirm}
                onChange={e => {
                  this.setState({
                    passwordConfirm: e.target.value
                  })
                }}
                autoComplete={'new-password'}
              />
            </div>

            <div className="break-lg" />

            <div className="row">
              <button type="submit">
                <Translate value="recovery.change_password" />
              </button>
            </div>

            <div className="break-sm" />

            <div
              className="row cursor-pointer description-text"
              onClick={() => this.setState({ step: 0 })}
            >
              <Translate value="recovery.ask_new_code" />
            </div>
          </div>
        </form>
      </React.Fragment>
    )

    return (
      <div className="row">
        <div className="col-sm-4 col-sm-offset-4 text-center">
          <div className="break-30vh" />

          <div className="row bigbold">
            <Translate value="recovery.password_recovery" />
          </div>

          <div className="break-sm" />

          {this.state.step === 0 ? initiate : confirm}
        </div>
      </div>
    )
  }
}

export default connect()(PasswordRecovery)
