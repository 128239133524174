import React, { useState } from 'react'
import TranslationRowInput from './translation-row-input'

const TranslationRowListItem = props => {
  const [open, setOpen] = useState(false)
  const {
    info,
    lang,
    updateTranslation,
    dispatchAddFaqRow,
    indent,
    path
  } = props

  const translationRows = Object.entries(info[1]).map(row => {
    const rowInfo = {
      transKey: row[0],
      value: row[1],
      topic: info[0],
      lang
    }

    if (typeof rowInfo.value === 'object') {
      return (
        <React.Fragment key={rowInfo.transKey}>
          <TranslationRowListItem
            lang={rowInfo.lang}
            info={[rowInfo.transKey, rowInfo.value]}
            updateTranslation={updateTranslation}
            dispatchAddFaqRow={dispatchAddFaqRow}
            indent={indent + 1}
            path={[...path, rowInfo.topic]}
          />
          <div className='break-sm' />
        </React.Fragment>
      )
    }

    return (
      <TranslationRowInput
        key={row[0] + row[1]}
        {...rowInfo}
        updateTranslation={updateTranslation}
        path={path}
      />
    )
  })

  if (info[0] === 'faq') {
    translationRows.push(
      <div key={'add-row'} className='col-sm-8'>
        <div className='break-sm' />
        <div className='text-right'>
          <button onClick={() => dispatchAddFaqRow(lang)}>Lisää rivi</button>
        </div>
      </div>
    )
  }

  return (
    <div
      className='tl-translation-item'
      style={{ marginLeft: `${indent * 2}em` }}
    >
      <div
        className='col-sm-6 cursor-pointer bold'
        onClick={() => setOpen(!open)}
      >
        {info[0]}
      </div>

      {open && translationRows}
    </div>
  )
}

export default TranslationRowListItem
