import './bootstrap.scss'
import './styles/events.scss'
import './styles/liputon.scss'
import './styles/tickets.scss'
import './styles/main-page.scss'
import './styles/userpage.scss'
import './styles/login.scss'
import './styles/shopping-basket.scss'
import './styles/payment.scss'
import './styles/adminpage.scss'
import './styles/footer.scss'
import './styles/bargain-ticket.scss'
import './styles/header.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Route, Switch, Redirect, withRouter } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import GlobalLoadingIndicator from './components/global-loading-indicator'
import PrivateRoute, { scopes } from './components/private-route'
import MainPage from './layouts/main-page'
import SearchPage from './layouts/search-page'
import UserPage from './layouts/user-page'
import AdminConsole from './containers/admin-console-page'
import Bargains from './containers/bargains'
import Login from './containers/login'
import Verify from './containers/verify'
import LoginModal from './containers/login-modal'
import AdditionalInfoModal from './containers/additional-info-modal'
import SocialLogin from './containers/social-login'
import Header from './containers/header'
import Hero from './containers/hero'
import Footer from './containers/footer'
import TicketsSell from './layouts/tickets-sell-page'
import Faq from './components/faq'
import EventShow from './containers/event-show'
import GlobalNotification from './containers/global-notification'
import PasswordRecovery from './containers/password-recovery'
import PaymentResult from './containers/payment-result'
import PaymentWall from './containers/payment-wall'
import Terms from './components/terms'
import moment from 'moment'
import jwtDecode from 'jwt-decode'

import ShoppingBasket from './containers/shopping-basket'
import SingleBatchSellPage from './containers/single-batch-sell-page'

import { getAllEvents } from './actions/events'
import { refreshTokens } from './actions/users'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

// I18n
import { syncTranslationWithStore } from 'react-redux-i18n'
import loadAndSetTranslations from './utility/loadTranslations'

import { store, history, persistor } from './utility/createStore'

moment.locale('fi')

ReactGA.initialize('G-0V577RJ11J')

// sets translations to reducer
syncTranslationWithStore(store)
loadAndSetTranslations(store)

// start loading all event infos
store.dispatch(getAllEvents())

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

const TopScroller = withRouter(ScrollToTop)

const TokenChecker = ({ store }) => {
  // Check if tokens are alive and fetch new if not
  const state = store.getState()
  const tokens = state && state.users && state.users.tokens
  const accessToken = tokens && tokens.access_token

  if (accessToken) {
    const tokenExp = jwtDecode(accessToken).exp
    const now = moment().unix()

    // if token is going to expire in 5 minutes, get a new one
    if (tokenExp < now - 5 * 60) {
      store.dispatch(refreshTokens(tokens))
    }
  }

  return null
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<GlobalLoadingIndicator center />}
      persistor={persistor}
    >
      <TokenChecker store={store} />
      <ConnectedRouter history={history}>
        <div className="container-margins">
          <div className="layout-container">
            <div className="footer-push">
              <TopScroller />
              <Header />
              <Hero />
              <LoginModal />
              <AdditionalInfoModal />

              <GlobalNotification />
              <Switch>
                <Route exact path="/" component={MainPage} />

                <Route exact path="/payment" component={PaymentWall} />
                <Route path="/payment/:result" component={PaymentResult} />

                {/* user paths */}
                <Route exact path="/register" component={Login} isRegister />
                <Route exact path="/register/verify" component={Verify} />
                <Route exact path="/login/:origin" component={SocialLogin} />
                <PrivateRoute
                  exact
                  path="/user"
                  component={UserPage}
                  store={store}
                  scope={scopes.LOGGED_IN}
                />
                <Route exact path="/recovery" component={PasswordRecovery} />

                {/* ticket paths */}
                <PrivateRoute
                  exact
                  path="/tickets/sell"
                  component={TicketsSell}
                  store={store}
                  scope={scopes.LOGGED_IN}
                />

                <Route
                  path="/tickets/:batchId"
                  component={SingleBatchSellPage}
                />

                {/* event paths */}
                <Route exact path="/events" component={SearchPage} />
                <Route exact path="/events/:id" component={EventShow} />
                <Route exact path="/basket" component={ShoppingBasket} />

                {/* misc paths */}
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/bargains" component={Bargains} />

                {/* admin paths */}
                <PrivateRoute
                  exact
                  path="/admin"
                  component={AdminConsole}
                  store={store}
                  scope={scopes.ADMIN}
                />

                {/* term paths */}
                <Route
                  exact
                  path="/termsandconditions"
                  component={Terms}
                  termsandconditions
                />

                <Route
                  exact
                  path="/termsofuse"
                  render={props => {
                    return <Terms {...props} title="termsofuse" />
                  }}
                />

                <Route
                  exact
                  path="/termsofpurchaseandsale"
                  render={props => {
                    return <Terms {...props} title="termsofpurchaseandsale" />
                  }}
                />

                {/* all of the rest*/}
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
