import React from 'react'
import { Translate } from 'react-redux-i18n'
import { updateUserInfos } from '../../actions/users'
import * as API from '../../utility/api'
import GlobalLoadingIndicator from '../../components/global-loading-indicator'
import ValidatedInput from './../validated-input'

class UserPageUpdate extends React.Component {
  state = {
    id: this.props.id,
    email: this.props.email,
    previousPassword: '',
    password: '',
    passwordConfirm: '',
    firstName: this.props.first_name,
    lastName: this.props.last_name,
    allowMarketing: this.props.allow_marketing || false,
    phoneNumber: this.props.phone_number || '',
    iban: this.props.iban || null,
    bic: this.props.bic || null,
    is_seller: this.props.is_seller || false,
    valid: {
      password: false,
      passwordConfirm: false
    },
    successfulMessageTranslateKey: undefined
  }
  handleChange = target => {
    return e => {
      this.setState({
        [target]: e.target.value
      })
    }
  }

  handleInfoSubmit = e => {
    e.preventDefault()

    const { dispatch } = this.props
    const { firstName, lastName, phoneNumber, allowMarketing, id, iban, bic } = this.state

    dispatch(
      updateUserInfos({ firstName, lastName, phoneNumber, allowMarketing, iban, bic }, id)
    )
  }

  handlePasswordSubmit = async e => {
    e.preventDefault()

    const { dispatch } = this.props
    const { password, previousPassword, id } = this.state

    if (this.checkPasswordsMatch()) {
      await dispatch(updateUserInfos({ password, previousPassword }, id))

      this.setState({
        password: '',
        passwordConfirm: '',
        previousPassword: ''
      })
      if (!this.props.userInfoUpdateError) {
        this.passwordChangedSuccesfully()
      }
    } else {
      API.apiNotification({
        value: 'notifications.passwords_not_matching',
        status: 'error'
      })
    }
  }

  passwordChangedSuccesfully = () => {
    this.setState({
      successfulMessageTranslateKey: 'user.password_changed_succesfully'
    })
    setTimeout(() => {
      this.setState({ successfulMessageTranslateKey: undefined })
    }, 6000)
  }

  checkPasswordsMatch = () => {
    const { password, passwordConfirm } = this.state
    return password === passwordConfirm
  }

  validPassword = () => {
    this.setState({
      valid: { ...this.state.valid, password: true }
    })
  }

  validPasswordConfirmation = () => {
    this.setState({
      valid: { ...this.state.valid, passwordConfirm: true }
    })
  }

  checkMarketing = e => {
    this.setState({
      allowMarketing: e.target.checked
    })
  }

  render() {
    const { userInfoUpdateError, userInfoUpdating } = this.props
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      previousPassword,
      valid,
      password,
      passwordConfirm,
      successfulMessageTranslateKey,
      allowMarketing,
      iban,
      bic
    } = this.state

    const allPasswordFieldsDirty =
      password.length === 0 &&
      passwordConfirm.length === 0 &&
      previousPassword.length === 0

    const form = (
      <div className="user-info-form">
        <form onSubmit={this.handleInfoSubmit}>
          <div className="row">
            <label htmlFor="loginEmail">
              <Translate value="registration.email" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <input
              type="email"
              id="loginEmail"
              value={email}
              className="user-info"
              readOnly
            />
          </div>

          <div className="break-lg" />

          <div className="row">
            <label htmlFor="firstName">
              <Translate value="registration.given_name" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <input
              type="text"
              id="firstName"
              value={firstName}
              className="user-info"
              onChange={this.handleChange('firstName')}
              required
            />
          </div>

          <div className="break-lg" />

          <div className="row">
            <label htmlFor="lastName">
              <Translate value="registration.family_name" />
            </label>

            <div className="break-sm" />

            <input
              type="text"
              id="lastName"
              value={lastName}
              className="user-info"
              onChange={this.handleChange('lastName')}
              required
            />
          </div>

          <div className="break-lg" />

          <div className="row">
            <label htmlFor="phoneNumber">
              <Translate value="user.phone_number" />
            </label>

            <div className="break-sm" />

            <input
              type="number"
              id="phoneNumber"
              value={phoneNumber}
              className="user-info"
              onChange={this.handleChange('phoneNumber')}
              required
            />
          </div>

          <div className="break-lg" />

          <div className="row">
              <label htmlFor="iban">
                <Translate value="user.iban" />
              </label>

              <div className="break-sm" />
            
              <input
                type="text"
                id="iban"
                value={iban}
                className="user-info"
                onChange={this.handleChange('iban')}
              />

          </div>

          <div className="break-lg" />

          <div className="row">
              <label htmlFor="bic">
                <Translate value="user.bic" />
              </label>

              <div className="break-sm" />
            
              <input
                type="text"
                id="bic"
                value={bic}
                className="user-info"
                onChange={this.handleChange('bic')}
              />

          </div>

          <div className="break-lg" />
          <div className="row">
            <div className="check-terms-container">
              <label htmlFor="user-page-update-marketing">
                <input
                  id="user-page-update-marketing"
                  type="checkbox"
                  onChange={this.checkMarketing}
                  checked={allowMarketing}
                />
                <Translate value="registration.confirm_marketing_licence" />
              </label>
            </div>
          </div>
          <div className="break-lg" />

          <div className="row">
            <button type="submit">
              <Translate value="user.update" />
            </button>
          </div>
          <div className="break-lg" />
        </form>
      </div>
    )

    const passwordForm = (
      <div className="user-info-form">
        <form onSubmit={this.handlePasswordSubmit}>
          <div className="row">
            <label htmlFor="updatePasswordPrevious">
              <Translate value="user.old_password" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <input
              type="password"
              id="updatePasswordPrevious"
              value={previousPassword}
              className="user-info"
              onChange={this.handleChange('previousPassword')}
              autoComplete="current-password"
            />
          </div>

          <div className="break-lg" />

          <div className="row">
            <label htmlFor="updatePassword">
              <Translate value="registration.password" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <ValidatedInput
              onValid={this.validPassword}
              validator={/^(?=.*[A-Ö])(?=.*[a-ö])(?=.*\d).{8,}$/.test(password)}
              valid={valid.password}
              value={password}
              describe={
                <Translate value="registration.password_requirements" />
              }
            >
              <input
                type="password"
                id="updatePassword"
                value={password}
                className="user-info"
                onChange={this.handleChange('password')}
                autoComplete="new-password"
              />
            </ValidatedInput>
          </div>

          <div className="break-lg" />

          <div className="row">
            <label htmlFor="updatePasswordConfirmation">
              <Translate value="registration.confirm_password" />
            </label>
          </div>

          <div className="break-sm" />

          <div className="row">
            <ValidatedInput
              onValid={this.validPasswordConfirmation}
              validator={
                password.length > 0 &&
                passwordConfirm.length > 0 &&
                password === passwordConfirm
              }
              valid={valid.passwordConfirm}
              value={passwordConfirm}
              describe={<Translate value="registration.passwords_dont_match" />}
            >
              <input
                type="password"
                id="updatePasswordConfirmation"
                value={passwordConfirm}
                className="user-info"
                onChange={this.handleChange('passwordConfirm')}
                autoComplete={'new-password'}
              />
            </ValidatedInput>
          </div>

          <div className="break-lg" />

          <div className="row">
            <button
              type="submit"
              disabled={
                allPasswordFieldsDirty &&
                !valid.password &&
                !valid.passwordConfirm
              }
            >
              <Translate value="user.update_password" />
            </button>
          </div>
        </form>
      </div>
    )

    return (
      <React.Fragment>
        {userInfoUpdating ? (
          <GlobalLoadingIndicator center />
        ) : (
          <React.Fragment>
            {userInfoUpdateError && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="error-message text-center">
                    <Translate
                      value={`user_page.info.${userInfoUpdateError}`}
                    />
                  </div>
                </div>
                <div className="break-md" />
              </div>
            )}
            {successfulMessageTranslateKey && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="success-message text-center">
                    <Translate value={successfulMessageTranslateKey} />
                  </div>
                </div>
              </div>
            )}

            <h2>
              <Translate value="user_page.info.modify" />
            </h2>

            <div className="row">
              <div className="col-sm-6 extra-right-left-padding">{form}</div>
              <div className="col-sm-6 extra-right-left-padding">
                {passwordForm}
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default UserPageUpdate
