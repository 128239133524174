import {
  ADD_TICKET_TO_BASKET,
  ADD_END_TIME,
  ADD_EMAIL_TO_BASKET,
  REMOVE_TICKET_FROM_BASKET,
  RESERVE_SUCCESS,
  KILL_BASKET,
  START_FETCH,
  RESERVE_FAILURE,
  STOP_FETCH,
  KILL_FETCHES,
  ADD_TIMER,
  ADD_TIME_LEFT,
  RESET_TIMERS,
  ADD_ERROR,
  SET_NAMED_INFOS,
  SET_VALID_EVENT
} from '../actionTypes'
import moment from 'moment'

export default function shoppingBasket(
  state = {
    ticketList: [],
    eventInfo: [],
    endTime: null,
    isFetching: false,
    ticketsFetching: [],
    totalPrice: 0,
    pdfData: [],
    email: '',
    timers: [],
    timeLeft: null,
    errors: [],
    namedInfos: [],
    validEvents: {}
  },
  action
) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        return {
          ...action.payload.shoppingBasket,
          isFetching: false
        }
      }

      return state

    case START_FETCH:
      const newFetch =
        state.ticketsFetching &&
        state.ticketsFetching.filter(ticket => ticket === action.ticketId)
          .length === 0
          ? [...state.ticketsFetching, action.ticketId]
          : state.ticketsFetching

      return {
        ...state,
        isFetching: true,
        ticketsFetching: newFetch
      }

    case SET_VALID_EVENT:
      const { value, event } = action

      return {
        ...state,
        validEvents: { ...state.validEvents, [event]: value }
      }

    case SET_NAMED_INFOS:
      const infos = action.info

      return {
        ...state,
        namedInfos: infos
      }

    case STOP_FETCH:
      const updatedFetch = state.ticketsFetching.filter(
        ticket => ticket !== action.ticket
      )
      return {
        ...state,
        ticketsFetching: updatedFetch
      }

    case KILL_FETCHES:
      return {
        ...state,
        ticketsFetching: []
      }

    case ADD_END_TIME:
      const newEndTime = state.endTime ? state.endTime : action.end_time

      return {
        ...state,
        endTime: newEndTime
      }

    case ADD_TIMER:
      const newTimers = [...state.timers, action.timer]

      return {
        ...state,
        timers: newTimers
      }

    case ADD_TIME_LEFT:
      return {
        ...state,
        timeLeft: action.time_left
      }

    case RESET_TIMERS:
      return {
        ...state,
        timers: [],
        timeLeft: null
      }

    case ADD_EMAIL_TO_BASKET:
      return {
        ...state,
        email: action.email
      }

    case ADD_TICKET_TO_BASKET: {
      const newEndTime = state.endTime
        ? state.endTime
        : moment()
            .add(10, 'minutes')
            .unix()

      const newEventInfo =
        state.eventInfo.filter(event => event.id === action.eventInfo.id)
          .length === 0
          ? [...state.eventInfo, action.eventInfo]
          : state.eventInfo

      const newTicketList =
        state.ticketList.filter(ticket => ticket.id === action.ticket.id)
          .length === 0
          ? [...state.ticketList, action.ticket]
          : state.ticketList

      const newTotalPrice = newTicketList.reduce(
        (acc, curr) => acc + parseInt(curr.price, 10),
        0
      )
      return {
        ...state,
        ticketList: newTicketList,
        eventInfo: newEventInfo,
        endTime: newEndTime,
        isFetching: false,
        totalPrice: newTotalPrice
      }
    }
    case REMOVE_TICKET_FROM_BASKET: {
      const newTicketList = state.ticketList.filter(
        ticket => ticket.id !== action.id
      )

      const newTotalPrice = newTicketList.reduce(
        (acc, curr) => acc + parseInt(curr.price, 10),
        0
      )

      const newEndTime = newTicketList.length === 0 ? null : state.endTime

      const newValidEvents = { ...state.validEvents }
      delete newValidEvents[action.id]

      return {
        ...state,
        ticketList: newTicketList,
        totalPrice: newTotalPrice,
        endTime: newEndTime,
        validEvents: newValidEvents
      }
    }
    case KILL_BASKET:
      for (let i = 0; i < state.timers.length; i++) {
        clearInterval(state.timers[i])
      }
      return {
        ...state,
        ticketList: [],
        eventInfo: [],
        endTime: null,
        errors: [],
        namedInfos: [],
        validEvents: {}
      }
    case RESERVE_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case RESERVE_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    case ADD_ERROR:
      return {
        ...state,
        errors: action.payload.errors
      }
    default:
      return state
  }
}
