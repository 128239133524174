import React from 'react'
import { connect } from 'react-redux'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import BuyableBargainList from '../components/bargain-tickets/bargain-ticket-list'
import { Translate } from 'react-redux-i18n'
import * as API from '../utility/api'
import MenuContainer from '../components/mainpage-menu-container'
import produce from 'immer'

class Bargains extends React.Component {
  state = {
    bargainTickets: null,
    error: false
  }

  componentDidMount() {
    API.GET('tickets/bargains')
      .then(({ data }) => {
        this.setState({ bargainTickets: data })
      })
      .catch(error => {
        this.setState({ error: true })
        console.log(error);
      })
  }

  // delete ticket function is made to be used differently
  // this wrapper is a bubble gum between bargain list and that function
  removeTicketFromListWrapper = signedIn => {
    return (dispatch, event, fn) => {
      return ticket => {
        if (signedIn) {
          this.removeFromList(event.id, ticket)
        }

        fn(event, dispatch)(ticket)
      }
    }
  }

  removeFromList = (eventId, ticket) => {
    const newTickets = produce(this.state.bargainTickets, draft => {
      draft[eventId].tickets = draft[eventId].tickets.filter(
        t => t.id !== ticket.id
      )
    })

    this.setState({
      bargainTickets: newTickets
    })
  }

  render() {
    const { bargainTickets, error } = this.state
    const { events, dispatch, signedIn } = this.props

    return (
      <div className="section">
        <MenuContainer />

        <h2>
          <Translate value="events.bargains" />
        </h2>
        <p>
          <Translate value="tickets.bargains_description" />
        </p>

        <div className="break-lg" />
        <div className="row">
          {bargainTickets ? (
            <React.Fragment>
              {Object.keys(bargainTickets).length > 0 && (
                <BuyableBargainList
                  isFetching={false}
                  tickets={bargainTickets}
                  events={events}
                  dispatch={dispatch}
                  removeTicketFromListWrapper={this.removeTicketFromListWrapper(
                    signedIn
                  )}
                />
              )}

              {bargainTickets.length === 0 && (
                <div className="col-sm-12">
                  <Translate value="tickets.bargains_not_available" />
                </div>
              )}
            </React.Fragment>
          ) : error ? (
            <div>Error</div>
          ) : (
            <div className="col-md-offset-6">
              <GlobalLoadingIndicator />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    events: state.events.events,
    signedIn: state.users.signedIn
  }
}

export default connect(mapStateToProps)(Bargains)
