import React from 'react'
import { changeStep, steps } from '../../actions/ticket-sale-steps'
import AccountNumber from './ticket-account-number'
import AcceptTerms from './ticket-accept-terms'

/*  max/min price step skipped due to lack of information how it will work
 *   import MaxPrice from '../components/ticket-max-price'*/

const SellFormLatter = ({
  dispatch,
  currentPhase,
  bankAccountValid,
  bankAccountNumber,
  termsAccepted,
  isFetching,
  ticketCode,
  userIBAN,
  submitError
}) => {
  return (
    <React.Fragment>
      <AccountNumber
        thisPhase={() => dispatch(changeStep(steps.accountNumber))}
        nextPhase={() => dispatch(changeStep(steps.acceptTerms))}
        visible={currentPhase === steps.accountNumber}
        dispatch={dispatch}
        bankAccountValid={bankAccountValid}
        bankAccountNumber={bankAccountNumber}
        ticketCode={ticketCode}
        userIBAN={userIBAN}
      />

      <AcceptTerms
        thisPhase={() => dispatch(changeStep(steps.acceptTerms))}
        visible={currentPhase === steps.acceptTerms}
        dispatch={dispatch}
        termsAccepted={termsAccepted}
        isFetching={isFetching}
        submitError={submitError}
      />
    </React.Fragment>
  )
}

export default SellFormLatter
