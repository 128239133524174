import React from 'react'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import MenuDropdown from './mainpage-menu-dropdown'
import { Link } from 'react-router-dom'
import { addSearchDates } from '../actions/search'
import moment from 'moment'

const MenuContainer = ({ dispatch, categories }) => {
  const active = link => {
    return link === window.location.pathname
  }

  return (
    <div className="mainpage-menu-container">
      <MenuDropdown
        dispatch={dispatch}
        categories={categories}
        className={`menu-button menu-button-link ${
          active('/') ? 'menu-button-active' : ''
        }`}
      />
      <div
        className={`menu-button ${
          active('/events') ? 'menu-button-active' : ''
        }`}
      >
        <Link
          to="/events"
          onClick={() => dispatch(addSearchDates(moment(), moment()))}
          className={` ${
            active('/events') ? 'menu-button-link-active' : 'menu-button-link'
          }`}
        >
          <Translate
            value="events.today"
            style={{ textTransform: 'uppercase' }}
          />
        </Link>
      </div>
      <div
        className={`menu-button ${
          active('/bargains') ? 'menu-button-active' : ''
        }`}
      >
        <Link
          to="/bargains"
          className={` ${
            active('/bargains') ? 'menu-button-link-active' : 'menu-button-link'
          }`}
        >
          <Translate
            value="events.bargains"
            style={{ textTransform: 'uppercase' }}
          />
        </Link>
      </div>

      <div className="menu-button main-sell-tickets">
        <Link to="/tickets/sell" className="menu-button-link">
          <Translate
            value="events.sell_ticket"
            style={{ textTransform: 'uppercase' }}
          />
        </Link>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  categories: state.events.categoriesList
})

export default connect(mapStateToProps)(MenuContainer)
