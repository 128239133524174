import React, { useReducer, useEffect } from 'react'
import moment from 'moment'
import Raports from './raports'
import * as API from '../../utility/api'
import RaportsTable from './raports-table'

const initialState = {
  selectedMonth: moment().month(),
  focusedInput: null,
  keyList: [...moment.monthsShort(), 'Year'],
  startDate: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .endOf('month')
    .format('YYYY-MM-DD'),
  baskets: [],
  fetching: false,
  raportData: null
}

function reducer(state, action) {
  switch (action.type) {
    case 'startFetching':
      return {
        ...state,
        fetching: true
      }
    case 'basketsFetch':
      return {
        ...state,
        fetching: false,
        baskets: action.data
      }
    case 'changeSelected':
      const { selectedMonth, endDate, startDate } = action

      return {
        ...state,
        selectedMonth,
        endDate,
        startDate
      }
    case 'changeSelectedDate':
      const { newDate, target } = action

      return {
        ...state,
        [target]: newDate
      }
    case 'setRaportData':
      return {
        ...state,
        raportData: action.data
      }
    default:
      return state
  }
}

const Exports = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { startDate, endDate, raportData } = state

  useEffect(() => {
    fetchBaskets(startDate, endDate)
  }, [startDate, endDate])

  const fetchBaskets = (start, end) => {
    dispatch({ type: 'startFetching' })

    API.GET(`admin/baskets/${start}/${end}`)
      .then(({ data }) => {
        dispatch({
          type: 'basketsFetch',
          data
        })
      })
      .catch(e => console.log({ e }))
  }

  const setRaportData = dispatch => {
    return data => {
      dispatch({
        type: 'setRaportData',
        data
      })
    }
  }

  const changeSelectedDate = (newDate, target) => {
    dispatch({
      type: 'changeSelectedDate',
      newDate,
      target
    })
  }

  const changeSelected = selectedMonth => {
    // "year" is selected
    // show from the start of year to this date
    if (selectedMonth === 12) {
      const startDate = moment()
        .startOf('year')
        .format('YYYY-MM-DD')
      const endDate = moment().format('YYYY-MM-DD')

      dispatch({
        type: 'changeSelected',
        selectedMonth,
        startDate,
        endDate
      })

      return
    }

    const startDate = moment(selectedMonth + 1, 'M MM')
      .startOf('month')
      .format('YYYY-MM-DD')
    const endDate = moment(selectedMonth + 1, 'M MM')
      .endOf('month')
      .format('YYYY-MM-DD')

    dispatch({
      type: 'changeSelected',
      selectedMonth,
      startDate,
      endDate
    })
  }

  const monthBoxes = () => {
    const months = state.keyList.map((m, i) => (
      <MonthBox
        month={m}
        index={i}
        key={i}
        onClick={changeSelected}
        selected={state.selectedMonth}
      />
    ))

    return (
      <div className="col-sm-10">
        <div className="admin-console--month-box--container">{months}</div>
      </div>
    )
  }

  return (
    <div className="row">
      <div className="break-lg" />

      {monthBoxes()}

      <div className="break-lg" />

      <div className="row">
        <div className="col-sm-6">
          <h5>Aikavälin kaikki tilaukset</h5>
          <label>
            Alkaen
            <input
              type="date"
              value={startDate}
              onChange={e => changeSelectedDate(e.target.value, 'startDate')}
              style={{ marginLeft: '5px' }}
            />
          </label>

          <label>
            Päättyy
            <input
              type="date"
              value={endDate}
              style={{ marginLeft: '5px' }}
              onChange={e => changeSelectedDate(e.target.value, 'endDate')}
            />
          </label>
        </div>
      </div>

      <div className="break-lg" />

      {/* <ExportsSepaStats
        baskets={baskets}
        fetching={fetching}
        dates={[startDate, endDate]}
      />
 */}
      <div className="break-sm" />

      <Raports
        startDate={startDate}
        endDate={endDate}
        setRaportData={setRaportData(dispatch)}
      />

      <div className="break-lg" />

      {raportData && <RaportsTable raportData={raportData} />}
    </div>
  )
}

export default Exports

const MonthBox = ({ month, index, onClick, selected }) => {
  return (
    <button
      onClick={() => onClick(index)}
      className={`admin-console--month-box ${
        selected === index ? 'selected-month-button' : ''
      }`}
    >
      {month}
    </button>
  )
}
