import React from 'react'
import placeholder from '../../images/placeholder.png'
import { push } from 'connected-react-router'
import moment from 'moment'
import { Translate } from 'react-redux-i18n'
import RepresentMoney from '../../utility/represent-money'

const EventListItem = (props) => {
  const handleClick = () => {
    props.dispatch(push('/events/' + props.id))
  }

  const cheapestTicket =
    isNaN(props.cheapest_ticket) ||
    props.cheapest_ticket === null ||
    props.cheapest_ticket === '0' ||
    props.cheapest_ticket === 0 ? (
      <div />
    ) : (
      <div className="event-list-item-header__price">
        <Translate
          value="tickets.starting_from"
          className="event-list-item-header__starting"
        />
        <span className="event-list-item-header__pricetext">
          <RepresentMoney sum={props.cheapest_ticket} eurSuffix />
        </span>
      </div>
    )

  const formatEventTime = () => {
    const startDate = props.start_date
    const startDateExact = props.start_date_exact
    const endDate = props.end_date

    const difference = moment(startDate).diff(moment(endDate), 'days', true)

    if (difference === 0) {
      // one day event
      return moment(startDateExact).format('DD.MM.YYYY HH:mm')
    } else {
      return `${moment(startDate).format('DD.MM.YYYY')} – ${moment(
        endDate
      ).format('DD.MM.YYYY')}`
    }
  }

  return (
    <div className="col-sm-4 event-list-item">
      <div className="event-list-item-holder">
        <div
          className="event-list-item-content cursor-pointer"
          onClick={() => handleClick()}
        >
          <div className="event-list-item-header">
            <img
              src={props.image || placeholder}
              alt="Tapahtuman mainoskuva"
              className="event-list-item-image"
            />
            {cheapestTicket}
          </div>

          <div className="event-list-item-bottom">
            <div className="event-list-item__name">{props.name}</div>
            <div className="event-list-item__info-container">
              <div className="event-list-item__info-text">
                {formatEventTime()}
              </div>
              <div className="event-list-item__info-text">
                {props.location && props.location.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventListItem
