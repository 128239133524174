import React from 'react'
import RepresentMoney from '../../utility/represent-money'
import { Translate, I18n } from 'react-redux-i18n'

const BargainTicketDetails = ({ tickets, singleSell, seats, addToBasket }) => {
  return (
    <React.Fragment>
      {tickets.map((ticket) => {
        const org_price = parseInt(ticket.original_price, 10)
        const percent =
          '' +
          (((org_price - ticket.price) / org_price) * 100).toFixed(0) +
          ' %'

        return (
          <div className="row event-ticket-detail" key={`details${ticket.id}`}>
            <div className="col-sm-3 text-left">
              {ticket.section && ticket.section.name}
              {seats && (
                <React.Fragment>
                  {`${I18n.t('bargains.row')} ${ticket.row_name}`}
                  {`${I18n.t('bargains.seat')} ${ticket.seat_name}`}
                </React.Fragment>
              )}
            </div>

            <div className="col-sm-3 text-left">-{percent}</div>

            <div className="col-sm-3 text-left">
              <RepresentMoney sum={ticket.price} eurSuffix />
              <br />
              <small>
                {`${I18n.t('tickets.orig_price')} `}
                <RepresentMoney sum={ticket.original_price} eurSuffix />
              </small>
            </div>

            {singleSell && (
              <div className="col-sm-3 pull-right">
                <div
                  className="event-ticket-buy cursor-pointer"
                  onClick={() => addToBasket(ticket)}
                >
                  <Translate value="tickets.buy" />
                </div>
              </div>
            )}
          </div>
        )
      })}
    </React.Fragment>
  )
}

export default BargainTicketDetails
