import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import HistoryTicket from '../history-ticket'
import moment from 'moment'
import HistoryTicketItem from './userpage-history-ticket-item'
import GlobalLoadingIndicator from '../../components/global-loading-indicator'
import RepresentMoney from '../../utility/represent-money'

const UserPageHistory = ({ bought, submitted, userHistoryFetch }) => {
  const submittedTickets = tickets => {
    return tickets.map(ticket => {
      return <HistoryTicketItem {...ticket} key={`submitted-${ticket.id}`} />
    })
  }

  const boughtTickets = tickets =>
    tickets
      .sort(
        (a, b) =>
          moment(b.sell_date) - moment(a.sell_date)
      )
      .map(ticket => <HistoryTicket key={ticket.id} ticket={ticket} />)

  const soldTickets =
    (submitted &&
      submitted.filter(ticket => ticket.status === 'SOLD')) ||
    []

  const boughtCount = (bought && bought.length) || 0
  const soldCount = soldTickets.length

  const totalMoneyFromSales = soldTickets.reduce(
    (acc, cur) => acc + parseInt(cur.price, 10),
    0
  )

  const totalMoneySpent =
    (bought && bought.reduce((acc, cur) => acc + parseInt(cur.price, 10), 0)) ||
    0

  if (userHistoryFetch) {
    return <GlobalLoadingIndicator center />
  }

  return (
    <>
      <div className="col-sm-6">
        <h2>
          <Translate value="user_page.history.sold.title" />
        </h2>

        <Translate
          value={I18n.t('user_page.history.sold.count.title')}
          className="history-info-title"
        />
        {`${soldCount} `}
        <Translate value={I18n.t('user_page.history.sold.count.pcs')} />

        <div className="break-sm" />
        <Translate
          value={I18n.t('user_page.history.sold.eur_title')}
          className="history-info-title"
        />
        <RepresentMoney sum={totalMoneyFromSales.toString()} eurSuffix />

        <hr />
        {submittedTickets(soldTickets)}
      </div>

      <div className="col-sm-6">
        <h2>
          <Translate value="user_page.history.bought.title" />
        </h2>

        <Translate
          value={I18n.t('user_page.history.bought.count.title')}
          className="history-info-title"
        />
        {`${boughtCount} `}
        <Translate value={I18n.t('user_page.history.bought.count.pcs')} />

        <div className="break-sm" />
        <Translate
          value={I18n.t('user_page.history.bought.eur_title')}
          className="history-info-title"
        />
        <RepresentMoney sum={totalMoneySpent.toString()} eurSuffix />

        <hr />
        {bought && boughtTickets(bought)}
      </div>
    </>
  )
}

export default UserPageHistory
