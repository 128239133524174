import React, { Fragment } from 'react'
import { Translate } from 'react-redux-i18n'
import moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates'
import { changeSelectedDate } from '../../actions/ticket-sale-steps'

class LastSellDate extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      ticketCode,
      selectedDate,
      tickets,
      sellIndividually
    } = this.props
    sellIndividually
      ? dispatch(changeSelectedDate(moment(selectedDate), ticketCode))
      : tickets.forEach(ticket => {
          dispatch(changeSelectedDate(moment(selectedDate), ticket.code))
        })
  }

  isOutsideRange = date => {
    const { endSaleDate } = this.props
    const today = moment()

    return (
      moment(date).isAfter(moment(endSaleDate)) ||
      moment(date).isBefore(moment(today))
    )
  }

  render() {
    const { dispatch, visible, selectedDate, ticketCode } = this.props

    const visibleContent = (
      <Fragment>
        <h4 className="ticket-sell-date--title">
          <Translate value="tickets.last_sell_date" />
        </h4>

        <div className="break-md" />

        <div className="ticket-sell-centered-calendar">
          <DayPickerSingleDateController
            date={moment(selectedDate)}
            onDateChange={date => {
              dispatch(changeSelectedDate(date, ticketCode))
            }}
            isOutsideRange={this.isOutsideRange}
            hideKeyboardShortcutsPanel
            numberOfMonths={1}
            focused={true}
          />
        </div>

        <div className="break-md" />

        <div className="break-sm" />

        <div className="break-md" />
        <div className="row ticket-sell-step-border" />
      </Fragment>
    )

    return (
      <React.Fragment>
        {visible && visibleContent}

        {/*!visible && hiddenContent*/}
      </React.Fragment>
    )
  }
}

export default LastSellDate
