import {
  ADD_SEARCH_CATEGORY,
  EMPTY_FILTERS,
  ADD_SEARCH_DATES
} from '../actionTypes'

export const addSearchCategory = (filter, empty = false) => {
  return dispatch => {
    if (empty) {
      dispatch(emptyFilters())
    }

    dispatch({
      type: ADD_SEARCH_CATEGORY,
      filter
    })
  }
}

export const addSearchDates = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: ADD_SEARCH_DATES,
      startDate,
      endDate
    })
  }
}

export const emptyFilters = () => {
  return dispatch =>
    dispatch({
      type: EMPTY_FILTERS
    })
}
