import * as API from '../utility/api'
import { push } from 'connected-react-router'
import { I18n } from 'react-redux-i18n'
import {
  TICKET_SELL_FAILURE,
  TICKET_SELL_SUCCESS,
  START_TICKET_SELL_REQUEST,
  REMOVE_TICKET_TO_SELL,
  CHANGE_ACCEPT_TERMS,
  CHANGE_BANK_ACCOUNT_NUMBER,
  CHANGE_PRICING_POLICY,
  CHANGE_SELL_INDIVIDUALLY_OPTION,
  CHANGE_BOUNDARY_VALUE,
  CHANGE_SLIDER_VALUE,
  CHANGE_CALENDAR_FOCUS,
  CHANGE_SELECTED_DATE,
  CHANGE_STEP,
  KILL_SALES,
  EMPTY_TICKETS_IN_SELL
} from '../actionTypes'

export const steps = {
  codeInput: 0,
  basePrice: 1,
  minMaxPrice: 2,
  lastSellDate: 3,
  accountNumber: 4,
  acceptTerms: 5
}

export const pricingPolicies = {
  flat: 0,
  rising: 1,
  declining: 2
}

export const emptyTicketsInSell = () => {
  return {
    type: EMPTY_TICKETS_IN_SELL
  }
}

export const killSales = () => {
  return {
    type: KILL_SALES
  }
}

export const changeStep = step => {
  return {
    type: CHANGE_STEP,
    step
  }
}

export const changeSelectedDate = (date, ticketCode) => {
  return {
    type: CHANGE_SELECTED_DATE,
    date,
    ticketCode
  }
}

export const changeCalendarFocus = (focused, ticketCode) => {
  return {
    type: CHANGE_CALENDAR_FOCUS,
    focused,
    ticketCode
  }
}

export const changeSliderValue = (value, ticketCode) => {
  return {
    type: CHANGE_SLIDER_VALUE,
    value,
    ticketCode
  }
}

export const changeBoundaryValue = (boundary, ticketCode) => {
  return {
    type: CHANGE_BOUNDARY_VALUE,
    boundary,
    ticketCode
  }
}

export const changeSellIndividually = value => {
  return {
    type: CHANGE_SELL_INDIVIDUALLY_OPTION,
    value
  }
}

export const changePricingPolicy = (policy, ticketCode) => {
  return {
    type: CHANGE_PRICING_POLICY,
    policy,
    ticketCode
  }
}

export const changeBankAccountNumber = iban => {
  return {
    type: CHANGE_BANK_ACCOUNT_NUMBER,
    iban
  }
}

export const changeAcceptTerms = value => {
  return {
    type: CHANGE_ACCEPT_TERMS,
    value
  }
}

export const removeTicketToSell = ticketCode => {
  return {
    type: REMOVE_TICKET_TO_SELL,
    ticketCode
  }
}

export const setTicketsToSale = (
  tickets,
  iban,
  ticketsToSell,
  price,
  selectedDate,
  sellIndividually,
  boundary,
  pricingPolicy
) => {
  return dispatch => {
    tickets = tickets.map(ticket => ({
      code: ticket.code,
      pricingPolicy: sellIndividually
        ? ticketsToSell[ticket.code].pricingPolicy
        : pricingPolicy,
      sellPrice: sellIndividually
        ? ticketsToSell[ticket.code].sellPrice
        : price,
      boundary: sellIndividually
        ? ticketsToSell[ticket.code].boundary
        : boundary,
      selectedDate: sellIndividually
        ? ticketsToSell[ticket.code].selectedDate
        : selectedDate
    }))

    dispatch({
      type: START_TICKET_SELL_REQUEST
    })

    API.POST('tickets', {
      tickets,
      sellIndividually,
      iban
    })
      .then(({ data }) => {
        dispatch({
          type: TICKET_SELL_SUCCESS
        })
        dispatch(emptyTicketsInSell())
        dispatch(killSales())

        dispatch(
          push(`/tickets/${data.batchId}`, {
            sellerSuccess: true,
            message: 'tickets.submit_success'
          })
        )
      })
      .catch(e => {
        if (e && e.response) {
          const response = e.response
          const errorMessage =
            response.data && I18n.t(`notifications.${response.data.message}`)
          const errorReason =
            (response.data &&
              I18n.t(
                `notifications.ticket_sell_failure_reasons.${response.data.reason}`
              )) ||
            ''
          const error = errorMessage + ': ' + errorReason

          dispatch({
            type: TICKET_SELL_FAILURE,
            error
          })
        }
      })
  }
}
