import React from 'react'

class ValidatedInput extends React.Component {
  componentWillMount() {
    this.handleValid()
  }

  componentDidUpdate() {
    this.handleValid()
  }

  handleValid() {
    const { value, onValid, inValid, valid, validator } = this.props

    if (value && valid && !validator && inValid) inValid()
    if (validator && !valid && onValid) onValid()
  }

  render() {
    const { value, describe, valid, children } = this.props

    return (
      <React.Fragment>
        {children}
        {!valid && value && <div style={{ color: 'red' }}>{describe}</div>}
      </React.Fragment>
    )
  }
}

export default ValidatedInput
