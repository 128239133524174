import React from 'react'
import { connect } from 'react-redux'
import FooterLogo from '../components/footerlogo'
import { push } from 'connected-react-router'
import { toggleModal } from '../actions/modal'
import { Translate } from 'react-redux-i18n'

const Footer = ({ dispatch, user, locale }) => {
  const signedIn = user && user.signedIn
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="col-sm-12 footer-bar text-left">
          <FooterLogo />

          <div className="row">
            <div className="col-sm-3 text-center">
              <div className="inline-block text-left">
                <h3>
                  <Translate value="footer.customer_service.title" />
                </h3>
                <p className="footer-information">
                  <Translate value="footer.customer_service.email" />
                </p>
              </div>
            </div>
            <div className="col-sm-3 text-center">
              <div className="inline-block text-left">
                <h3>
                  <Translate value="footer.terms.title" />
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/termsofuse"
                  className="footer-link"
                >
                  <Translate value="footer.terms.terms_of_use" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/termsofpurchaseandsale"
                  className="footer-link"
                >
                  <Translate value="footer.terms.terms_of_purchase_and_sale" />
                </a>
                <a
                  href={
                    locale === 'fi'
                      ? '/static/tietosuojaseloste.pdf'
                      : '/static/privacypolicy.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  <Translate value="footer.terms.privacy_policy" />
                </a>
              </div>
            </div>
            <div className="col-sm-3 text-center">
              <div className="inline-block text-left">
                <h3>
                  <Translate value="footer.sitemap.title" />
                </h3>
                <div
                  className="footer-link"
                  onClick={() => dispatch(push('/'))}
                >
                  <Translate value="footer.sitemap.search" />
                </div>

                <div
                  className="footer-link"
                  onClick={() => dispatch(push('/tickets/sell'))}
                >
                  <Translate value="footer.sitemap.sell_tickets" />
                </div>
                {!signedIn && (
                  <React.Fragment>
                    <div
                      className="footer-link"
                      onClick={() => dispatch(push('/register'))}
                    >
                      <Translate
                        value="footer.sitemap.register"
                        className="footer-link"
                      />
                    </div>

                    <div
                      className="footer-link"
                      onClick={() => {
                        dispatch(toggleModal(true))
                      }}
                    >
                      <Translate
                        value="footer.sitemap.sign_in"
                        className="footer-link"
                      />
                    </div>
                  </React.Fragment>
                )}
                <div
                  className="cursor-pointer"
                  onClick={() => dispatch(push('/faq'))}
                >
                  <Translate
                    value="footer.sitemap.help"
                    className="footer-link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.users,
    locale: state.i18n.locale
  }
}

export default connect(mapStateToProps)(Footer)
