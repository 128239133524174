import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  ticketCodeChanged,
  addTicketToSell,
  fetchEventInfo
} from '../actions/tickets'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { Translate } from 'react-redux-i18n'
import * as API from '../utility/api'

class TicketCodeSellInput extends Component {
  handleSubmit = e => {
    e.preventDefault()

    this.fetchEventInfoIfNeeded()

    if (this.props.inSell.length === 4) {
      // TODO: Change to global notifications or similar?
      API.apiNotification({ value: 'tickets.max_tickets', status: 'error' })
      return
    }

    //fails if this ticketID is already added to be sold
    if (this.checkTicketsInSellTicketCodes(this.props.ticketCode)) {
      API.apiNotification({
        value: 'tickets.ticket_already_for_sale',
        status: 'error'
      })
      return
    }

    //fails if trying to add different event tickets
    if (!this.checkEventIDisSame()) {
      API.apiNotification({ value: 'tickets.wrong_events', status: 'error' })
      return
    }

    //prevents submit spam
    if (!this.props.isFetching) {
      this.props.dispatch(addTicketToSell())
    }
  }

  fetchEventInfoIfNeeded = () => {
    if (this.props.inSell.length === 0) {
      this.props.dispatch(fetchEventInfo(this.props.ticketInfo.event.id))
    }
  }

  //checks that all tickets belong to same event
  checkEventIDisSame = () => {
    //if there is no tickets being sold accept all
    if (this.props.inSell.length === 0) return true

    //check if eventID matches earlier added tickets
    return this.props.inSell[0].event.id === this.props.ticketInfo.event.id
  }

  //checks that there is only unique ticket codes
  checkTicketsInSellTicketCodes = ticketCode => {
    return !!this.props.inSell.filter(t => t.code === ticketCode).length
  }

  handleChange = e => {
    const { dispatch } = this.props
    const value = e.target.value

    if (value.length <= 13) {
      // if ticket is valid the check fetch is done in here
      dispatch(ticketCodeChanged(value))
    }
  }

  render() {
    const {
      isFetching,
      validationError,
      validationMessage,
      valid,
      text,
      ticketCode
    } = this.props

    return (
      <Fragment>
        <div className="ticket-code-container">
          <input
            type="number"
            value={ticketCode}
            onChange={this.handleChange}
            className="ticket-code-input"
            placeholder={text}
          />
          <button
            className="ticket-add-button"
            disabled={!valid || isFetching}
            onClick={this.handleSubmit}
          >
            <div className="ticket-add-button-icon-container">
              {isFetching && <GlobalLoadingIndicator center />}
              {validationError && !isFetching && (
                <i className="fa-solid fa-triangle-exclamation fa-2x" />
              )}
              {!isFetching && !validationError && (
                <i className="fa-solid fa-plus fa-2x" />
              )}
            </div>
          </button>
        </div>
        <div className="ticket-error-container">
          {validationError && (
            <div className="text-center">
              <div className="ticket-sell-error-title">
                <Translate value="sell_page.not_valid" />
              </div>
              <div className="ticket-sell-error-message">
                {validationMessage}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    ticketCode: state.tickets.ticketCode,
    valid: state.tickets.valid,
    isFetching: state.tickets.isFetching,
    validationError: state.tickets.validationError,
    validationMessage: state.tickets.validationMessage,
    inSell: state.tickets.inSell,
    ticketInfo: state.tickets.ticketInfo,
    eventInfo: state.tickets.eventInfo
  }
}

export default connect(mapStateToProps)(TicketCodeSellInput)
