import { TOGGLE_MODAL, SET_DESIRED_PATH } from '../actionTypes'

export const toggleModal = (modalOpen, path = false) => {
  return dispatch => {
    dispatch({
      type: TOGGLE_MODAL,
      modalOpen
    })

    if (path) {
      dispatch({
        type: SET_DESIRED_PATH,
        path
      })
    }
  }
}
