import React, { Fragment } from 'react'
import CategoryCheckbox from '../components/search/search-category-checkbox'
import SearchInput from '../containers/searchpage-search-input'
import MenuContainer from '../components/mainpage-menu-container'
import EventListItem from '../components/event/event-list-item'
import { connect } from 'react-redux'
import { filteredAndSortedEvents, mostViewedEvents } from '../reducers'
import { addSearchCategory, emptyFilters } from '../actions/search'
import SearchpageDatePicker from '../components/search/searchpage-date-picker'
import { Translate } from 'react-redux-i18n'

const SearchPage = props => {
  const { dispatch, categories, selectedCategories, filteredEvents } = props

  const categoryOnClick = dispatch => {
    return filter => dispatch(addSearchCategory(filter, false))
  }

  const categoriesList = categories.map(category => (
    <CategoryCheckbox
      checked={selectedCategories.includes(category.id)}
      filter={category}
      key={`category-checkbox-${category.id}`}
      onClick={categoryOnClick(dispatch)}
    />
  ))

  const mapEvents = () =>
    filteredEvents.map(event => (
      <EventListItem
        {...event}
        key={`search-page-event-${event.id}`}
        dispatch={props.dispatch}
      />
    ))

  return (
    <div className="section">
      <MenuContainer />
      <div className="break-lg" />
      <div className="row">
        <div className="col-sm-6">
          <SearchInput />
        </div>
      </div>
      <hr />
      <h3>
        <Translate value="events.filter" />
      </h3>
      <div className="row">
        <div className="col-sm-6">
          <SearchpageDatePicker />
        </div>
      </div>
      <div className="break-md" />
      <div className="">{categoriesList}</div>
      <div className="cursor-pointer">
        <div
          className="recommendedEvent recommendedEvent-empty"
          onClick={() => dispatch(emptyFilters())}
        >
          <Translate value="search.empty" /> <i className="fa-solid fa-trash-can"></i>
        </div>
      </div>
      <hr />
      <div className="row">
        {filteredEvents && filteredEvents.length > 0 ? (
          <Fragment>
            <div className="col-sm-12">
              <p className="search-result-text">
                <Translate
                  value="events.n_events_found"
                  amount={filteredEvents.length}
                />
              </p>
            </div>
            {mapEvents()}
          </Fragment>
        ) : null}
        {filteredEvents.length === 0 && (
          <div className="col-sm-12">
            <div className="break-lg" />
            <p className="search-result-text">
              <Translate value="events.no_events_found" />
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    categories: state.events.categoriesList,
    selectedCategories: state.search.categories.map(c => c.id),
    filteredEvents: filteredAndSortedEvents(state),
    mostViewedEvents: mostViewedEvents(state)
  }
}

export default connect(mapStateToProps)(SearchPage)
