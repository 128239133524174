import React from 'react'
import EventListItem from '../components/event/event-list-item'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import EventsCarousel from '../components/event/events-carousel'
import chunk from 'lodash/chunk'
import GlobalLoadingIndicator from '../components/global-loading-indicator'

class EventsList extends React.Component {
  render() {
    const { events, mostViewed } = this.props
    const { dispatch } = this.props

    // idea here is to fill carousels vertically instead of horizontally
    // e.g. first 3 items go to carousel 1, second 3 to carousel 2, third 3 to carousel 4
    // fourth 3 to carousel 1 etc
    const divideEventsToCarousels = () => {
      const upcomingEvents = events.map(event => (
        <EventListItem key={event.id} {...event} dispatch={dispatch} />
      ))

      const chunkSize =
        Math.ceil(upcomingEvents.length / 3) >= 3
          ? Math.ceil(upcomingEvents.length / 3)
          : 3

      const rowEvents = chunk(upcomingEvents, chunkSize)

      return (
        <React.Fragment>
          {events.length > 0 && (
            <React.Fragment>
              <EventsCarousel events={rowEvents[0]} />
              <div className="break-lg" />
              {rowEvents[1] && <EventsCarousel events={rowEvents[1]} />}
              <div className="break-lg" />
              {rowEvents[2] && <EventsCarousel events={rowEvents[2]} />}
              <div className="break-lg" />
            </React.Fragment>
          )}

          {events.length === 0 && <div>Ei uusimpia tapahtumia</div>}
        </React.Fragment>
      )
    }

    const mostViewedCarousel = () => {
      const mostViewedComponents = mostViewed.map(event => (
        <EventListItem
          key={`${event.id}-mostViewed`}
          {...event}
          dispatch={dispatch}
        />
      ))

      return <EventsCarousel events={mostViewedComponents} />
    }

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="event-carousel-container">
            <h2 className="col-margin-left">
              <Translate
                value="events.most_viewed"
                style={{ textTransform: 'uppercase' }}
              />
            </h2>

            {mostViewed && mostViewedCarousel()}
            {!mostViewed && <GlobalLoadingIndicator center />}

            <h2 className="col-margin-left">
              <Translate
                value="events.upcoming"
                style={{ textTransform: 'uppercase' }}
              />
            </h2>

            {events && divideEventsToCarousels()}
            {!events && <GlobalLoadingIndicator center />}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    events: state.events.upcomingEvents,
    mostViewed: state.events.mostViewed
  }
}

export default connect(mapStateToProps)(EventsList)
