import React, { useState } from 'react'
import TranslationRowListItem from './translation-row-list-item'
const TranslationRowList = ({
  translations = {},
  updateTranslation,
  dispatchAddFaqRow
}) => {
  const [newTranslation, setNewTranslation] = useState({})
  const [saveDisabled, setSaveDisabled] = useState(true)

  const handleTranslationChange = translation => {
    const { value, key = '', lang } = translation
    const translations = newTranslation
    if (!translations[lang]) throw new Error('Language not found')


    translations[lang]['translation']['value'] = value
    translations[lang]['translation']['key'] = key
    if (!value) return setSaveDisabled(true)
    if (key.split('.').length < 2) return setSaveDisabled(true)
    setSaveDisabled(false)
    setNewTranslation(translations)
  }

  const toggleNewTranslations = (lang) => {
    if (!lang) return
    const langEntry = {
      [lang]: {
        translation: {
          key: '',
          value: ''
        },
        open: !newTranslation[lang]?.open
      }
    }
    setNewTranslation({ ...newTranslation, ...langEntry })
  }

  const handleTranslationSave = (translation) => {
    const { key, value, lang } = translation
    const [translationKey, translationTopic, ...translationPath] = key.split('.').reverse()
    updateTranslation(lang, translationTopic, translationKey, value, translationPath)
    toggleNewTranslations(lang)
  }
  const translationLanguages = Object.keys(translations).map(lang => {
    const translationsLang = translations[lang]

    const translationRows = Object.entries(translationsLang).map(
      translation => {
        return (
          <React.Fragment key={translation[0]}>
            <TranslationRowListItem
              lang={lang}
              info={translation}
              updateTranslation={updateTranslation}
              dispatchAddFaqRow={dispatchAddFaqRow}
              indent={0}
              path={[]}
            />
            <div className='break-sm' />
          </React.Fragment>
        )
      }
    )

    const newTranslationRowInput = ({ onClose, onSave, onChange, translation, lang, disabled }) => {
      const valuePlaceholderText = 'Käännös'
      const keyPlaceholderText = "Avain muodossa 'otsikko.aliotsikko.avain' (esim: user.subheading.name, jolloin käännös avaimelle name löytyy otsikosta 'user' ja aliotsikosta 'subheading')"
      const handleValueChange = e => {
        onChange({
          value: e.target.value,
          key: translation.key,
          lang
        })
      }
      const handleKeyChange = e => {
        onChange({
          value: translation.value,
          key: e.target.value,
          lang
        })
      }
      const closePrompt = (e) => {
        e.preventDefault()
        onClose(lang)
      }
      const saveTranslation = () => {
        const { key, value } = newTranslation[lang].translation
        onSave({ key, value, lang })
      }
      return (
        <React.Fragment>
          <div className="admin-language-new-input">
            <textarea onChange={handleKeyChange} name={lang} placeholder={keyPlaceholderText}>{translation.key}</textarea>
            <textarea onChange={handleValueChange} name={lang} placeholder={valuePlaceholderText}>{translation.value}</textarea>
          </div>
          <div className="admin-language-info">
            <i className="fa-regular fa-circle-info" />
            <span>Uudessä käännöksessä tulee olla vähintään otsikko ja avain pisteellä erotettuina.</span>
          </div>
          <div className='admin-language-new-actions'>

            <button onClick={saveTranslation} disabled={disabled}>Lisää</button>
            <button onClick={closePrompt}>Sulje</button>
          </div>
        </React.Fragment >
      )
    }

    return (
      <div className='row' key={lang}>
        <div className='col-sm-2 bigbold'>{lang}</div>
        <div className='break-lg' />
        <div className='col-sm-12'>{translationRows}</div>
        <div className='col-sm-12'>
          <div className="admin-language-new-toggle" onClick={() => toggleNewTranslations(lang)}>
            { newTranslation[lang]?.open ?
              <i className="fa-solid fa-chevron-up" />
              :
              <i className="fa-solid fa-chevron-down" />
            }
            <span>Luo uusi käännös</span>
          </div>

          {newTranslation?.[lang]?.open && newTranslationRowInput({
            lang,
            onSave: handleTranslationSave,
            onClose: toggleNewTranslations,
            onChange: handleTranslationChange,
            translation: newTranslation[lang]?.translation || { key: '', value: '' },
            disabled: saveDisabled
          }
          )}

        </div>
        <div className='break-md' />
      </div>
    )
  })

  return <div className=''>{translationLanguages}</div>
}

export default TranslationRowList
