import React from 'react'
import { connect } from 'react-redux'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { push } from 'connected-react-router'
import ConfirmationForm from '../components/login-register/confirmation-form'

class Verify extends React.Component {
  componentWillMount = () => {
    const { dispatch } = this.props

    if (this.props.signedIn) {
      dispatch(push('/'))
    }
  }

  render() {
    const { dispatch, verificationError} = this.props

    const renderViewContent = () => {
      const { isFetching, verificationEmail, verificationFetching } = this.props

      if (isFetching) {
        return (
          <div>
            <GlobalLoadingIndicator center />
          </div>
        )
      }

        return (
        <ConfirmationForm
            dispatch={dispatch}
            email={verificationEmail}
            verificationFetching={verificationFetching}
            error={verificationError}
        />
        )
    }

    return (
      <div className="row text-center section">
        <div className="col-sm-6 col-sm-offset-3">
          <div className="login-form-container">
            <div className="login-form-window">{renderViewContent()}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.users.isFetching,
    signedIn: state.users.signedIn,
    verificationEmail: state.users.verificationEmail,
    verificationFetching: state.users.verificationFetching,
    verificationError: state.users.verificationError,
  }
}

export default connect(mapStateToProps)(Verify)
