import React from 'react'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'

const Faq = ({ translations, locale }) => {
  if (!translations) return <div />

  const faqs = translations[locale].faq

  const questions = Object.keys(faqs).filter(key => key.slice(0, 1) === 'q')

  const mapQuestions = questions.map(questionKey => {
    const answerKey = questionKey.replace('q', 'a')

    const answer = faqs[answerKey]
    const question = faqs[questionKey]

    return (
      <React.Fragment key={questionKey}>
        <div className="bold">{question}</div>

        <div className="break-md" />

        <div>{answer}</div>

        <div className="break-lg" />
      </React.Fragment>
    )
  })

  return (
    <div className="row">
      <div className="break-lg" />

      <div className="col-sm-8 col-sm-offset-2">
        <div className="bigbold">
          <Translate value="faq.faq" />
        </div>

        <div className="break-lg" />

        {mapQuestions}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  translations: state.i18n.translations,
  locale: state.i18n.locale
})

export default connect(mapStateToProps)(Faq)
