import * as API from '../utility/api'
import { changeStep, steps } from './ticket-sale-steps'
import {
  START_VALIDATION_FETCH,
  ADD_TICKET_TO_SELL,
  SET_EVENT_INFO,
  DELETE_TICKET,
  TICKET_CODE_FOUND_SUCCESS,
  TICKET_CODE_NOT_FOUND_FAILURE,
  TICKET_CODE_CHANGED,
  TICKET_CODE_IS_INVALID
} from '../actionTypes'

export const checkTicket = ticketCode => {
  return dispatch => {
    dispatch({
      type: START_VALIDATION_FETCH
    })

    API.GET('tickets/validate/' + ticketCode)
      .then(resp => {
        dispatch(ticketCodeFoundSuccess(resp.data))
      })
      .catch(e => {
        const error =
          e && e.response && e.response.data && e.response.data.message
        dispatch(ticketCodeNotFoundFailure(error))
      })
  }
}

export const addTicketToSell = () => {
  return dispatch => {
    dispatch({
      type: ADD_TICKET_TO_SELL
    })
  }
}

export const fetchEventInfo = eventID => {
  return dispatch => {
    API.GET('events/' + eventID).then(resp => {
      dispatch({
        type: SET_EVENT_INFO,
        eventInfo: resp.data.event
      })
    })
  }
}

export const setEventInfo = eventInfo => {
  return dispatch => {
    dispatch({
      type: SET_EVENT_INFO,
      eventInfo: eventInfo
    })
  }
}

export const deleteTicket = ticketCode => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_TICKET,
      ticketCode
    })
    if (getState().tickets.inSell.length < 1) {
      dispatch(changeStep(steps.codeInput))
    }
  }
}

const ticketCodeFoundSuccess = body => {
  return {
    type: TICKET_CODE_FOUND_SUCCESS,
    body
  }
}

const ticketCodeNotFoundFailure = message => {
  return {
    type: TICKET_CODE_NOT_FOUND_FAILURE,
    message
  }
}

export const ticketCodeChanged = ticketCode => {
  return dispatch => {
    dispatch({
      type: TICKET_CODE_CHANGED,
      ticketCode
    })

    if (checkTicketCodeValidity(ticketCode)) {
      dispatch(checkTicket(ticketCode))
    } else {
      dispatch({
        type: TICKET_CODE_IS_INVALID
      })
    }
  }
}

const checkTicketCodeValidity = ticketCode => {
  //test for 13 numbers
  return /^[0-9]{13}$/.test(ticketCode)
}
