import React from 'react'
import './styles.scss'

const MostViewedButton = props => {
  const { onClick, text } = props

  return (
    <div className="most-viewed-button" onClick={onClick}>
      {text}
    </div>
  )
}

export default MostViewedButton
