import React from 'react'
import * as API from '../utility/api'
import { Translate, I18n } from 'react-redux-i18n'
import moment from 'moment'
import LoadingButton from './button-loading-indicator'
import RepresentMoney from '../utility/represent-money'
import download from '../lib/downloader'

class HistoryTicket extends React.Component {
  state = { loading: false, pdf: null }

  // pdf comes as text and is quite big
  // we dont want to store it to localStorage
  // and we dont need those datas anywhere else,
  // so we'll not use redux here
  loadPDFData = ticketId => {
    const { pdf } = this.state
    if (pdf) {
      const data = `data:application/octet-stream;charset=utf-16be;base64,${
        pdf.pdf_data
      }`
      download(data, pdf.pdf_name)

      return
    }

    // file_name keeps being surrounded by ""
    const url = `tickets/${ticketId}/pdf`.replace(/"/g, '')
    this.setState({
      loading: true,
      error: null
    })

    return API.GET(url)
      .then(({ data }) => {
        const { pdf_info } = data

        this.setState({ loading: false, pdf: pdf_info })

        const getData = `data:application/octet-stream;charset=utf-16be;base64,${
          pdf_info.pdf_data
        }`
        download(getData, pdf_info.pdf_name)
      })
      .catch(() => {
        this.setState({
          error: I18n.t('tickets.download_error'),
          loading: false
        })
      })
  }

  render() {
    const {
      id,
      name,
      price,
      event_name,
      section_name,
      order_status,
      sell_date,
      pdf_name
    } = this.props.ticket

    const { loading, error } = this.state

    // TODO: use this class on titles: className="history-info-title"

    return (
      <React.Fragment>
        <div className="history-ticket-container">
          <div className="bold">{event_name} {name}</div>

          <div className="break-sm" />

          {order_status && (
            <div className="">
              <Translate value="tickets.order_status" className="history-info-title" />
              <br />
              {`${order_status}`}
            </div>
          )}

          {section_name && section_name !== "EMPTY" && (
            <div className="">
              <Translate value="tickets.seat" className="history-info-title" />
              <br />
              {`${section_name}`}
            </div>
          )}

          <Translate value="tickets.bought" className="history-info-title" />
          <br />
          {`${moment(sell_date).format('DD.MM.YYYY HH:MM')}`}

          <br />

          <Translate
            value="tickets.base_price"
            className="history-info-title"
          />
          <br />
          <RepresentMoney sum={price} eurSuffix />

          <div className="break-sm" />

          {pdf_name && pdf_name !== '' && (
            <LoadingButton
              content={I18n.t('tickets.download_ticket')}
              onClick={() => this.loadPDFData(id)}
              loading={loading}
              error={error}
            />
          )}
        </div>
        <hr />
      </React.Fragment>
    )
  }
}

export default HistoryTicket
