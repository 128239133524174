import { loadTranslations, setLocale } from 'react-redux-i18n'
import * as API from './api'
import moment from 'moment'

const loadAndSetTranslations = store => {
  if (
    store.i18n &&
    store.i18n.translations &&
    moment(store.i18n.translations.expires).isBefore(moment())
  ) {
    // translations are semi updated - no need to load new ones
    // activate when in production
    // return
  }

  const fetchTranslations = () => {
    API.GET(process.env.REACT_APP_TRANSLATIONS_URL, {}, false)
      .then(({ data }) => {
        // to change languages elsewhere, you need to dispatch these functions
        // also the 'loadTranslations' -part, so every language can be in own file
        store.dispatch(
          loadTranslations({ ...data, expires: moment().endOf('day') })
        )
        // sets fi to default language
        const currentStore = store.getState()
        if (!(currentStore && currentStore.i18n && currentStore.i18n.locale)) {
          store.dispatch(setLocale('fi'))
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  if (process.env.NODE_ENV === 'production') {
    fetchTranslations()
  } else {
    API.GET(`${process.env.REACT_APP_TRANSLATIONS_URL_DEV}/en`, {}, false)
      .then(({ data: en }) => {
        API.GET(`${process.env.REACT_APP_TRANSLATIONS_URL_DEV}/fi`, {}, false)
          .then(({ data: fi }) => {
            store.dispatch(
              loadTranslations({
                ...{ fi, en },
                expires: moment().endOf('day')
              })
            )
            // sets fi to default language
            const currentStore = store.getState()
            if (
              !(currentStore && currentStore.i18n && currentStore.i18n.locale)
            ) {
              store.dispatch(setLocale('fi'))
            }
          })
          .catch(e => console.log(e))
      })
      .catch(() => {
        fetchTranslations()
      })
  }
}

export default loadAndSetTranslations
