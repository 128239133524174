import React from 'react'
import BargainTicketDetails from './bargain-ticket-details'
import { Translate } from 'react-redux-i18n'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import RepresentMoney from '../../utility/represent-money'
import { I18n } from 'react-redux-i18n'

class BargainTicketEvent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chosenTickets: 0,
      ticketDetails: false,
      seats: false
    }
  }

  render() {
    const {
      tickets,
      addToBasket,
      eventInfo,
      dispatch,
      // bargains,
      image
    } = this.props

    const priceSum = () => {
      let sum = 0
      Object.values(tickets).forEach((ticket) => {
        sum += parseInt(ticket.price, 10)
      })
      return sum
    }

    const singleSell = true

    const addAllToBasket = () => {
      Object.values(tickets).forEach((ticket) => {
        addToBasket(ticket)
      })
    }

    // redirect to parent_id if event is child
    // redirect to event_id if event is normal
    const backToParent = () => {
      const pct = eventInfo && eventInfo.parent_child_type
      const ids = {
        NORMAL: eventInfo && eventInfo.id,
        CHILD: eventInfo && eventInfo.parent_id
      }
      const queryId = ids[pct]

      dispatch(push('/events/' + queryId))
    }

    const eventTicketDate = () => {
      return (
        <div className="event-ticket-date">
          {eventInfo && moment(eventInfo.start_date).format('D.M.YYYY')}
        </div>
      )
    }

    return (
      <div className="event-ticket-container">
        <div className="row event-ticket-content">
          <div className="col-sm-2 col-xs-3">
            {image && (
              <img
                src={image}
                className="event-list-image"
                alt={I18n.t('images.event_image')}
              />
            )}
          </div>

          <div className="col-sm-3 event-ticket-info">
            <div
              className="event-ticket-title cursor-pointer"
              onClick={backToParent}
            >
              {eventInfo && eventInfo.name} <br />
            </div>
            <div className="event-ticket-section">
              {tickets && tickets[0].section && tickets[0].section.name}
            </div>
            {eventTicketDate()}
            {!singleSell && (
              <div className="event-ticket-price">
                <Translate value="tickets.sum" />{' '}
                <RepresentMoney sum={priceSum()} eurSuffix />
              </div>
            )}
          </div>

          <div className="col-sm-7">
            <BargainTicketDetails
              tickets={tickets}
              addToBasket={addToBasket}
              seats={
                eventInfo &&
                eventInfo.section_map_id !== '0' &&
                eventInfo.section_map_id !== null
              }
              singleSell={singleSell}
            />
          </div>

          {!singleSell && (
            <div className="col-sm-3 col-sm-push-9 event-ticket-buy-all-container">
              <div
                className="event-ticket-buy-all pull-right cursor-pointer"
                onClick={() => addAllToBasket()}
              >
                <Translate value="tickets.buy_all" />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect()(BargainTicketEvent)
