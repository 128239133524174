import React from 'react'
import Spinner from 'react-spinkit'

const GlobalLoadingIndicator = ({ center, secondary }) => {
  return (
    <div style={{ display: 'flex', justifyContent: center ? 'center' : '' }}>
      <Spinner
        name="cube-grid"
        color={secondary ? '#fff' : '#8d3fa6'}
        fadeIn="none"
      />
    </div>
  )
}

export default GlobalLoadingIndicator
