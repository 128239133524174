import React from 'react'
import MenuContainer from '../components/mainpage-menu-container'
import EventsList from '../containers/events-list'
import SearchInput from '../containers/searchpage-search-input'
import NewestTickets from '../containers/newest-tickets'
import { Translate } from 'react-redux-i18n'
import MostViewed from '../containers/most-viewed-container'
import { getAllEvents } from '../actions/events'
import { connect } from 'react-redux'
import UnsubscribeByLink from '../components/unsubscribe-by-link'

class MainPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(getAllEvents())
  }
  render() {
    return (
      <React.Fragment>
        <div className="mainpage-upper mainpage-header">
          <div className="break-lg" />
          <div className="row">
            <div className="col-sm-12">
              <div className="mainpage-header-title">
                <Translate value="mainpage.mainpage_header" />
              </div>
            </div>
            <div className="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4 mainpage-search-input">
              <div className="mainpage-header-description">
                <Translate value="mainpage.mainpage_2nd_header" />
              </div>

              <div className="break-lg" />

              <SearchInput />

              <div className="break-md" />

              <MostViewed />

              <div className="break-lg" />
            </div>
          </div>
        </div>

        <div className="section">
          <MenuContainer />
          <EventsList />

          <div className="break-md" />
        </div>
        <NewestTickets />
        <UnsubscribeByLink />
      </React.Fragment>
    )
  }
}

export default connect()(MainPage)
