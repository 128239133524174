import GlobalLoadingIndicator from "../global-loading-indicator";
import React from "react";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { signIn } from "../../actions/users";
import { toggleModal } from "../../actions/modal";

class LoginForm extends React.Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = target => {
    return e => {
      this.setState({
        [target]: e.target.value,
      });
    };
  };

  handleSubmit = e => {
    e.preventDefault();

    const { dispatch } = this.props;
    dispatch(signIn(this.state.email, this.state.password, this.props.path));

    this.setState({
      email: "",
      password: "",
    });
  };

  handleForgotPassword = () => {
    const { dispatch } = this.props;
    dispatch(toggleModal(false));
    dispatch(push("/recovery"));
  };

  handleRegister = () => {
    const { dispatch } = this.props;
    dispatch(toggleModal(false));
    dispatch(push("/register"));
  };

  render() {
    const { isFetching } = this.props;
    const facebookAuthUrl = process.env.REACT_APP_FACEBOOK_AUTH_URL;

    return (
      <div>
        <div className="modal-exit-container text-right">
          <div className="cursor-pointer login-register" onClick={() => this.handleRegister()}>
            <Translate value="header.register" className="form-link" />
          </div>
          <i className="fa-solid fa-xmark cursor-poiter modal-close-icon" onClick={this.props.closeModal} />
        </div>

        <div className="user-info-form text-center">
          <h2>
            <Translate value="registration.signin" />
          </h2>

          {this.props.verificationMessage && (
            <div className="bold">
              <div className="break-md" />
              <Translate value="registration.verification_message" />
              <div className="break-md" />
            </div>
          )}

          <div className="col-sm-12">
            <div onClick={() => this.handleForgotPassword()} className="cursor-pointer">
              <Translate value="registration.forgot_password" className="form-link" />
            </div>
          </div>

          <form onSubmit={this.handleSubmit}>
            <div className="col-sm-12">
              <label htmlFor="loginEmail">
                <Translate value="registration.email" />
              </label>
              <input
                type="email"
                id="loginEmail"
                value={this.state.email}
                onChange={this.handleChange("email")}
                className="user-info"
                required
                autoComplete="username"
              />
            </div>

            <div className="break-md" />
            <div className="col-sm-12">
              <label htmlFor="loginPassword">
                <Translate value="registration.password" />
              </label>

              <input
                type="password"
                id="loginPassword"
                value={this.state.password}
                onChange={this.handleChange("password")}
                className="user-info"
                required
                autoComplete={"current-password"}
              />
            </div>

            <div className="break-md" />
            <div className="col-sm-6 col-sm-offset-3">
              {isFetching ? (
                <GlobalLoadingIndicator center />
              ) : (
                <button type="submit" className="full-width-button">
                  <Translate value="registration.login" />
                </button>
              )}
            </div>

            <div className="break-md" />
          </form>

          <div className="socialbuttons">
            <div className="col-xs-12 col-sm-6 col-sm-offset-3">
              <a href={facebookAuthUrl}>
                <div className="loginBtn loginBtn--facebook">
                  <Translate value="registration.login_facebook" />
                </div>
              </a>
            </div>
          </div>

          <div className="break-md" />

          <div onClick={() => this.handleForgotPassword()} className="cursor-pointer">
            <Translate value="registration.forgot_password" className="form-link" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  path: state.modal.desiredPath,
}))(LoginForm);
