import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import TicketSellItem from '../components/ticket-sell/ticket-sell-item'
import TicketCodeSellInput from '../containers/ticket-code-sell-input'
import { I18n, Translate } from 'react-redux-i18n'
import SellForm from '../components/ticket-sell/ticket-sell-form'
import SellFormLatter from '../components/ticket-sell/ticket-sell-form-latter'
import ProgressBar from '../components/ticket-sell/ticket-sell-page-progressbar'
import { deleteTicket, setEventInfo } from '../actions/tickets'
import {
  changeStep,
  steps,
  setTicketsToSale,
  changeSellIndividually,
  emptyTicketsInSell
} from '../actions/ticket-sale-steps'
import Placeholder from '../images/placeholder.png'
import moment from 'moment'
import * as API from '../utility/api'
import RepresentMoney from '../utility/represent-money'
import GlobalLoadingIndicator from '../components/global-loading-indicator'

const TicketsSell = ({
  bankAccountNumber,
  bankAccountValid,
  boundary,
  customIBAN,
  currentPhase,
  datePickerFocused,
  dispatch,
  eventInfo,
  events,
  isFetching,
  selectedDate,
  sellPrice,
  sellIndividually,
  pricingPolicy,
  termsAccepted,
  tickets,
  ticketsToSell,
  submitError
}) => {
  const removeTicket = ticketCode => {
    return () => {
      dispatch(deleteTicket(ticketCode))
      //dispatch(removeTicketToSell(ticketCode))
    }
  }

  const getEventDate = eventInfo => {
    if (!eventInfo) return
    return moment(eventInfo.start_date).format('D.M.YYYY') ===
      moment(eventInfo.end_date).format('D.M.YYYY')
      ? moment(eventInfo.end_date).format('D.M.YYYY')
      : `${moment(eventInfo.start_date).format('D.M.')} - ${moment(
          eventInfo.start_date
        ).format('D.M.YYYY')}`
  }

  const removeEveryTicket = () => {
    tickets.forEach(ticket => dispatch(deleteTicket(ticket.code)))
  }

  const handleTicketsToSell = () => {
    if (bankAccountValid && termsAccepted) {
      dispatch(
        setTicketsToSale(
          tickets,
          bankAccountNumber,
          ticketsToSell,
          sellPrice || tickets[0].sum,
          selectedDate,
          sellIndividually,
          boundary,
          pricingPolicy
        )
      )
    } else {
      // User should never see the alert below as the button is disabled if
      // these are not accepted
      alert(I18n.t('user_page.accept_terms_to_continue'))
    }
  }

  const checkEventImage = () => {
    if (eventInfo && eventInfo.image) {
      return eventInfo.image
    } else if (events && eventInfo) {
      for (let parentEvent of events) {
        if (parentEvent?.id && parentEvent.id === eventInfo.parent_id && parentEvent.image) {
          return parentEvent.image
        }
      }

      const imageQueryId =
        eventInfo.parent_id !== '0'
          ? eventInfo.parent_id
          : eventInfo.id

      API.GET('events/' + imageQueryId + '/image')
        .then(({ data }) => {
          const newEventInfo = { ...eventInfo, image: data.image }
          dispatch(setEventInfo(newEventInfo))
        })
        .catch(error => {
          console.log('Image fetch failed', error)
          //this.setState({ error: true })
        })
    }
    return Placeholder
  }

  const ticketItems = tickets.map((ticket, i) => (
    <TicketSellItem
      {...ticket}
      key={`ticket-blaaa-${i}`}
      deleteMe={removeTicket(ticket.code)}
    >
      <TicketSellItem.Column width={2} content={ticket.code} />
      <TicketSellItem.Column width={2} content={ticket.category && ticket.category.name} />
      <TicketSellItem.Column width={2} content={ticket.section && ticket.section.name} />
      <TicketSellItem.Column width={1} content={ticket.row_name} />
      <TicketSellItem.Column width={2} content={ticket.seat_name} />
      <TicketSellItem.Column
        width={2}
        content={<RepresentMoney sum={ticket.sum * 100} eurSuffix />}
      />
    </TicketSellItem>
  ))

  const sellForm = sellIndividually ? (
    tickets.map((ticket, i) => (
      <div className="col-sm-12 col-md-6" key={`sellform-${i}`}>
        <div className="layout-container-undo">
          <div className="ticket-item-container-holder">
            <div className="ticket-item-container">
              <SellForm
                bankAccountValid={bankAccountValid}
                bankAccountNumber={customIBAN}
                currentPhase={currentPhase}
                datePickerFocused={datePickerFocused}
                dispatch={dispatch}
                pricingPolicy={
                  ticketsToSell[ticket.code] &&
                  ticketsToSell[ticket.code].pricingPolicy
                }
                selectedDate={
                  ticketsToSell[ticket.code]
                    ? ticketsToSell[ticket.code].selectedDate
                    : moment(ticket.last_sales_time)
                }
                sellPrice={
                  ticketsToSell[ticket.code] &&
                  ticketsToSell[ticket.code].sellPrice
                }
                sellIndividually={sellIndividually}
                termsAccepted={termsAccepted}
                tickets={tickets}
                isFetching={isFetching}
                eventInfo={eventInfo}
                ticket={ticket}
                ticketCode={ticket.code}
                ticketsToSell={ticketsToSell}
                key={`sellform-${i}`}
              />
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="col-sm-12">
      <div className="ticket-item-container-holder">
        <div className="ticket-item-container">
          <h3 className="text-center">
            <Translate
              value="tickets.sell_group_title"
              amount={tickets.length}
            />
          </h3>
          <SellForm
            boundary={boundary}
            currentPhase={currentPhase}
            datePickerFocused={datePickerFocused}
            dispatch={dispatch}
            eventInfo={eventInfo}
            selectedDate={selectedDate ? selectedDate : moment(new Date())}
            sellPrice={sellPrice}
            sellIndividually={sellIndividually}
            pricingPolicy={pricingPolicy}
            termsAccepted={termsAccepted}
            tickets={tickets}
            isFetching={isFetching}
            ticketsToSell={ticketsToSell}
            ticket={tickets[0]}
            ticketCode={tickets[0] && tickets[0].code}
          />
        </div>
      </div>
    </div>
  )

  const sellFormLatter = (
    <div className="col-sm-12">
      <SellFormLatter
        dispatch={dispatch}
        currentPhase={currentPhase}
        selectedDate={selectedDate}
        datePickerFocused={datePickerFocused}
        sellPrice={sellPrice}
        sellIndividually={sellIndividually}
        pricingPolicy={pricingPolicy}
        bankAccountValid={bankAccountValid}
        bankAccountNumber={bankAccountNumber}
        termsAccepted={termsAccepted}
        tickets={tickets}
        isFetching={isFetching}
        eventInfo={eventInfo}
        userIBAN={customIBAN}
        submitError={submitError}
      />
    </div>
  )

  const ticketCodesList = tickets.map((ticket, index) => {
    const code = ticket.code
    const event = ticket.event.name
    const subevent = ticket.event.subevent_name

    return (
      <div key={code}>
        <div
          className={`ticket-sell-page--ticket-detail--row ${
            index === tickets.length - 1 ? 'ticket-detail--last-row' : ''
          }`}
        >
          <div className="col-xs-7 text-left">
            <div className="ticket-sell-page--ticket-id">
              {<Translate value="tickets.ticket_id" id={code} />}
            </div>
            <div className="ticket-sell-page--event-name">{event}</div>
          </div>

          <div className="col-xs-3">
            <div className="text-left">{subevent}</div>
          </div>

          <div className="col-xs-2">
            <div
              onClick={() => {
                removeTicket(code)()
              }}
              className="cursor-pointer"
            >
              <i className="fa-solid fa-circle-xmark ticket-sell-page--delete--icon" />
            </div>
          </div>
        </div>
      </div>
    )
  })

  const showInput = (
    <div className="row">
      <div className="col-sm-4 tickets-input-info-box">
        <div className="gradientbox">
          <div className="break-lg" />
          <div className="bigbold">
            <Translate
              style={{ textTransform: 'uppercase', color: '#696969' }}
              value="sell_page.header"
            />
          </div>

          <div className="break-lg" />

          <div className="bold text-left">
            <Translate value="sell_page.so_easy" style={{ color: '#696969' }} />
          </div>

          <div className="break-md" />

          <ol className="sell-page-step-list">
            <li>
              <Translate value="sell_page.step_1" />
            </li>
            <li>
              <Translate value="sell_page.step_2" />
            </li>
            <li>
              <Translate value="sell_page.step_3" />
            </li>
            <li>
              <Translate value="sell_page.step_4" />
            </li>
          </ol>
        </div>
      </div>

      <div className="col-sm-8">
        <div className="tickets-input-box text-left">
          <div className="row">
            <div className="col-sm-11 col-sm-offset-1">
              <div className="tickets-input-container text-center">
                <div className="tickets-input">
                  <div className="bigbold">
                    <div className="break-lg" />
                    <Translate
                      style={{
                        textTransform: 'uppercase',
                        color: '#696969',
                        fontSize: '24px'
                      }}
                      value="sell_page.input_ticket_code"
                    />
                    <div className="break-sm" />
                    <Translate
                      style={{
                        color: '#696969',
                        fontSize: '14px',
                        fontWeight: '400'
                      }}
                      value="sell_page.max_4_tickets"
                    />
                    <div className="break-md" />
                  </div>

                  <div className="break-lg" />
                  <TicketCodeSellInput text={I18n.t('tickets.add_ticket')} />
                  {tickets.length > 0 && (
                    <div>
                      <h3>
                        <Translate
                          value="tickets.inputted_tickets"
                          style={{
                            textTransform: 'uppercase',
                            color: '#696969'
                          }}
                        />
                      </h3>

                      {ticketCodesList}

                      <div className="break-lg" />
                      <div className="break-lg" />

                      <button
                        onClick={() => {
                          dispatch(changeStep(steps.basePrice))
                        }}
                      >
                        <Translate
                          value="tickets.set_for_sale"
                          style={{ textTransform: 'uppercase' }}
                        />
                      </button>
                      <div className="break-lg" />
                    </div>
                  )}

                  <div className="break-md" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const ticketList = (
    <Fragment>
      <div className="ticket-items-titles">
        <div className="row">
          <div className="col-sm-2">
            <Translate value="tickets.ticket_code" />
          </div>
          <div className="col-sm-2">
            <Translate value="tickets.type" />
          </div>
          <div className="col-sm-2">
            <Translate value="tickets.section" />
          </div>
          <div className="col-sm-1">
            <Translate value="tickets.row" />
          </div>
          <div className="col-sm-2">
            <Translate value="tickets.seat" />
          </div>
          <div className="col-sm-2">
            <Translate value="tickets.orig_price" />
          </div>
          <div className="col-sm-1" />
        </div>
      </div>

      <div className="ticket-items-container">{ticketItems}</div>

      <div className="break-lg" />

      <label className="text-center">{I18n.t('tickets.add_ticket')}</label>
      <TicketCodeSellInput text={I18n.t('tickets.add_ticket')} />
    </Fragment>
  )

  const showForm = (
    <Fragment>
      <div className="ticket-info-box-container">
        <div className="row">
          <div className="col-sm-5 col-md-4">
            <img
              src={checkEventImage()}
              alt="Tapahtuman mainoskuva"
              className="event-show-image"
            />
          </div>

          <div className="col-sm-7 col-md-8">
            {eventInfo && (
              <h2 className="event-show-title">{eventInfo.name}</h2>
            )}

            <div>{getEventDate(eventInfo)}</div>

            <div>{eventInfo && eventInfo.location && eventInfo.location.name}</div>
          </div>

          <div className="col-sm-12">
            <div className="break-md" />
            {ticketList}
            <div className="break-md" />
          </div>
        </div>
      </div>

      <div className="horizontal-divider">
        <div className="horizontal-divider-box">
          <Translate value="tickets.terms_title" />
        </div>
      </div>

      {tickets.length > 0 && (
        <React.Fragment>
          {tickets.length > 1 && currentPhase === 1 && (
            <div className="single-sell-checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={e => {
                    dispatch(changeSellIndividually(e.target.checked))
                    dispatch(emptyTicketsInSell())
                    jumpToPhase(1)
                  }}
                  defaultChecked={sellIndividually}
                />
                <span className="description-text">
                  <Translate value="tickets.single_sell_checkbox" />
                </span>
              </label>
            </div>
          )}

          <div className="row">
            {sellForm}
            {currentPhase > 3 && sellFormLatter}
          </div>

          <div className="break-lg" />

          <div className="progress-bar-container">
            <ProgressBar
              phase={currentPhase}
              sellIndividually={sellIndividually}
              pricingPolicy={pricingPolicy}
              ticketsToSell={ticketsToSell}
              changeStep={step => {
                ;(step < currentPhase || phaseNoErrors()) && jumpToPhase(step)
              }}
            />
          </div>

          <div className="break-lg" />

          <div className="row center-block ticket-sell-step-button">
            <div className="col-sm-offset-2 col-md-offset-3 col-sm-4 col-md-3 col-sm-push-4 col-md-push-3">
              <button
                className={`button-wide ${
                  currentPhase === 5 ? 'approve-button' : ''
                }`}
                onClick={() => {
                  if (isFetching) return
                  currentPhase < 5
                    ? phaseNoErrors() && changePhase()
                    : handleTicketsToSell()
                }}
                disabled={
                  (currentPhase === 4 && !bankAccountValid) ||
                  (currentPhase === 5 && !termsAccepted)
                }
              >
                {isFetching ? (
                  <GlobalLoadingIndicator center secondary />
                ) : currentPhase < 5 ? (
                  <Translate value="general.next" />
                ) : (
                  <Translate value="tickets.set_for_sale" />
                )}
              </button>
            </div>
            <div className="col-sm-4 col-md-3 col-md-pull-3 col-sm-pull-4">
              <button
                onClick={() => {
                  currentPhase > 1
                    ? jumpToPhase(
                        currentPhase === 3 ? currentPhase - 2 : currentPhase - 1
                      )
                    : removeEveryTicket()
                }}
                className="secondary-button button-wide"
              >
                {currentPhase > 1 ? (
                  <Translate value="general.prev" />
                ) : (
                  <Translate value="general.cancel" />
                )}
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Fragment>
  )

  const phaseErrors = () => {
    const errors = []
    if (currentPhase === 1) {
      tickets.forEach(ticket => {
        if (
          !(
            ticketsToSell[ticket.code] &&
            ticketsToSell[ticket.code].sellPrice &&
            !isNaN(ticketsToSell[ticket.code].pricingPolicy)
          )
        ) {
          errors.push(I18n.t('sell_page.incorrect_price'))
        }
      })
    } else if (currentPhase === 2) {
      tickets.forEach(ticket => {
        if (
          !(ticketsToSell[ticket.code] && ticketsToSell[ticket.code].boundary)
        ) {
          errors.push(I18n.t('sell_page.incorrect_price'))
        }
      })
    } else if (currentPhase === 3) {
      if (sellIndividually) {
        tickets.forEach(ticket => {
          if (
            !(
              ticketsToSell[ticket.code] &&
              ticketsToSell[ticket.code].selectedDate
            )
          ) {
            errors.push(I18n.t('sell_page.incorrect_date'))
          }
        })
      } else {
        // !selectedDate && (valid = false)
      }
    } else if (currentPhase === 4) {
      if (!bankAccountValid) {
        errors.push(I18n.t('sell_page.incorrect_iban'))
      }
    }
    return errors
  }

  const phaseNoErrors = () => phaseErrors().length === 0

  const jumpToPhase = step => {
    if (step === 2) {
      tickets.forEach(ticket => {
        if (
          ticketsToSell[ticket.code] &&
          ticketsToSell[ticket.code].pricingPolicy === 0 &&
          step > currentPhase
        ) {
          step = currentPhase
        }
      })
    }
    dispatch(changeStep(step))
  }

  const changePhase = () => {
    let phaseIncrement = 1
    if (currentPhase === 1) {
      phaseIncrement = 2
      if (sellIndividually) {
        tickets.forEach(ticket => {
          if (
            ticketsToSell[ticket.code] &&
            ticketsToSell[ticket.code].pricingPolicy !== 0
          ) {
            phaseIncrement = 1
          }
        })
      } else {
        if (pricingPolicy !== 0) {
          phaseIncrement = 1
        }
      }
    }
    dispatch(changeStep(currentPhase + phaseIncrement))
  }

  return (
    <div className="section">
      {currentPhase === steps.codeInput || tickets.length === 0
        ? showInput
        : showForm}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    bankAccountNumber: state.ticketSaleSteps.bankAccountNumber,
    bankAccountValid: state.ticketSaleSteps.bankAccountValid,
    boundary: state.ticketSaleSteps.boundary,
    currentPhase: state.ticketSaleSteps.currentPhase,
    customIBAN: state.users.userInfo && state.users.userInfo.iban,
    datePickerFocused: state.ticketSaleSteps.datePickerFocused,
    error: state.ticketSaleSteps.fetchError,
    eventInfo: state.tickets.eventInfo,
    events: state.events.events,
    isFetching: state.ticketSaleSteps.isTicketSellFetching,
    tickets: state.tickets.inSell,
    pricingPolicy: state.ticketSaleSteps.pricingPolicy,
    selectedDate: state.ticketSaleSteps.selectedDate,
    sellIndividually: state.ticketSaleSteps.sellIndividually,
    sellPrice: state.ticketSaleSteps.sellPrice,
    termsAccepted: state.ticketSaleSteps.termsAccepted,
    ticketsToSell: state.ticketSaleSteps.ticketsToSell,
    tokens: state.users.tokens,
    submitError: state.ticketSaleSteps.submitError
  }
}

export default connect(mapStateToProps)(TicketsSell)
