import { push } from 'connected-react-router'
import React from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import {
  removeTicketFromBasket,
  addEmailToBasket,
  killBasket,
  setValidEvent
} from '../actions/shopping-basket'

import { connect } from 'react-redux'
import BasketEventContainer from './basket-event-container'
import RepresentMoney from '../utility/represent-money'

class ShoppingBasket extends React.Component {
  state = {
    email: ''
  }

  componentDidMount() {
    console.log(this.props)
    const { dispatch, eventList, userInfo } = this.props

    if (eventList.length === 0) {
      dispatch(push('/'))
    }
    if (userInfo) {
      dispatch(addEmailToBasket(userInfo.email))
    }
  }

  handleChange = e => {
    this.props.dispatch(addEmailToBasket(e.target.value))
  }

  handleToPayment = e => {
    e.preventDefault()
    this.props.dispatch(push('/payment'))
  }

  namedValid = (validated, id) => {
    const { dispatch } = this.props
    dispatch(setValidEvent(validated, id))
  }

  allEventsValid = () => {
    const { validEvents } = this.props

    return Object.values(validEvents).every(k => k)
  }

  render() {
    const removeTicketFromBasketFunction = dispatch => {
      return id => {
        return () => {
          dispatch(removeTicketFromBasket(id, true))
        }
      }
    }

    const {
      eventList,
      ticketList,
      totalPrice,
      email,
      errors,
      dispatch
    } = this.props

    const eventContainers = eventList.map(event => {
      const eventTickets = ticketList.filter(
        ticket => ticket.event.id === event.id
      )

      if (eventTickets.length > 0) {
        return (
          <BasketEventContainer
            {...event}
            tickets={eventTickets}
            removeTicketFromBasket={removeTicketFromBasketFunction(dispatch)}
            errors={errors}
            key={`basket-event-${event.id}`}
            dispatch={dispatch}
            valid={this.namedValid}
          />
        )
      }

      return <div key={event.id} />
    })

    const summary = ticketList.map(ticket => (
      <div key={`ticket-summary-row-${ticket.id}`}>
        <div className="col-sm-9 col-xs-8">{ticket.name}</div>
        <div className="col-sm-3 col-xs-4 text-right">
          <RepresentMoney sum={ticket.price} eurSuffix />
        </div>
      </div>
    ))

    if (ticketList.length === 0) {
      this.props.dispatch(killBasket())
      this.props.dispatch(push('/'))
    }

    return (
      <div className="section">
        <div className="break-lg" />

        <h2>
          <Translate value="shopping_basket.shopping_basket" />
        </h2>

        <div className="break-lg" />

        {eventContainers}

        <div className="break-lg" />

        <div className="delivery-info-container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="delivery-info">
                <h3 className="delivery-info-title">
                  <Translate value="shopping_basket.delivery_info" />
                </h3>

                <div className="break-md" />

                <form
                  id="email-form"
                  method="get"
                  onSubmit={this.handleToPayment}
                >
                  <label htmlFor="shopping-email" className="darker-label">
                    <Translate value="shopping_basket.email" />
                  </label>
                  <input
                    type="email"
                    id="shopping-email"
                    className="shopping-email-input"
                    value={email}
                    onChange={this.handleChange}
                    disabled={true}
                    required
                  />
                  <div className="break-sm" />

                  <small>
                    <Translate
                      value="shopping_basket.pdf_tickets"
                      className=""
                    />
                  </small>

                  <div className="break-sm" />

                  <label>
                    <input id="email" name="email" type="checkbox" required />
                    {I18n.t('shopping_basket.agree')}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/termsofpurchaseandsale"
                    >
                      {I18n.t('shopping_basket.agree_title')}
                    </a>
                  </label>
                </form>
              </div>
            </div>

            <div className="col-md-8 col-sm-12">
              <div className="ticket-summary">
                <h3>
                  <Translate value="shopping_basket.ticket_summary" />
                </h3>

                <div className="row">
                  <div className="col-sm-9 col-xs-8 bold">
                    <Translate value="shopping_basket.ticket_name" />
                  </div>
                  <div className="col-sm-3 col-xs-4 bold text-right">
                    <Translate value="shopping_basket.price" />
                  </div>
                  {summary}
                </div>

                <div className="break-lg" />

                <div className="text-right">
                  <Translate value="shopping_basket.vat" />
                  {' 0%'}
                </div>
                <div className="break-sm" />

                <div className="text-right">
                  {`${I18n.t('shopping_basket.price')} ${I18n.t(
                    'shopping_basket.in_total'
                  )} `}
                  <div className="bigbold">
                    <RepresentMoney sum={totalPrice} eurSuffix />
                  </div>
                </div>

                <div className="break-md" />

                {ticketList.length > 0 && (
                  <div className="text-right">
                    <button
                      type="submit"
                      form="email-form"
                      disabled={!this.allEventsValid()}
                    >
                      <Translate
                        style={{ textTransform: 'uppercase' }}
                        value="shopping_basket.proceed_to_payment"
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const MapStateToProps = state => {
  return {
    eventList: state.shoppingBasket.eventInfo,
    ticketList: state.shoppingBasket.ticketList,
    endTime: state.shoppingBasket.endTime,
    totalPrice: state.shoppingBasket.totalPrice,
    userInfo: state.users.userInfo,
    email: state.shoppingBasket.email,
    errors: state.shoppingBasket.errors,
    validEvents: state.shoppingBasket.validEvents
  }
}

export default connect(MapStateToProps)(ShoppingBasket)
