import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { createLogger } from 'redux-logger'
import { i18nReducer } from 'react-redux-i18n'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import reducers from '../reducers'

export const history = createBrowserHistory()
const historyMiddleware = routerMiddleware(history)
const loggerMiddleware = createLogger()

//Persist
const persistConfig = {
  key: 'root-new',
  storage: storage,
  blacklist: ['notification', 'payment', 'router', 'tickets']
}

const appReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
  i18n: i18nReducer
})

const rootReducer = (state, action) => {

  // Remove old if exists
  window.localStorage.removeItem('persist:root')

  if (action.type === 'SIGN_OUT') {
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`)
    })

    window.localStorage.removeItem('persist:root-new')

    const { i18n, events } = state
    state = { i18n, events }
  }

  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store = null

if (process.env.NODE_ENV === 'production') {
  const composeEnhancers = compose

  store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(historyMiddleware, thunkMiddleware))
  )
} else {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(historyMiddleware, thunkMiddleware, loggerMiddleware)
    )
  )
}

export const persistor = persistStore(store)
