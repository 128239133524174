import { Route, Redirect } from 'react-router'
import { Translate } from 'react-redux-i18n'
import React from 'react'
import { toggleModal } from '../actions/modal'

export const scopes = {
  LOGGED_IN: 0,
  ADMIN: 1
}

const PrivateRoute = ({ component: Component, store, scope, ...rest }) => {
  const allow = () => {
    switch (scope) {
      case scopes.LOGGED_IN:
        return store.getState().users.signedIn
      case scopes.ADMIN:
        const userInfo = store.getState().users.userInfo

        return (
          userInfo?.group?.name === 'admin'
        )
      default:
        return false
    }
  }

  const spawnLoginModal = () => {
    store.dispatch(toggleModal(true, rest.path))

    return (
      <div className="login-notice">
        <Translate value="login.login_needed" />
      </div>
    )
  }

  /**
   * if user is allowed to enter page with given scope, render said component/page
   * if user is not allowed to enter page with scope LOGGED_IN, render login modal and login_needed page.
   * if user is trying to enter admin scope, redirect to "/", like any invalid path, if not allowed
   */
  return (
    <Route
      {...rest}
      render={props => {
        return allow() ? (
          <Component {...props} />
        ) : scope === scopes.ADMIN ? (
          <Redirect to="/" />
        ) : (
          spawnLoginModal()
        )
      }}
    />
  )
}

export default PrivateRoute
