import { push } from 'connected-react-router'
import * as API from '../utility/api'
import { store } from '../utility/createStore'
import jwtDecode from 'jwt-decode'
import { toggleModal } from '../actions/modal'
import { changeTab } from '../actions/user-page-ui'
import { TABS } from '../actions/user-page-ui'
import { I18n } from 'react-redux-i18n'
import {
  SUBSCRIPTION_SUCCESS,
  REMOVE_SUBSCRIPTION,
  SET_FORCED_LOGIN_FROM,
  CHANGE_BANK_ACCOUNT_NUMBER,
  DELETE_TICKET_FROM_SELL,
  INITIATE_TICKET_UPDATE,
  TICKET_UPDATE_SUCCESS,
  LOAD_USER_PDF_DATA,
  START_USER_HISTORY_FETCH,
  USER_HISTORY_FETCH,
  START_USER_INFO_UPDATE,
  USER_INFO_UPDATING,
  STOP_USER_INFO_UPDATE,
  SIGN_IN_SUCCESS,
  USER_SUBSCRIPTIONS_FETCH,
  SIGN_OUT,
  START_FETCH,
  SIGN_IN_FAILURE,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CHANGE_VIEW,
  REFRESH_TOKENS,
  INITIATE_VERIFICATION,
  VERIFICATION_SUCCESS,
  VERIFICATION_FAILURE
} from '../actionTypes'

export const views = {
  login: 0,
  register: 1,
  verificationCode: 2
}

export const createSubscription = (eventId, parent_child_type) => {
  return dispatch => {
    dispatch({
      type: SUBSCRIPTION_SUCCESS,
      eventId
    })

    API.POST('subscriptions', {
      event_id: eventId,
      parent_child_type
    })
  }
}

export const deleteSubscription = eventId => {
  return dispatch => {
    dispatch({
      type: REMOVE_SUBSCRIPTION,
      eventId
    })

    API.DELETE('subscriptions', {
      event_id: eventId
    })
  }
}

export const setForcedLoginFrom = path => {
  return dispatch =>
    dispatch({
      type: SET_FORCED_LOGIN_FROM,
      path
    })
}

export const resendConfirmationCode = email => {
  return () => {
    API.POST('auth/verify/resend', {
      email,
    })
      .then(() => {
        API.apiNotification({
          value: 'notifications.code_resent',
          status: 'success'
        })
      })
      .catch(e => console.log('resend', e))
  }
}

export const changeBankAccountNumber = iban => {
  return {
    type: CHANGE_BANK_ACCOUNT_NUMBER,
    iban
  }
}

export const deleteTicketFromSell = ticket_id => {
  return dispatch => {
    API.DELETE(`tickets/${ticket_id}`)
      .then(() => {
        dispatch({
          type: DELETE_TICKET_FROM_SELL,
          ticket_id
        })
        console.log('Ticket removed succesfully')
      })
      .catch(() => {
        API.apiNotification({
          value: 'tickets.delete_from_sell_failed',
        })
        console.log('Deleting ticket failed')
      })
  }
}

export const updateTicket = ticket => {
  return dispatch => {
    dispatch({
      type: INITIATE_TICKET_UPDATE
    })

    API.PUT(`tickets/${ticket.id}`, {
      ticket
    })
      .then(({ data }) => {
        if (data.status === 'error') {
          const errorMessage = data.message || 'tickets.update_failed'
          API.apiNotification({
          value: I18n.t(errorMessage),
          status: 'error'
          })          
        } else {
          // console.log('UPDATE DATA', data)
          dispatch({
            type: TICKET_UPDATE_SUCCESS,
            ticket: data
          })
          API.apiNotification({
          value: I18n.t('tickets.updated_successfully'),
          status: 'success'
          })
        }
      })
      .catch((e) => {
        console.log('TICKET UPDATE ERROR', e)
        API.apiNotification({
        value: I18n.t('tickets.update_failed'),
        status: 'error'
      })
    })
  }
}

export const loadPDFData = pdfNames => {
  return dispatch => {
    const pdfFetches = pdfNames.map(file_name => {
      return API.POST('tickets/pdf', {
        file_name
      })
    })

    Promise.all(pdfFetches)
      .then(requests => {
        const pdf_data = requests.map(req => req.data.pdf_info)

        dispatch({
          type: LOAD_USER_PDF_DATA,
          pdf_data
        })
      })
      .catch(() => {
        console.log('Fetch failed')
      })
  }
}

export const fetchUserHistory = id => {
  return dispatch => {
    dispatch({
      type: START_USER_HISTORY_FETCH
    })

    API.GET(`customers/${id}/history`)
      .then(({ data }) => {
        dispatch({
          type: USER_HISTORY_FETCH,
          data
        })
      })
      .catch(() => {
        console.log('User history fetch failed')
      })
  }
}

export const updateUserInfos = (params, id) => {
  return dispatch => {
    dispatch({
      type: START_USER_INFO_UPDATE
    })

    const mappedParams = {
      last_name: params.lastName,
      first_name: params.firstName,
      phone_number: params.phoneNumber,
      allow_marketing:  Boolean(params.allowMarketing),
      password: params.password,
      iban: params.iban,
      bic: params.bic,
      previous_password: params.previousPassword
    }

    Object.keys(mappedParams).forEach(
      key => mappedParams[key] == null && delete mappedParams[key]
    )

    API.PUT(`customers/${id}`, {
      ...mappedParams
    })
      .then(({ data }) => {
        const first_name = data.customer && data.customer.first_name
        const last_name = data.customer && data.customer.last_name
        const phone_number = data.customer && data.customer.phone_number
        const iban = data.customer && data.customer.iban
        const bic = data.customer && data.customer.bic
        const allow_marketing =
          data.customer && data.customer.allow_marketing

        dispatch({
          type: USER_INFO_UPDATING,
          first_name,
          email: data.customer.email,
          last_name,
          allow_marketing,
          phone_number,
          iban,
          bic
        })

        dispatch(changeTab(TABS.info))
        API.apiNotification({
          value: 'user.updated_successfully',
          status: 'success'
        })
      })
      .catch(e => {
        const message =
          e && e.response && e.response.data && e.response.data.message

        dispatch({
          type: STOP_USER_INFO_UPDATE,
          message
        })
      })
  }
}

export const initiatePasswordRecovery = email => {
  return () => {
    API.POST('auth/forgot/start', {
      email
    })
      .then(() => {
        API.apiNotification({
          value: 'user.password_change_code_sent',
          status: 'success'
        })
      })
      .catch(() => {
        API.apiNotification({
          value: 'user.password_change_code_sent',
          status: 'success'
        })
      })
  }
}

export const confirmPasswordRecovery = (email, token, new_password) => {
  return dispatch => {
    API.POST('auth/forgot/change', {
      code: token,
      email: email,
      password: new_password
    })
      .then(() => {
        API.apiNotification({
          value: 'user.confirm_password_change_success',
          status: 'success'
        })
        dispatch(toggleModal(true))
      })
      .catch(() => {
        API.apiNotification({
          value: 'user.confirm_password_change_failure',
          status: 'error'
        })
      })
  }
}

export const signIn = (email, password, path = false) => {
  return (dispatch, getState) => {
    dispatch(startFetch())

    API.POST('auth/login', {
      email,
      password
    })
      .then(({ data }) => {
        const tokens = data.tokens

        dispatch({
          type: SIGN_IN_SUCCESS,
          tokens: tokens,
          userInfo: checkTokens(tokens)
        })

        dispatch(fetchSubscribedEvents())

        const forcedLoginFrom = getState().users.forcedLoginFrom
        if (forcedLoginFrom) {
          dispatch(push(forcedLoginFrom))
          return
        }

        path = path ? path : '/'
        dispatch(push(path))
      })
      .catch(e => {
        const code = e && e.response && e.response.status

        // missing verification
        if (code === 303) {
          API.apiNotification({
            value: 'notifications.verification_needed',
            status: 'error'
          })
          dispatch(signInFailure())
          return
        }

        API.apiNotification({
          value: 'notifications.sign_in_error_header',
          status: 'error'
        })
        dispatch(signInFailure())
      })
  }
}

export const socialSignIn = tokens => {
  return dispatch => {
    dispatch({
      type: SIGN_IN_SUCCESS,
      tokens: tokens,
      userInfo: checkTokens(tokens)
    })

    dispatch(fetchSubscribedEvents())

    dispatch(push('/'))
  }
}

export const socialSignInError = () => {
  return dispatch => {

    API.apiNotification({
      value: 'notifications.facebook_sign_in_error_header',
      status: 'error'
    })

    dispatch(push('/'))
  }
}

const fetchSubscribedEvents = () => {
  return dispatch => {
    API.GET('subscriptions')
      .then(({ data }) => {
        dispatch({
          type: USER_SUBSCRIPTIONS_FETCH,
          data
        })
      })
      .catch(() => {})
  }
}

const checkTokens = ({ access_token, id_token }) => {
  const userInfo = {
    ...jwtDecode(access_token),
    ...jwtDecode(id_token),
  }

  if (userInfo.allow_marketing) {
    userInfo.allow_marketing = true
  } else {
    userInfo.allow_marketing = false
  }

  return {
    ...userInfo
  }
}

export const signOut = () => {
  return async dispatch => {
    dispatch(push('/'))

    const token = store.getState().users.refresh_token

    if(token) {
      API.POST('auth/logout', {
        refresh_token: token
      })
        .then(() => {
          window.FB.getLoginStatus(response => {
            if (response.status === 'connected') {
              window.FB.logout(() =>
                console.log('User logged out from Facebook succesfully')
              )
            }
          })
      
          dispatch({
            type: SIGN_OUT
          })
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          window.FB.logout(() =>
            console.log('User logged out from Facebook succesfully')
          )
        }
      })

      dispatch({
        type: SIGN_OUT
      })
    }
  }
}

const startFetch = (email = null) => {
  return {
    type: START_FETCH,
    email
  }
}

const signInFailure = () => {
  return {
    type: SIGN_IN_FAILURE
  }
}

// SIGN UP HERE
export const signUp = (
  email,
  password,
  birthdate,
  phone_number,
  first_name,
  last_name,
  allow_marketing,
  accepted_terms
) => {
  return dispatch => {
    dispatch(startFetch(email))

    API.POST('customers', {
      email,
      password,
      last_name,
      first_name,
      phone_number,
      birthdate,
      allow_marketing,
      accepted_terms,
    })
      .then(() => {
        dispatch(signUpSuccess())
        dispatch(push("/register/verify"));
      })
      .catch(res => {
        const message =
          (res &&
            res.response &&
            res.response.data &&
            res.response.data.message) ||
          'username_exists'
        dispatch(signUpFailure(message))
      })
  }
}

const signUpSuccess = () => {
  return {
    type: SIGN_UP_SUCCESS
  }
}

const signUpFailure = message => {
  return {
    type: SIGN_UP_FAILURE,
    message
  }
}

export const changeView = view => {
  return {
    type: CHANGE_VIEW,
    view
  }
}

export const refreshTokens = newTokens => {
  return {
    type: REFRESH_TOKENS,
    newTokens
  }
}

export const initiateVerification = (email, code) => {
  return dispatch => {
    dispatch({
      type: INITIATE_VERIFICATION
    })

    API.POST('auth/verify', {
      email,
      code,
    })
      .then(() => {
        dispatch({
          type: VERIFICATION_SUCCESS
        })

        dispatch(push('/'))
        dispatch(toggleModal(true))
      })
      .catch(e => {
        dispatch({
          type: VERIFICATION_FAILURE,
          message: e && e.response && e.response.data && e.reponse.data.message
        })
      })
  }
}
