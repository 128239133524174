import React, { useState } from 'react'
import TranslationRowList from './translation-row-list'
import * as API from '../../utility/api'

const Translations = ({
  modifiedTranslations,
  translationRowOnChange,
  dispatchAddFaqRow,
  dispatchLoadTranslations
}) => {
  const [fetching, setFetchinig] = useState(false)

  const saveTranslations = () => {
    if (!modifiedTranslations) return

    dispatchLoadTranslations(modifiedTranslations)

    setFetchinig(true)
    API.POST('admin/translations', {
      modifiedTranslations
    })
      .then(() => setFetchinig(false))
      .catch(() => setFetchinig(false))
  }

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-sm-3'>
          <button onClick={() => saveTranslations()}>
            {fetching ? 'Tallennetaan...' : 'Tallenna muutokset'}
          </button>
        </div>

        <div className='break-md' />

        <TranslationRowList
          translations={modifiedTranslations}
          updateTranslation={translationRowOnChange}
          dispatchAddFaqRow={dispatchAddFaqRow}
        />
      </div>
    </React.Fragment>
  )
}

export default Translations
