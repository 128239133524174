import * as API from '../utility/api'
import moment from 'moment'
import {
  START_EVENTS_FETCH,
  EVENTS_FETCH_SUCCESS,
  EVENT_IDS_AND_NAMES,
  SET_CATEGORIES_COUNTER,
  START_EVENT_INFO_FETCH,
  EVENT_INFO_FETCH_SUCCESS,
  EVENT_INFO_FETCH_FAIL,
  CHANGE_SELECTED_EVENT,
  SELECT_PREVIOUS_EVENT,
  TOGGLE_MAINPAGE_DROPDOWN_HOVER,
  SET_MAP
} from '../actionTypes'

moment.locale('fi')

// fetches all events that have tickets in sell
export const getAllEvents = (locale = 'fi', localeChanged = false) => {
  return (dispatch, getState) => {
    const minuteAgo = moment()
      .subtract(1, 'minute')
      .unix()
    const eventsLastFetchedAt = getState().events.eventsFetchedAt

    console.log(locale);

    // if events are last fetched within a minute, dont fetch them again
    if (!localeChanged && eventsLastFetchedAt && minuteAgo < eventsLastFetchedAt) return

    if (getState().events.isFetching) return

    dispatch({
      type: START_EVENTS_FETCH
    })

    API.GET('events/insell')
      .then(({ data }) => {
        dispatch({
          type: EVENTS_FETCH_SUCCESS,
          locale: locale,
          allEvents: data.all_events,
          upcomingEvents: data.upcoming_events,
          newest: data.newest_tickets,
          mostViewed: data.most_viewed
        })

        fetchAllEventsAndIds(dispatch)
      })
      .catch(() => {
        console.log('Sale fetch failed')
      })
  }
}

export const setCategoriesCounter = categoriesCounter => {
  return dispatch => {
    dispatch({
      type: SET_CATEGORIES_COUNTER,
      categoriesCounter
    })
  }
}

export const fetchAllEventsAndIds = (dispatch) => {
  API.GET(process.env.REACT_APP_EVENT_CACHE_URL, {}, false)
    .then(({ data }) => {
      dispatch({
        type: EVENT_IDS_AND_NAMES,
        data
      })
    })
    .catch(() => console.log('Ids and names fetch failed'))
}

export const getOneEventInfo = (eventID) => {
  return dispatch => {
    dispatch({
      type: START_EVENT_INFO_FETCH
    })

    API.GET(`events/${eventID}`, {})
      .then(({ data }) => {
        const eventInfo = data

        dispatch(loadEventSeatMap(eventInfo.event))

        dispatch({
          type: EVENT_INFO_FETCH_SUCCESS,
          eventInfo
        })
      })
      .catch(() => {
        dispatch({
          type: EVENT_INFO_FETCH_FAIL
        })
      })
  }
}

const loadEventSeatMap = eventInfo => {
  return dispatch => {
    const { section_map_id } = eventInfo

    if (section_map_id) {
      API.POST('events/section_map', {
        section_map_id
      })
        .then(({ data }) => {
          dispatch(setMap(data))
        })
        .catch(() => {
          dispatch(setMap(null))
        })
    }
  }
}

const setMap = data => {
  return dispatch => {
    dispatch({
      type: SET_MAP,
      data
    })
  }
}

export const changeSelectedEvent = eventInfo => {
  return {
    type: CHANGE_SELECTED_EVENT,
    event: eventInfo.event,
    tickets: eventInfo.tickets
  }
}

export const selectPreviousEvent = () => {
  return {
    type: SELECT_PREVIOUS_EVENT
  }
}

export const toggleHover = () => ({
  type: TOGGLE_MAINPAGE_DROPDOWN_HOVER
})
