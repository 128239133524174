import React from 'react'
import { connect } from 'react-redux'

const Hero = ({ location }) => {
  if (!location) return null
  if (location.pathname !== '/') return null

  return (
    <div className="hero">
      <div className="svg-holder">
        <svg xmlns="http://www.w3.org/2000/svg">
          <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
            <path
              transform="scale(0.000697, 0.00127)"
              d="M0,398.198284 C19.121717,491.900087 40.737488,628.169243 98.3207779,671.724699 C136.643876,697.32749 170.649188,696.25411 210.811962,693.725368 C388.393876,682.544397 748.954325,709.517844 1024.64034,735.730087 C1103.0493,743.185215 1241.50252,759.991652 1440,786.149397 L1440,0 L0,0 L0,398.198284 Z"
            />
          </clipPath>
        </svg>
        <div className="background-image" />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    location: state.router.location
  }
}

export default connect(mapStateToProps)(Hero)
