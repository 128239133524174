// sum comes as string and as cents
// 1€ == "100"
const RepresentMoney = ({ sum, eurSuffix = false }) => {
  const ret = (parseInt(sum, 10) / 100)
    .toFixed(2)
    .toString()
    .replace('.', ',')

  return eurSuffix ? ret + ' €' : ret
}

export default RepresentMoney
