import React from 'react'
import { Translate } from 'react-redux-i18n'
import SubEventListItem from './event-subevent-list-item'

const SubEventList = ({ eventInfos, changeEvent }) => {
  const eventsList = eventInfos.map(
    (info) =>
      info.tickets &&
      info.tickets.length > 0 && (
        <SubEventListItem
          key={info.event.id}
          event={info.event}
          tickets={info.tickets}
          changeEvent={changeEvent}
        />
      )
  )

  return (
    <React.Fragment>
      {eventsList.every((e) => e === false) ? (
        <div className="text-center">
          <div className="break-md" />
          <Translate value="tickets.no_available_tickets" />
        </div>
      ) : (
        eventsList
      )}
    </React.Fragment>
  )
}

export default SubEventList
