import { CHANGE_TAB } from '../actionTypes'

export const TABS = {
  info: 0,
  history: 1,
  update: 2,
  inSell: 3
}

export const changeTab = tab => {
  return {
    type: CHANGE_TAB,
    tab
  }
}
