import React, { Fragment } from 'react'
import { Translate } from 'react-redux-i18n'
import { changeBankAccountNumber } from '../../actions/ticket-sale-steps'

const erroredBankAccount = (accountNumber, valid) => {
  const stripped = accountNumber.replace(/ /g, '')

  if (valid) return null
  if (stripped.length < 18) return null

  return (
    <div className="ticket-sell-error text-center">
      <Translate value="tickets.bank_account_error" />
    </div>
  )
}

const Comp = ({ dispatch, visible, bankAccountValid, bankAccountNumber }) => {
  const visibleContent = (
    <div className="ticket-item-container-holder">
      <div className="ticket-item-container">
        <h3 className="orange-title">
          <Translate value="user.bank_account_title" />
        </h3>
        <div className="label--thick">
          <Translate value="user.bank_account_number" />
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-4" style={{ position: 'relative' }}>
            <input
              onChange={e => {
                dispatch(changeBankAccountNumber(e.target.value))
              }}
              className="account-number"
              type="text"
              maxLength={34}
              value={bankAccountNumber}
            />

            {bankAccountValid && (
              <div className="ticket-sell-bank-account-valid">
                <i className="fa-solid fa-check fa-2x" 
                  style={{ paddingTop: '8px', color: 'green' }} 
                />
              </div>
            )}
          </div>
        </div>

        {erroredBankAccount(bankAccountNumber, bankAccountValid)}

        <div className="break-sm" />
      </div>
    </div>
  )

  return <Fragment>{visible && visibleContent}</Fragment>
}

export default class TicketAccountNumber extends React.Component {
  componentDidMount() {
    const { userIBAN, dispatch } = this.props
    if (userIBAN) {
      dispatch(changeBankAccountNumber(userIBAN))
    }
  }

  render() {
    return <Comp {...this.props} />
  }
}
