import React from 'react'
import { connect } from 'react-redux'
import { getOneEventInfo } from '../actions/events'
import Select from 'react-select'
import { push } from 'connected-react-router'
import differenceBy from 'lodash/differenceBy'
import { Translate } from 'react-redux-i18n'

import moment from 'moment'

const customStyles = {
  option: base => ({
    ...base,
    color: '#8d3fa6',
    textAlign: 'left'
  }),
  control: base => ({
    ...base,
    borderRadius: '10px',
    fontSize: '14px',
    fontWeight: '400',
    padding: '7px 15px'
  })
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  textAlign: 'left'
}

const groupBadgeStyles = {
  display: 'none'
}

class SearchInput extends React.Component {
  state = {
    selectedOption: ''
  }

  // this change refers to when the selected item changes
  // not when value in input changes
  handleChange = selectedOption => {
    if (!selectedOption.label || !selectedOption.value) return

    const dispatch = this.props.dispatch
    dispatch(getOneEventInfo(selectedOption.value))
    dispatch(push(`/events/${selectedOption.value}`))
  }

  render() {
    const { selectedOption } = this.state
    const { events, allEventsIdsAndNames } = this.props

    const value = selectedOption && selectedOption.value

    const withTicketsOptions =
      events &&
      events.map(event => {

        const location =
        typeof event.location.name === 'string' && event.location.name !== ''
          ? ` - ${event.location.name}`
          : '';

        const start = moment(event.start_date_exact);
        const startDate = start.isValid
          ? ` - ${start.format('DD.MM.YYYY')}`
          : '';

        return {
          value: event.id,
          label: `${event.name}${location}${startDate}`
        }
      })

    const withNoTicketsOptions = differenceBy(
      allEventsIdsAndNames,
      withTicketsOptions,
      'value'
    )

    const groupedOptions = [
      {
        label: <Translate value="tickets.available_tickets_title" />,
        options: withTicketsOptions
      },
      {
        label: <Translate value="tickets.not_available_tickets_title" />,
        options: withNoTicketsOptions
      }
    ]

    const formatGroupLabel = data => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    )

    return (
      <React.Fragment>
        <Select
          styles={customStyles}
          value={value}
          onChange={this.handleChange}
          options={groupedOptions}
          placeholder={<Translate value="tickets.find_event_place_artist" />}
          formatGroupLabel={formatGroupLabel}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    events: state.events.events,
    eventSearchInput: state.events.eventSearchInputValue,
    allEventsIdsAndNames: state.events.allEventsIdsAndNames
  }
}

export default connect(mapStateToProps)(SearchInput)
