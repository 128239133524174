import {
  CHANGE_ADMIN_TAB,
  UPDATE_TRANSLATION,
  SET_MODIFIED_TRANSLATIONS,
  ADD_FAQ_ROW
} from '../actionTypes'

export const TABS = {
  RAPORTS: 0,
  TRANSLATIONS: 1,
  STATS: 2,
  MANAGE: 3,
  USERS: 4,
  TICKETS: 5,
  SEPA: 6
}

export const changeAdminTab = tab => ({
  type: CHANGE_ADMIN_TAB,
  tab
})

export const updateTranslation = (lang, topic, key, value, path) => {
  return dispatch => {
    dispatch({
      type: UPDATE_TRANSLATION,
      lang,
      topic,
      key,
      value,
      path
    })
  }
}

export const setModifiedTranslations = () => {
  return (dispatch, getState) => {
    const { translations } = getState().i18n

    dispatch({
      type: SET_MODIFIED_TRANSLATIONS,
      translations
    })
  }
}

export const addFaqRow = lang => ({
  type: ADD_FAQ_ROW,
  lang
})
