import React from 'react'
import { connect } from 'react-redux'
import { toggleModal } from '../actions/modal'
import LoginForm from '../components/login-register/login-form'
import Modal from 'react-modal'

Modal.setAppElement('#root')

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '320px',
    maxWidth: '700px',
    padding: '10px 20px 20px 20px'
  }
}

class LoginModal extends React.Component {
  componentWillMount() {
    this.props.dispatch(toggleModal(false))
  }

  render() {
    const {
      dispatch,
      user,
      modalOpen,
      isFetching,
      verificationMessage
    } = this.props

    const closeModal = () => {
      dispatch(toggleModal(false))
    }

    const isModalOpen = () => {
      const signedIn = user.signedIn
      return !signedIn && modalOpen
    }

    return (
      <Modal
        isOpen={isModalOpen()}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Login Modal"
      >
        <LoginForm
          dispatch={dispatch}
          verificationMessage={verificationMessage}
          closeModal={closeModal}
          isFetching={isFetching}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.users.isFetching,
    signedIn: state.users.signedIn,
    verificationMessage: state.users.verificationMessage,
    modalOpen: state.modal.modalOpen,
    user: state.users
  }
}

export default connect(mapStateToProps)(LoginModal)
