import React from 'react'
import TicketListBatch from './event-buyable-ticket-batch'
import { groupBy } from 'lodash'
import './event-buyable-ticket.scss'

const BuyableEventList = ({
  tickets,
  addToBasket,
  isFetching,
  eventInfo,
  dispatch,
  bargains,
  userId
}) => {
  const toListItem = tickets => {
    let keys = []
    for (let key in tickets) {
      keys.push(key)
    }
    keys.sort()

    return keys.map(key => {
      const batch = tickets[key]
      return (
        <TicketListBatch
          key={key}
          batch={batch}
          count={batch.length}
          addToBasket={addToBasket}
          isFetching={isFetching}
          eventInfo={eventInfo}
          tickets={tickets}
          dispatch={dispatch}
          bargains={bargains}
          image={batch[0].image}
          userId={userId}
        />
      )
    })
  }

  const mapTicketList = () => {
    const groupedTickets = groupBy(tickets, 'batch.id')

    return toListItem(groupedTickets)
  }

  return (
    <React.Fragment>
      <div className="col-sm-12">{mapTicketList()}</div>
    </React.Fragment>
  )
}

export default BuyableEventList
