import React from 'react'
import { Translate } from 'react-redux-i18n'
import moment from 'moment'
import RepresentMoney from '../../utility/represent-money'

const SubEventListItem = ({ event, tickets, changeEvent }) => {
  let cheapestTicket = null
  let ticketsLeft = null
  const eventInfo = { event, tickets }

  if (tickets.length > 0) {
    const prices = tickets.map((t) => parseInt(t.price, 10))
    cheapestTicket = Math.min(...prices)
    ticketsLeft = tickets.length
  }

  const ticketsPath = `/events/${event.id}`

  return (
    <div className="col-sm-6">
      <div className="event-subevent">
        <div
          className="event-subevent-bottom cursor-pointer"
          onClick={changeEvent(ticketsPath, eventInfo)}
        >
          <div className="row">
            <div className="col-sm-8 event-subevent-left-info text-center">
              <h3>{event.subevent_name}</h3>
              <div>
                <p>{moment(event.start_date).format('DD.MM.YYYY')}</p>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="event-subevent-right-info text-center">
                <div className="">
                  <small>
                    <Translate value="tickets.in_sell" />
                  </small>
                </div>
                <div className="event-subevent-value">
                  <small>
                    {ticketsLeft ? (
                      <span>
                        {ticketsLeft + ' '}
                        <Translate value="tickets.pieces" />
                      </span>
                    ) : (
                      <Translate value="tickets.no_available_tickets" />
                    )}
                  </small>
                </div>
              </div>
              <div className="event-subevent-right-info text-center">
                <div>
                  <small>
                    <Translate value="tickets.from" />
                  </small>
                </div>
                <div className="event-subevent-value">
                  <small>
                    {cheapestTicket ? (
                      <RepresentMoney sum={'' + cheapestTicket} eurSuffix />
                    ) : (
                      <Translate value="tickets.no_available_tickets" />
                    )}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubEventListItem
