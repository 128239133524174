import React from 'react'
import { connect } from 'react-redux'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { push } from 'connected-react-router'
import RegisterForm from '../components/login-register/register-form'

class Login extends React.Component {
  componentWillMount = () => {
    const { dispatch } = this.props

    if (this.props.signedIn) {
      dispatch(push('/'))
    }
  }

  render() {
    const { dispatch, signUpError } = this.props

    const renderViewContent = () => {
      const { isFetching } = this.props

      if (isFetching) {
        return (
          <div>
            <GlobalLoadingIndicator center />
          </div>
        )
      }

      return <RegisterForm dispatch={dispatch} error={signUpError} />
    }

    return (
      <div className="row text-center section">
        <div className="col-sm-6 col-sm-offset-3">
          <div className="login-form-container">
            <div className="login-form-window">{renderViewContent()}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.users.isFetching,
    signedIn: state.users.signedIn,
    signUpError: state.users.signUpError
  }
}

export default connect(mapStateToProps)(Login)
