import React from 'react'
import { Translate } from 'react-redux-i18n'
import { steps } from '../../actions/ticket-sale-steps'
import { I18n } from 'react-redux-i18n'

const ProgressBar = props => {
  const {
    phase,
    changeStep,
    sellIndividually,
    ticketsToSell,
    pricingPolicy
  } = props

  const {
    basePrice,
    lastSellDate,
    minMaxPrice,
    accountNumber,
    acceptTerms
  } = steps

  const showBoundary = () => {
    if (sellIndividually) {
      let show = false
      Object.values(ticketsToSell).forEach(ticket => {
        if (ticket.pricingPolicy !== 0) {
          show = true
        }
      })
      return show
    } else {
      return pricingPolicy === 0 ? false : true
    }
  }

  return (
    <React.Fragment>
      <div
        onClick={() => {
          changeStep(basePrice)
        }}
        className={`cursor-pointer ${
          phase === basePrice
            ? 'progress-bar-active progress-bar'
            : 'progress-bar-history progress-bar'
        }`}
      >
        <Translate value="tickets.start_price" />
      </div>

      {showBoundary() && (
        <div
          onClick={() => {
            changeStep(minMaxPrice)
          }}
          className={`cursor-pointer ${
            phase === minMaxPrice
              ? 'progress-bar-active progress-bar'
              : phase > minMaxPrice
              ? 'progress-bar-history progress-bar'
              : 'progress-bar-future progress-bar'
          }`}
        >
          {I18n.t('tickets.min_max_price')}
        </div>
      )}

      <div
        onClick={() => {
          changeStep(lastSellDate)
        }}
        className={`cursor-pointer ${
          phase === lastSellDate
            ? 'progress-bar-active progress-bar'
            : phase > lastSellDate
            ? 'progress-bar-history progress-bar'
            : 'progress-bar-future progress-bar'
        }`}
      >
        <Translate value="tickets.last_sell_date" />
      </div>

      <div
        onClick={() => {
          changeStep(accountNumber)
        }}
        className={`cursor-pointer ${
          phase === accountNumber
            ? 'progress-bar-active progress-bar'
            : phase > accountNumber
            ? 'progress-bar-history progress-bar'
            : 'progress-bar-future progress-bar'
        }`}
      >
        <Translate value="tickets.bank_account_number" />
      </div>

      <div
        onClick={() => {
          changeStep(acceptTerms)
        }}
        className={`cursor-pointer ${
          phase === acceptTerms
            ? 'progress-bar-active progress-bar'
            : phase > acceptTerms
            ? 'progress-bar-history progress-bar'
            : 'progress-bar-future progress-bar'
        }`}
      >
        <Translate value="tickets.service_terms" />
      </div>
    </React.Fragment>
  )
}

export default ProgressBar
