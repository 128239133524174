import React from 'react'
import GlobalLoadingIndicator from '../components/global-loading-indicator'

const ButtonLoadingIndicator = ({ content, loading, onClick, error }) => {
  return (
    <div className="loading-button" onClick={onClick}>
      {!loading && <div className="color-vivid">{content}</div>}
      {error && <div className="error-message">{error}</div>}
      <div className="loading-button-indicator">
        {loading && !error && <GlobalLoadingIndicator center />}
      </div>
    </div>
  )
}

export default ButtonLoadingIndicator
