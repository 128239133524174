import React from 'react'

const EventDescription = ({
  description_text,
  description_text_en,
  locale,
  name
}) => {
  const descriptionText = locale === 'fi' ? description_text : description_text_en

  return (
    <div id="event-description" className="event-description">
      <br />
      <h2>{name}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: descriptionText }}
        className="event-description-text"
      />
    </div>
  )
}

export default EventDescription
