import React, { Fragment } from 'react'
import { Translate } from 'react-redux-i18n'
import Slider from 'rc-slider'
import { pricingPolicies } from '../../actions/ticket-sale-steps'
import { changeBoundaryValue } from '../../actions/ticket-sale-steps'

class MaxPrice extends React.Component {
  componentDidUpdate() {
    const {
      boundary,
      dispatch,
      tickets,
      ticketCode,
      sellIndividually,
      sellPrice
    } = this.props
    if (!boundary) {
      sellIndividually
        ? dispatch(changeBoundaryValue(sellPrice, ticketCode))
        : tickets.forEach(ticket => {
            dispatch(changeBoundaryValue(sellPrice, ticket.code))
          })
    }
  }

  render() {
    const {
      ticketPrice,
      dispatch,
      sellPrice,
      ticketCode,
      pricingPolicy,
      visible,
      sellIndividually,
      tickets,
      boundary
    } = this.props
    const minPrice = pricingPolicy === pricingPolicies.rising ? sellPrice : 1
    const maxPrice =
      pricingPolicy === pricingPolicies.rising ? ticketPrice * 1.2 : sellPrice

    const content = (
      <Fragment>
        <div className="section">
          <p className="ticket-min-max-price">
            {pricingPolicy === 2 && <Translate value="tickets.min_price" />}
            <Translate value="tickets.base_price" />
            {pricingPolicy === 1 && <Translate value="tickets.max_price" />}
          </p>
          <div className="col-sm-12 ticket-min-max-price">
            <div className="ticket-sell-price text-right">
              {pricingPolicy === 2 ? boundary : sellPrice} €
            </div>
            <div className="ticket-sell-price text-right">
              {pricingPolicy === 1 ? boundary : sellPrice} €
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <Slider
              min={minPrice}
              max={maxPrice}
              defaultValue={sellPrice}
              step={1}
              onChange={value => {
                sellIndividually
                  ? dispatch(changeBoundaryValue(value, ticketCode))
                  : tickets.forEach(ticket => {
                      dispatch(changeBoundaryValue(value, ticket.code))
                    })
              }}
            />
          </div>
        </div>
        <div className="break-md" />
        <div className="break-md" />
        <div className="row ticket-sell-step-border" />
      </Fragment>
    )

    return <Fragment>{visible && content}</Fragment>
  }
}

export default MaxPrice
