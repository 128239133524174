import {
  CHANGE_STEP,
  CHANGE_CALENDAR_FOCUS,
  CHANGE_SELECTED_DATE,
  CHANGE_SLIDER_VALUE,
  CHANGE_SELL_INDIVIDUALLY_OPTION,
  CHANGE_PRICING_POLICY,
  CHANGE_BANK_ACCOUNT_NUMBER,
  CHANGE_ACCEPT_TERMS,
  CHANGE_BOUNDARY_VALUE,
  EMPTY_TICKETS_IN_SELL,
  REMOVE_TICKET_TO_SELL,
  START_TICKET_SELL_REQUEST,
  TICKET_SELL_FAILURE,
  TICKET_SELL_SUCCESS
} from '../actionTypes'
import moment from 'moment'
import IBAN from 'iban'

export default function ticketSaleSteps(
  state = {
    //defaults to first step
    currentPhase: 0,
    selectedDate: null,
    datePickerFocused: false,
    sellPrice: null,
    //defaults to flat
    pricingPolicy: 0,
    sellIndividually: true,
    bankAccountNumber: '',
    bankAccountValid: false,
    termsAccepted: false,
    isTicketSellFetching: false,
    submitError: false,
    ticketCode: '',
    ticketsToSell: {},
    boundary: 0
  },
  action
) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      // persistor persists moment objects as strings
      // parse date string back to moment object
      if (action.payload && action.payload.ticketSaleSteps.selectedDate) {
        moment.locale(action.payload.users.locale)

        return {
          ...action.payload.ticketSaleSteps,
          selectedDate: moment(action.payload.ticketSaleSteps.selectedDate),
          isTicketSellFetching: false,
          submitError: false
        }
      }

      return state
    case CHANGE_STEP:
      return {
        ...state,
        currentPhase: action.step
      }
    case CHANGE_CALENDAR_FOCUS:
      return {
        ...state,
        ticketsToSell: {
          ...state.ticketsToSell,
          [action.ticketCode]: {
            ...state.ticketsToSell[action.ticketCode],
            datePickerFocused: action.focused
          }
        }
      }
    case CHANGE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: moment(action.date).format('YYYY-MM-DD'),
        ticketsToSell: {
          ...state.ticketsToSell,
          [action.ticketCode]: {
            ...state.ticketsToSell[action.ticketCode],
            selectedDate: moment(action.date).format('YYYY-MM-DD')
          }
        }
      }
    case CHANGE_SLIDER_VALUE:
      return {
        ...state,
        sellPrice: action.value,
        ticketsToSell: {
          ...state.ticketsToSell,
          [action.ticketCode]: {
            ...state.ticketsToSell[action.ticketCode],
            sellPrice: action.value
          }
        }
      }
    case CHANGE_BOUNDARY_VALUE:
      return {
        ...state,
        ticketsToSell: {
          ...state.ticketsToSell,
          [action.ticketCode]: {
            ...state.ticketsToSell[action.ticketCode],
            boundary: action.boundary
          }
        },
        boundary: action.boundary
      }
    case CHANGE_SELL_INDIVIDUALLY_OPTION:
      return {
        ...state,
        sellIndividually: action.value
      }
    case CHANGE_PRICING_POLICY:
      return {
        ...state,
        pricingPolicy: action.policy,
        ticketsToSell: {
          ...state.ticketsToSell,
          [action.ticketCode]: {
            ...state.ticketsToSell[action.ticketCode],
            pricingPolicy: action.policy
          }
        }
      }
    case CHANGE_BANK_ACCOUNT_NUMBER:
      const IBANvalidity = action.iban && action.iban !== "" ? IBAN.isValid(action.iban) : false

      return {
        ...state,
        bankAccountNumber: action.iban,
        bankAccountValid: IBANvalidity
      }
    case CHANGE_ACCEPT_TERMS:
      return {
        ...state,
        termsAccepted: action.value
      }
    case EMPTY_TICKETS_IN_SELL:
      return {
        ...state,
        ticketsToSell: {},
        sellPrice: null,
        pricingPolicy: 0,
        selectedDate: null,
        termsAccepted: false
      }
    case START_TICKET_SELL_REQUEST:
      return {
        ...state,
        isTicketSellFetching: true,
        submitError: false
      }
    case REMOVE_TICKET_TO_SELL:
      return {
        ...state
      }
    case TICKET_SELL_FAILURE:
      return {
        ...state,
        isTicketSellFetching: false,
        submitError: action.error
      }
    case TICKET_SELL_SUCCESS:
      return {
        ...state,
        isTicketSellFetching: false,
        submitError: false,
        currentPhase: 0
      }
    default:
      return state
  }
}
