import React, { Fragment } from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import { changeAcceptTerms } from '../../actions/ticket-sale-steps'

const TicketAcceptTermsComp = ({
  dispatch,
  visible,
  termsAccepted,
  submitError
}) => {
  const handleTermsAccept = e => {
    dispatch(changeAcceptTerms(e.target.checked))
  }

  const visibleContent = (
    <div className="ticket-item-container-holder">
      <div className="ticket-item-container">
        <h3 className="orange-title">
          <Translate value="general.accept_terms_title" />
        </h3>

        <label>
          <input
            type="checkbox"
            onChange={handleTermsAccept}
            checked={termsAccepted}
          />
          {`${I18n.t('general.accept_terms')} `}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/termsofpurchaseandsale"
          >
            {I18n.t('general.accept_terms_title')}
          </a>
          *
        </label>

        <div className="break-sm" />

        {submitError && (
          <Fragment>
            <div className="error-message">{submitError}</div>
            <div className="break-md" />
          </Fragment>
        )}
      </div>
    </div>
  )

  return <Fragment>{visible && visibleContent}</Fragment>
}

class TicketAcceptTerms extends React.Component {
  componentDidMount() {
    this.props.dispatch(changeAcceptTerms(false))
  }

  render() {
    return <TicketAcceptTermsComp {...this.props} />
  }
}

export default TicketAcceptTerms
