import { INVOKE_NOTIFICATION, HIDE_NOTIFICATION } from '../actionTypes'

export default function notification(
  state = {
    params: {},
    show: false
  },
  action
) {
  switch (action.type) {
    case INVOKE_NOTIFICATION:
      return {
        ...state,
        params: action.params,
        show: true
      }
    case HIDE_NOTIFICATION:
      return {
        ...state,
        header: '',
        message: '',
        status: undefined,
        show: false
      }
    default:
      return state
  }
}
