import React from 'react'
import NewestTicketItem from '../components/newest-ticket-item/newest-ticket-item'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { push } from 'connected-react-router'
import GlobaLoadingIndicator from '../components/global-loading-indicator'

class NewestTickets extends React.Component {
  eventImage = eventInfo => {
    const events = this.props.events
    if (eventInfo) {
      if (eventInfo.image) {
        return eventInfo.image
      } else {
        for (let parentEvent of events) {
          const imageEventId =
            eventInfo.parent_id === 0
              ? eventInfo.id
              : eventInfo.parent_id
          if (parentEvent?.id && parentEvent.id === imageEventId && parentEvent.image) {
            return parentEvent.image
          }
        }
      }
    }
  }

  render() {
    const { dispatch, newestTicketsInfos } = this.props

    const renderNewest = () => {
      return Object.values(newestTicketsInfos.events).map((event) => {
        const tickets = newestTicketsInfos.tickets.filter(
          ticket =>
            ticket.event.id === event.id || ticket.event.parent_id === event.id
        )
        const numberOfTickets = tickets.length
        const eventId =
          !event.parent_id ? event.id : event.parent_id
        event.image = this.eventImage(event)
        const newestTickets = { event: event, tickets: tickets }

        return (
          <NewestTicketItem
            key={`newestTicketEvent-${event.id}`}
            dispatch={dispatch}
            onClick={() => dispatch(push('/events/' + event.id, newestTickets))}
            onEventClick={() => dispatch(push('/events/' + eventId))}
            eventName={event.name}
            numberOfTickets={numberOfTickets}
            eventInfo={event}
          />
        )
      })
    }

    return (
      <div className="newest-tickets-container">
        <div className="layout-container-undo">
          <div className="newest-tickets-holder">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="newest-tickets-title col-margin-left">
                  <Translate value="tickets.newest_tickets" />
                </h2>
                <div className="row newest-tickets-titles">
                  <div className="col-sm-12 col-md-2" />
                  <div className="col-sm-4">
                    <Translate value="events.event" />
                  </div>
                  <div className="col-sm-2">
                    <Translate value="tickets.date" />
                  </div>
                  <div className="col-sm-1">
                    <Translate value="tickets.amount" />
                  </div>
                </div>
                {newestTicketsInfos && renderNewest()}
                {!newestTicketsInfos && <GlobaLoadingIndicator center />}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  newestTicketsInfos: state.tickets.newest,
  events: state.events.events
})

export default connect(mapStateToProps)(NewestTickets)
