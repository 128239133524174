import * as API from '../utility/api'
import { setBasket, setError, clearErrors } from '../actions/payment'
import { toggleModal } from '../actions/modal'
import {
  ADD_EMAIL_TO_BASKET,
  ADD_TICKET_TO_BASKET,
  REMOVE_TICKET_FROM_LISTS,
  ADD_END_TIME,
  ADD_TIMER,
  ADD_TIME_LEFT,
  RESET_TIMERS,
  REMOVE_TICKET_FROM_BASKET,
  KILL_BASKET,
  START_FETCH,
  STOP_FETCH,
  KILL_FETCHES,
  RESERVE_FAILURE,
  UNRESERVE_SUCCESS,
  ADD_ERROR,
  SET_NAMED_INFOS,
  SET_VALID_EVENT
} from '../actionTypes'

export const paymentSuccessToServer = params => {
  return dispatch => {
    API.POST('payment', {
      ...params
    })
      .then(({ data }) => {
        dispatch(setBasket(data.basket))
        dispatch(clearErrors())
        return Promise.resolve()
      })
      .catch(e => {
        let errorMessage = 'something_wrong'
        if(e && e.response) {
          if(typeof e.response.data === 'string') {
            errorMessage = e.response.data
          } else if(e.response.data.message) {
            errorMessage = e.response.data.message
          }
        }

        dispatch(setError('payment.' + errorMessage))
        return Promise.reject()
      })
  }
}

export const addTicketToBasket = (eventInfo, ticket) => {
  return (dispatch, getState) => {
    const loggedIn = getState().users.signedIn

    if (!loggedIn) {
      dispatch(toggleModal(true))
      return
    }

    //first we add ticket to basket, then send a reserve request to backend, then move to shopping basket view
    const reserveBody = {
      ticket_id: ticket.id,
      sale_start_date: ticket.sale_start_date,
      action: 'reserve'
    }

    dispatch({
      type: ADD_TICKET_TO_BASKET,
      ticket,
      eventInfo
    })

    dispatch({
      type: REMOVE_TICKET_FROM_LISTS,
      ticketId: ticket.id,
      eventInfo
    })

    API.POST('tickets/reserve', reserveBody)
      .then(response => {
        dispatch(addEndTime(response.data.end_time))
      })
      .catch(() => {
        API.apiNotification({
          value: 'notifications.reserve_failure',
          status: 'error'
        })
        dispatch(reserveFailure())
        dispatch(removeTicketFromBasket(ticket.id, false))
      })
  }
}

export const addEndTime = end_time => {
  return {
    type: ADD_END_TIME,
    end_time
  }
}

export const addTimer = timer => {
  return {
    type: ADD_TIMER,
    timer
  }
}

export const addTimeLeft = time_left => {
  return {
    type: ADD_TIME_LEFT,
    time_left
  }
}

export const resetTimers = () => {
  return {
    type: RESET_TIMERS
  }
}

export const addEmailToBasket = email => {
  return {
    type: ADD_EMAIL_TO_BASKET,
    email
  }
}

export const removeTicketFromBasket = (id, unreserve) => {
  return dispatch => {
    const unReserveBody = {
      ticket_id: id,
      action: 'unreserve'
    }

    dispatch({ type: REMOVE_TICKET_FROM_BASKET, id })

    if (unreserve) API.POST('tickets/reserve', unReserveBody)
  }
}

export const killBasket = (unreserve = true) => {
  return (dispatch, getState) => {
    const basketTickets = getState().shoppingBasket.ticketList

    basketTickets.forEach(ticket => {
      dispatch(removeTicketFromBasket(ticket.id, unreserve))
    })

    dispatch({
      type: KILL_BASKET
    })
  }
}

export const startFetch = ticketId => {
  return {
    type: START_FETCH,
    ticketId
  }
}

export const stopFetch = ticketId => {
  return dispatch => {
    dispatch({
      type: STOP_FETCH,
      ticketId
    })
  }
}

export const killFetches = () => {
  return (dispatch, getState) => {
    const fetches = getState().shoppingBasket.ticketsFetching

    fetches.forEach(fetch => {
      dispatch(stopFetch(fetch))
    })
    dispatch({
      type: KILL_FETCHES
    })
  }
}

const reserveFailure = () => {
  return {
    type: RESERVE_FAILURE
  }
}

export const unReserveSuccess = body => {
  return {
    type: UNRESERVE_SUCCESS,
    body
  }
}

export const addError = errors => {
  return {
    type: ADD_ERROR,
    payload: { errors }
  }
}

export const setNamedInfos = info => {
  return dispatch => {
    dispatch({ type: SET_NAMED_INFOS, info })
  }
}

export const setValidEvent = (value, event) => {
  return dispatch => {
    dispatch({ type: SET_VALID_EVENT, value, event })
  }
}
