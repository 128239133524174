import React, { useState } from 'react'
import InfoRow from './info-row'
import RepresentMoney from '../../utility/represent-money'
import * as API from '../../utility/api'
import moment from 'moment'

const TICKETS_URL = 'admin/tickets'

const TicketRow = ({ ticket, basket }) => {
  const {
    category,
    event,
    original_code,
    original_price,
    price,
    sale_start_date,
    last_sell_date,
    section,
    row_name,
    seat_name,
    id,
    status,
    new_code,
    extra,
    seller,
    deleted_at,
    sold_at,
    updated_at
  } = ticket
  const comment = extra && extra.comment ? extra.comment : ''
  const [loading, setLoading] = useState('')
  const [commentsText, setCommentsText] = useState(comment)
  const [ticketStatus, setTicketStatus] = useState(status)
  const [deleted, setDeleted] = useState(false)

  const deleteTicket = () => {
    const confirm = window.confirm('Tahdotko todella poistaa lipun?')

    if (!confirm) return

    setLoading('delete')
    API.DELETE(TICKETS_URL+"/"+id)
      .then(() => {
        setLoading('')
        setDeleted(true)
      })
      .catch(e => {
        console.log({ e })
      })
  }

  const expireTicket = () => {
    const confirm = window.confirm('Tahdotko todella poistaa lipun myynnistä?')

    if (!confirm) return

    setLoading('expire')
    API.PUT(TICKETS_URL+"/"+id+"/expire")
      .then(() => {
        setLoading('')
        setDeleted(true)
      })
      .catch(e => {
        console.log({ e })
      })
  }

  const updateTicket = () => {
    setLoading('update')

    API.PUT(TICKETS_URL+"/"+id, {
      ticket: id,
      comments: commentsText,
      status: ticketStatus,
      basket
    })
      .then((response) => {
        setLoading('')
        console.log('updateTicket response', response);
        if (response.data?.message) {
          API.apiNotification({
            value: response.data.message,
            status: response.status !== 200 ? 'error' : 'success'
          })
        }
      })
      .catch(e => {
        console.log({ e })
      })
  }

  const statusDropdown = (
    <select
      value={ticketStatus}
      onChange={e => setTicketStatus(e.target.value)}
    >
      <option value="REJECTED" disabled="disabled">Hylätty</option>
      <option value="FOR_SALE">Myynnissä</option>
      <option value="SOLD">Myyty</option>
      <option value="FROZEN">Jäädytetty</option>
      <option value="EXPIRED" disabled="disabled">Vanhentunut</option>
      <option value="DELETED" disabled="disabled">Poistettu</option>
      <option value="FAILED" disabled="disabled">Prosessiontivirhe</option>
    </select>
  )

    const statusTime = () => {
      if (status === 'SOLD') {
        return sold_at ? moment(sold_at).format('DD.MM.YYYY HH:mm') : '-'
      }
      if (status === 'DELETED') {
        return deleted_at ? moment(deleted_at).format('DD.MM.YYYY HH:mm') : '-'
      }
      return updated_at ? moment(updated_at).format('DD.MM.YYYY HH:mm') : '-'
    }

  return (
    <div className={`basket-management-ticket-row ${deleted || status === 'DELETED' ? 'basket-management-ticket-row-deleted' : ''}`}>
      <InfoRow
        first={['kategoria', category?.name || '-']}
        second={['tapahtuma ID', event ? event.id : 'Ei tiedossa']}
        third={['tapahtuman nimi', event ? event.name : 'Ei tiedossa']}
      />
      <InfoRow
        first={[
          'alkuperäinen hinta',
          <RepresentMoney sum={original_price} eurSuffix />
        ]}
        second={[
          'Jälleenmyyntihinta',
          <RepresentMoney sum={price} eurSuffix />
        ]}
        third={['Myyjä', seller?.first_name ? `${seller?.first_name} ${seller?.last_name}, ${seller?.email}`  : 'Tuntematon']}
      />
      <InfoRow
        first={['alkuperäinen koodi', original_code]}
        second={['uusi koodi', new_code || '-']}
        third={['ID', id]}
      />
      <InfoRow
        first={['myyntiinlaittopäivä', moment(sale_start_date).format('DD.MM.YYYY')]}
        second={['lohko', section?.name || '-']}
        third={[
          'istumapaikka',
          row_name && seat_name
            ? `rivi: ${row_name}, paikka: ${seat_name}`
            : '-'
        ]}
      />
      <InfoRow
        first={['myyntipäättyypäivä', moment(last_sell_date).format('DD.MM.YYYY')]}
        second={['Lipun status', statusDropdown]}
        third={['Status aika', statusTime()]}
      />

      <div className="break-sm" />

      <div>
        <textarea
          style={{ width: '90%', marginLeft: '15px' }}
          type="text"
          value={commentsText}
          onChange={e => setCommentsText(e.target.value)}
        />
      </div>

      <div style={{ padding: '15px' }}>
        <button
          className="basket-management--ticket-row--button"
          onClick={updateTicket}
        >
          {loading === 'update' ? 'Tallennetaan...' : 'Tallenna muutokset'}
        </button>

        {/* <button className="basket-management--ticket-row--button">
          Tulosta PDF uudestaan
        </button> */}

        <button
          className="basket-management--ticket-row--button"
          onClick={expireTicket}
        >
          {loading === 'expire' ? 'Korjaataan poistoa...' : 'Korjaa lipun poisto'}
        </button>

        <button
          className="basket-management--ticket-row--button"
          onClick={deleteTicket}
        >
          {loading === 'delete' ? 'Poistetaan...' : 'Poista lippu'}
        </button>
      </div>
    </div>
  )
}

export default TicketRow
