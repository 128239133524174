import users from './users'
import tickets from './tickets'
import events from './events'
import userPageUI from './user-page-ui'
import notification from './global-notification'
import ticketSaleSteps from './tickets-sale-steps'
import shoppingBasket from './shopping-basket'
import payment from './payment'
import admin from './admin'
import modal from './modal'

import search, * as fromSearch from './search'

const obj = {
  payment,
  users,
  notification,
  tickets,
  events,
  userPageUI,
  ticketSaleSteps,
  shoppingBasket,
  search,
  admin,
  modal
}

export default obj

export const filteredAndSortedEvents = state => fromSearch.finalSelector(state)
export const mostViewedEvents = state => fromSearch.mostViewedSelector(state)
