export const START_EVENTS_FETCH = 'START_EVENTS_FETCH'
export const EVENTS_FETCH_SUCCESS = 'EVENTS_FETCH_SUCCESS'
export const EVENT_IDS_AND_NAMES = 'EVENT_IDS_AND_NAMES'
export const START_VALIDATION_FETCH = 'START_VALIDATION_FETCH'
export const FETCH_EVENT_INFO = 'FETCH_EVENT_INFO'
export const ADD_TICKET_TO_SELL = 'ADD_TICKET_TO_SELL'
export const SET_EVENT_INFO = 'SET_EVENT_INFO'
export const DELETE_TICKET = 'DELETE_TICKET'
export const TICKET_CODE_FOUND_SUCCESS = 'TICKET_CODE_FOUND_SUCCESS'
export const TICKET_CODE_NOT_FOUND_FAILURE = 'TICKET_CODE_NOT_FOUND_FAILURE'
export const TICKET_CODE_CHANGED = 'TICKET_CODE_CHANGED'
export const TICKET_CODE_IS_VALID = 'TICKET_CODE_IS_VALID'
export const TICKET_CODE_IS_INVALID = 'TICKET_CODE_IS_INVALID'
export const SET_CATEGORIES_COUNTER = 'SET_CATEGORIES_COUNTER'
export const EVENT_INFO_FETCH_SUCCESS = 'EVENT_INFO_FETCH_SUCCESS'
export const EVENT_INFO_FETCH_FAIL = 'EVENT_INFO_FETCH_FAIL'
export const START_EVENT_INFO_FETCH = 'START_EVENT_INFO_FETCH'
export const CHANGE_SELECTED_EVENT = 'CHANGE_SELECTED_EVENT'
export const SELECT_PREVIOUS_EVENT = 'SELECT_PREVIOUS_EVENT'
export const TOGGLE_MAINPAGE_DROPDOWN_HOVER = 'TOGGLE_MAINPAGE_DROPDOWN_HOVER'
export const CHANGE_ADMIN_TAB = 'CHANGE_ADMIN_TAB'
export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION'
export const SET_MODIFIED_TRANSLATIONS = 'SET_MODIFIED_TRANSLATIONS'
export const ADD_FAQ_ROW = 'ADD_FAQ_ROW'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_DESIRED_PATH = 'SET_DESIRED_PATH'
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS'
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION'
export const SET_FORCED_LOGIN_FROM = 'SET_FORCED_LOGIN_FROM'
export const CHANGE_BANK_ACCOUNT_NUMBER = 'CHANGE_BANK_ACCOUNT_NUMBER'
export const DELETE_TICKET_FROM_SELL = 'DELETE_TICKET_FROM_SELL'
export const INITIATE_TICKET_UPDATE = 'INITIATE_TICKET_UPDATE'
export const TICKET_UPDATE_SUCCESS = 'TICKET_UPDATE_SUCCESS'
export const LOAD_USER_PDF_DATA = 'LOAD_PDF_DATA'
export const START_USER_HISTORY_FETCH = 'START_USER_HISTORY_FETCH'
export const USER_HISTORY_FETCH = 'USER_HISTORY_FETCH'
export const STOP_USER_INFO_UPDATE = 'STOP_USER_INFO_UPDATE'
export const START_USER_INFO_UPDATE = 'START_USER_INFO_UPDATE'
export const USER_INFO_UPDATING = 'USER_INFO_UPDATING'
export const UPDATE_USER_INFOS = 'UPDATE_USER_INFOS'
export const INITIATE_PASSWORD_RECOVERY = 'INITIATE_PASSWORD_RECOVERY'
export const INITIATE_RECOVERY_SENT = 'INITIATE_RECOVERY_SENT'
export const CONFIRM_PASSWORD_RECOVERY = 'CONFIRM_PASSWORD_RECOVERY'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const USER_SUBSCRIPTIONS_FETCH = 'USER_SUBSCRIPTIONS_FETCH'
export const SIGN_OUT = 'SIGN_OUT'
export const START_FETCH = 'START_FETCH'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'
export const CHANGE_VIEW = 'CHANGE_VIEW'
export const REFRESH_TOKENS = 'REFRESH_TOKENS'
export const INITIATE_VERIFICATION = 'INITIATE_VERIFICATION'
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE'
export const CLEAR_SEARCH_EVENTS = 'CLEAR_SEARCH_EVENTS'
export const EVENT_SEARCH_FETCH_SUCCESS = 'EVENT_SEARCH_FETCH_SUCCESS'
export const EVENT_SEARCH_FETCH_FAILURE = 'EVENT_SEARCH_FETCH_FAILURE'
export const ADD_SEARCH_CATEGORY = 'ADD_SEARCH_CATEGORY'
export const ADD_SEARCH_DATES = 'ADD_SEARCH_DATES'
export const EMPTY_FILTERS = 'EMPTY_FILTERS'
export const SET_BASKET = 'SET_BASKET'
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CHANGE_TAB = 'CHANGE_TAB'
export const REMOVE_TICKET_FROM_LISTS = 'REMOVE_TICKET_FROM_LISTS'
export const ADD_TICKET_TO_BASKET = 'ADD_TICKET_TO_BASKET'
export const RESERVE_SUCCESS = 'RESERVE_SUCCESS'
export const ADD_END_TIME = 'ADD_END_TIME'
export const ADD_TIMER = 'ADD_TIMER'
export const ADD_TIME_LEFT = 'ADD_TIME_LEFT'
export const RESET_TIMERS = 'RESET_TIMERS'
export const ADD_EMAIL_TO_BASKET = 'ADD_EMAIL_TO_BASKET'
export const REMOVE_TICKET_FROM_BASKET = 'REMOVE_TICKET_FROM_BASKET'
export const KILL_BASKET = 'KILL_BASKET'
export const STOP_FETCH = 'STOP_FETCH'
export const KILL_FETCHES = 'KILL_FETCHES'
export const RESERVE_FAILURE = 'RESERVE_FAILURE'
export const UNRESERVE_SUCCESS = 'UNRESERVE_SUCCESS'
export const ADD_ERROR = 'ADD_ERROR'
export const SET_NAMED_INFOS = 'SET_NAMED_INFOS'
export const SET_VALID_EVENT = 'SET_VALID_EVENT'
export const EMPTY_TICKETS_IN_SELL = 'EMPTY_TICKETS_IN_SELL'
export const KILL_SALES = 'KILL_SALES'
export const CHANGE_STEP = 'CHANGE_STEP'
export const CHANGE_SELECTED_DATE = 'CHANGE_SELECTED_DATE'
export const CHANGE_CALENDAR_FOCUS = 'CHANGE_CALENDAR_FOCUS'
export const CHANGE_SLIDER_VALUE = 'CHANGE_SLIDER_VALUE'
export const CHANGE_BOUNDARY_VALUE = 'CHANGE_BOUNDARY_VALUE'
export const CHANGE_SELL_INDIVIDUALLY_OPTION = 'CHANGE_SELL_INDIVIDUALLY_OPTION'
export const CHANGE_PRICING_POLICY = 'CHANGE_PRICING_POLICY'
export const CHANGE_ACCEPT_TERMS = 'CHANGE_ACCEPT_TERMS_STATUS'
export const REMOVE_TICKET_TO_SELL = 'REMOVE_TICKET_TO_SELL'
export const START_TICKET_SELL_REQUEST = 'START_TICKET_SELL_REQUEST'
export const TICKET_SELL_SUCCESS = 'TICKET_SELL_SUCCESS'
export const TICKET_SELL_FAILURE = 'TICKET_SELL_FAILURE'
export const SET_TICKETS_TO_SALE = 'SET_TICKETS_TO_SALE'
export const INVOKE_NOTIFICATION = 'INVOKE_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SET_MAP = 'SET_MAP'
