import React, { useState, Fragment, useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import placeholder from '../images/placeholder.png'
import TicketSellItem from '../components/ticket-sell/ticket-sell-item'
import RepresentMoney from '../utility/represent-money'
import { setNamedInfos } from '../actions/shopping-basket'
import { Translate } from 'react-redux-i18n'

const BasketEventContainer = (props) => {
  const { tickets, image } = props
  const [namedTicketInfo, setNamedTicketInfo] = useState({})

  const ticketList = tickets.map((ticket) => (
    <BasketEventTicketRow
      {...props}
      ticket={ticket}
      namedTicketInfo={namedTicketInfo}
      setNamedTicketInfo={setNamedTicketInfo}
    />
  ))

  return (
    <div className="basket-event-container">
      <div className="row">
        <div className="col-sm-4 col-md-3">
          <img
            src={image || placeholder}
            alt="event header"
            className="basket-image"
          />
        </div>

        <div className="col-sm-8 col-md-9">
          <div className="row">{ticketList}</div>
        </div>
      </div>
    </div>
  )
}

const BasketEventTicketRow = ({
  valid,
  dispatch,
  removeTicketFromBasket,
  errors,
  time_info,
  start_date,
  namedTicketInfo,
  setNamedTicketInfo,
  ticket
}) => {
  const [submitted, setSubmitted] = useState(false)
  const {
    row_name,
    seat_name,
    id,
    name,
    price,
    category,
    section,
    named_ticket,
    phone_ticket,
    email_ticket,
    address_ticket
  } = ticket

  console.log(ticket)

  useEffect(() => {
    const validator = () => {
      if (
        (!named_ticket || named_ticket === '0') &&
        (!email_ticket || email_ticket === '0') &&
        (!address_ticket || address_ticket === '0') &&
        (!phone_ticket || phone_ticket === '0')
      ) {
        return true
      }

      return submitted
    }

    const validated = validator()

    valid(validated, id)
  }, [
    submitted,
    id,
    named_ticket,
    email_ticket,
    phone_ticket,
    address_ticket,
    valid
  ])

  const seatString =
    row_name && seat_name
      ? `${I18n.t('basket.row')} ${row_name}, ${I18n.t(
          'basket.seat'
        )} ${seat_name}`
      : null

  const date = () => {
    return (
      <div>
        {start_date && moment(start_date).format('D.M.YYYY')}{' '}
        {!!time_info && <small>{time_info}</small>}
      </div>
    )
  }

  const namedTicketForm = () => {
    return (
      <Fragment>
        <TicketSellItem.Break size="md" />
        <TicketSellItem.Column
          width={12}
          content={
            <div className="bold">
              <Translate value="shopping_basket.named_ticket_info" />
            </div>
          }
        />
        <TicketSellItem.Column width={12} content={namedTicketInfoForm} />
      </Fragment>
    )
  }

  const handleChange = (id, target, value) => {
    setSubmitted(false)
    const newInfo = {
      ...namedTicketInfo,
      [id]: { ...namedTicketInfo[id], [target]: value }
    }

    setNamedTicketInfo(newInfo)
  }

  const handleTicketInfoSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    dispatch(setNamedInfos(namedTicketInfo))
  }

  const namedTicketInfoForm = (
    <form onSubmit={handleTicketInfoSubmit}>
      <div className="row">
        <div className="col-sm-10">
          <Translate value="shopping_basket.named_ticket_event_box_tutorial" />
        </div>
      </div>

      <div className="break-sm" />

      <div className="row">
        {named_ticket === 1 && (
          <Fragment>
            <div className="col-sm-5">
              <label
                style={{ display: 'flex', flexDirection: 'column' }}
                className=""
              >
                <Translate value="registration.given_name" />
                <input
                  className="user-info"
                  required
                  type="text"
                  value={
                    (namedTicketInfo[id] &&
                      namedTicketInfo[id].named_first_name) ||
                    ''
                  }
                  onChange={(e) =>
                    handleChange(id, 'named_first_name', e.target.value)
                  }
                />
              </label>
            </div>

            <div className="col-sm-5">
              <label
                style={{ display: 'flex', flexDirection: 'column' }}
                className=""
              >
                <Translate value="registration.family_name" />
                <input
                  className="user-info"
                  required
                  type="text"
                  value={
                    (namedTicketInfo[id] &&
                      namedTicketInfo[id].named_last_name) ||
                    ''
                  }
                  onChange={(e) =>
                    handleChange(id, 'named_last_name', e.target.value)
                  }
                />
              </label>
            </div>
          </Fragment>
        )}

        {email_ticket === 1 && (
          <div className="col-sm-5">
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              <Translate value="registration.email" />
              <input
                required
                className="user-info"
                type="email"
                value={
                  (namedTicketInfo[id] && namedTicketInfo[id].named_email) || ''
                }
                onChange={(e) =>
                  handleChange(id, 'named_email', e.target.value)
                }
              />
            </label>
          </div>
        )}

        {phone_ticket === 1 && (
          <div className="col-sm-5">
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              <Translate value="user.phone_number" />
              <input
                required
                type="number"
                className="user-info"
                value={
                  (namedTicketInfo[id] && namedTicketInfo[id].named_phone) || ''
                }
                onChange={(e) =>
                  handleChange(id, 'named_phone', e.target.value)
                }
              />
            </label>
          </div>
        )}

        {address_ticket === 1 && (
          <div className="col-sm-5">
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              <Translate value="user.address" />
              <input
                required
                className="user-info"
                value={
                  (namedTicketInfo[id] && namedTicketInfo[id].named_address) ||
                  ''
                }
                onChange={(e) =>
                  handleChange(id, 'named_address', e.target.value)
                }
              />
            </label>
          </div>
        )}

        {address_ticket === 1 && (
          <div className="col-sm-5">
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              <Translate value="user.postcode" />
              <input
                required
                className="user-info"
                value={
                  (namedTicketInfo[id] && namedTicketInfo[id].named_postcode) ||
                  ''
                }
                onChange={(e) =>
                  handleChange(id, 'named_postcode', e.target.value)
                }
              />
            </label>
          </div>
        )}

        {address_ticket === 1 && (
          <div className="col-sm-5">
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              <Translate value="user.city" />
              <input
                required
                className="user-info"
                value={
                  (namedTicketInfo[id] && namedTicketInfo[id].named_city) || ''
                }
                onChange={(e) => handleChange(id, 'named_city', e.target.value)}
              />
            </label>
          </div>
        )}

        {address_ticket === 1 && (
          <div className="col-sm-5">
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              <Translate value="user.country" />
              <input
                required
                className="user-info"
                value={
                  (namedTicketInfo[id] && namedTicketInfo[id].named_country) ||
                  ''
                }
                onChange={(e) =>
                  handleChange(id, 'named_country', e.target.value)
                }
              />
            </label>
          </div>
        )}
      </div>

      <div className="break-md" />

      {submitted && (
        <i className="fa-regular fa-circle-info" size={'3x'} color="green" />
      )}
      {!submitted && (
        <button type="submit">
          <Translate value="shopping_basket.set_named_infos" />
        </button>
      )}
    </form>
  )

  return (
    <React.Fragment key={`basket-ticket-${id}`}>
      <TicketSellItem deleteMe={removeTicketFromBasket(id)}>
        <TicketSellItem.Column
          width={10}
          content={<div className="bold">{name}</div>}
        />
        <TicketSellItem.Column
          width={2}
          content={<RepresentMoney sum={price} eurSuffix />}
        />

        <TicketSellItem.Column width={12} content={date()} />
        <TicketSellItem.Column width={12} content={category && category.name} />
        <TicketSellItem.Column
          width={12}
          content={<small>{section && section.name}</small>}
        />

        {seatString && (
          <TicketSellItem.Column width={12} content={seatString} />
        )}

        {(email_ticket || named_ticket || phone_ticket || address_ticket) &&
          namedTicketForm()}

        {errors && errors.includes(id) && (
          <TicketSellItem.Column
            width={12}
            content={`${I18n.t('basket.invalid_ticket')}!`}
            error
          />
        )}
      </TicketSellItem>
      <div className="break-sm" />
    </React.Fragment>
  )
}

export default BasketEventContainer
