import React from 'react'
import { connect } from 'react-redux'
import Markdown from 'react-markdown'

const Terms = ({ translations, locale, title }) => {
  if (!translations) return <div />

  const terms = translations[locale].terms[title]

  const titles = Object.keys(terms).filter(key => key.slice(0, 1) === 't')

  const mapContents = titles.map(titleKey => {
    const contentKey = titleKey.replace('t', 'c')

    const content = terms[contentKey]
    const title = terms[titleKey]

    return (
      <React.Fragment key={title}>
        <div className="bold">{title}</div>

        <div className="break-md" />

        <div>
          <Markdown source={content} />
        </div>

        <div className="break-lg" />
      </React.Fragment>
    )
  })

  return (
    <div className="row">
      <div className="break-lg" />

      <div className="col-sm-8 col-sm-offset-2">
        <div className="bigbold">
          {translations[locale].terms[title].maintitle}
        </div>

        <div className="break-lg" />

        {mapContents}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  translations: state.i18n.translations,
  locale: state.i18n.locale
})

export default connect(mapStateToProps)(Terms)
