import React from 'react'
import { connect } from 'react-redux'
import {
  getOneEventInfo,
  changeSelectedEvent,
  selectPreviousEvent
} from '../actions/events'
import { killFetches } from '../actions/shopping-basket'
import SubEventList from '../components/event/event-subevent-list'
import EventInfo from '../components/event/event-info'
import EventMenu from '../components/event/event-menu'
import EventDescription from '../components/event/event-description'
import BuyableTicketList from '../components/event-buyable-ticket/event-buyable-ticket-list'
import MiniSearchbar from '../components/mini-searchbar/mini-searchbar'
import { addTicketToBasket } from '../actions/shopping-basket'
import { push } from 'connected-react-router'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { createSubscription, deleteSubscription } from '../actions/users'
import { Translate } from 'react-redux-i18n'
import throttle from 'lodash/throttle'
import { toggleModal } from '../actions/modal'

class EventShow extends React.Component {
  state = {
    selectedAction: 'tickets'
  }

  componentDidMount = () => {
    const { dispatch, isFetching, match } = this.props
    if (!isFetching) {
      dispatch(getOneEventInfo(match.params.id))
    }
  }

  componentWillUnmount = () => {
    this.props.dispatch(killFetches())
  }

  componentWillReceiveProps = nextProps => {
    const { dispatch } = this.props

    if (this.props.previousEvent) {
      if (
        this.eventParentType() !== 'PARENT' &&
        nextProps.match.params.id === this.props.previousEvent.event.id
      ) {
        dispatch(selectPreviousEvent())
      }
    }
  }

  addToBasket = (eventInfo, dispatch) => {
    return ticket => {
      const signedIn = this.props.user && this.props.user.signedIn
      if (signedIn) {
        if (eventInfo.image === null) {
          const image = this.eventImage()
          eventInfo = { ...eventInfo, image: image }
        }
        dispatch(addTicketToBasket(eventInfo, ticket))
      } else {
        dispatch(toggleModal(true, this.props.location.pathname))
      }
    }
  }

  redirect = (path, eventInfo) => {
    const { dispatch } = this.props

    return () => {
      dispatch(push(path))
      dispatch(changeSelectedEvent(eventInfo))
    }
  }

  // if this is 'parent' - event has subevents
  // if this is 'child' - event may only have tickets and no subevents
  // if this is 'normal' - event is single and has no subevents
  eventParentType = () => this.props.eventInfo.event.parent_child_type

  eventImage = () => {
    const events = this.props.events
    const eventInfo = this.props.eventInfo && this.props.eventInfo.event
    if (eventInfo) {
      for (let parentEvent of events) {
        if (parentEvent?.id && parentEvent.id === eventInfo.parent_id && parentEvent.image) {
          return parentEvent.image
        }
      }
    }
  }

  // if event is parent show subevents
  subEventList = () => {
    return (
      <div className="row">
        <SubEventList
          eventInfos={this.props.eventInfo.child_events}
          changeEvent={this.redirect}
        />
      </div>
    )
  }

  // if event is not parent show tickets
  eventTicketlist = tickets => {
    const { eventInfo, location, ticketsFetching, userId } = this.props

    return (
      <div className="row">
        <br />
        <BuyableTicketList
          isFetching={ticketsFetching}
          tickets={tickets}
          addToBasket={this.addToBasket(
            location.state ? location.state.event : eventInfo.event,
            this.props.dispatch
          )}
          eventInfo={
            location.state ? { event: location.state.event } : eventInfo
          }
          dispatch={this.props.dispatch}
          userId={userId}
        />
      </div>
    )
  }

  descriptionText = () => {
    const events = this.props.events
    const eventInfo = this.props.eventInfo && this.props.eventInfo.event
    if (eventInfo) {
      for (let parentEvent of events) {
        if (
          parentEvent?.id && 
          parentEvent.id === eventInfo.parent_id &&
          parentEvent.description_text
        ) {
          eventInfo.description_text = parentEvent.description_text
        }
      }
    }
  }

  renderBottom = () => {
    const { selectedAction } = this.state
    const { eventInfo, location, newestTicketsInfos, locale } = this.props

    if (selectedAction === 'info') {
      this.descriptionText()
      return <EventDescription {...eventInfo.event} locale={locale} />
    } else {
      if (this.eventParentType() === 'PARENT') {
        return this.subEventList()
      }
      const tickets = location.state
        ? newestTicketsInfos.tickets.filter(
            ticket => ticket.event.id === eventInfo.event.id
          )
        : eventInfo.tickets

      if (tickets.length > 0) {
        return this.eventTicketlist(tickets)
      }

      return (
        <div className="text-center">
          <div className="break-md" />
          <Translate value="tickets.no_available_tickets" />
        </div>
      )
    }
  }

  getEventTicketsLength = () => {
    const { eventInfo } = this.props
    return eventInfo.tickets.length
  }

  handleAction = action => {
    this.setState({ selectedAction: action })
  }

  handleSetSubscription = throttle((eventId, parentType) => {
    this.props.dispatch(createSubscription(eventId, parentType))
  }, 5000)

  handleDeleteSubscription = throttle(eventId => {
    this.props.dispatch(deleteSubscription(eventId))
  }, 5000)

  render() {
    const { error, eventInfo, isFetching, previousEvent } = this.props

    return (
      <div className="section">
        {!isFetching && !error && eventInfo && (
          <div>
            <div className="row">
              <div className="col-sm-6 col-md-7 col-lg-8" />
              <div className="col-sm-6 col-md-5 col-lg-4">
                <MiniSearchbar />
              </div>
            </div>
            <div className="event-info-container">
              <EventInfo
                {...eventInfo.event}
                map={eventInfo.map}
                previousEvent={previousEvent ? previousEvent.event : null}
                events={this.props.events}
                eventInfo={eventInfo}
                handleSetSubscription={this.handleSetSubscription}
                handleDeleteSubscription={this.handleDeleteSubscription}
                userSubscriptions={this.props.userSubscriptions}
                subscriptionFetch={this.props.subscriptionFetch}
                signedIn={this.props.signedIn}
                ticketsAmount={this.getEventTicketsLength()}
              />

              <EventMenu
                infoClick={() => this.handleAction('info')}
                ticketsClick={() => this.handleAction('tickets')}
                selectedAction={this.state.selectedAction}
                dispatch={this.props.dispatch}
              />
            </div>
            {this.renderBottom()}
          </div>
        )}

        {isFetching && <GlobalLoadingIndicator center />}

        {error && <Translate value="events.not_found" />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    eventInfo: state.events.selectedEventInfo,
    isFetching: state.events.isFetchingEventInfo,
    error: state.events.eventInfoError,
    previousEvent: state.events.previousEvent,
    ticketBuyFetch: state.shoppingBasket.isFetching,
    ticketsFetching: state.shoppingBasket.ticketsFetching,
    newestTicketsInfos: state.tickets.newest,
    events: state.events.events,
    signedIn: state.users.signedIn,
    subscriptionFetch: state.users.subscriptionFetch,
    user: state.users,
    userSubscriptions: state.users.userSubscriptions,
    locale: state.i18n.locale,
    userId: state.users && state.users.userInfo && state.users.userInfo.sub
  }
}

export default connect(mapStateToProps)(EventShow)
