import React from 'react'

const TicketSellItem = props => {
  const { deleteMe } = props

  return (
    <div className="ticket-info--box--container">
      <div className="ticket-info-box">
        <div className="row text-left">{props.children}</div>

        <div
          onClick={() => {
            deleteMe()
          }}
          className="cursor-pointer ticket-delete-icon-container"
        >
          <div className="ticket-delete--centered">
            <i className="fa-solid fa-circle-xmark" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketSellItem

TicketSellItem.Column = ({ width, content, error }) => {
  return (
    <div className={`col-sm-${width} ${error ? 'error-message' : ''}`}>
      {content}
    </div>
  )
}

TicketSellItem.Break = ({ size }) => {
  return <div className={`break-${size}`} />
}
