import React from 'react'
import Slider from 'react-slick'

const EventsCarousel = props => {
  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  // generate events list in form of triples
  const SliderEventTriples = () => {
    // splice is destructive so we need to copy the array first
    const events = [...props.events]
    const eventTriples = []

    while (events.length) {
      eventTriples.push(events.splice(0, 3))
    }

    return eventTriples
  }

  const SliderContent = () => {
    const eventTriples = SliderEventTriples()
    const carouselContent = eventTriples.map(triple => (
      <div key={triple[0].key}>{triple}</div>
    ))
    return carouselContent
  }

  return (
    <div>
      <Slider {...options}>{SliderContent()}</Slider>
    </div>
  )
}

export default EventsCarousel
