import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import * as API from '../utility/api'
import GlobalLoadingIndicator from '../components/global-loading-indicator'
import { signOut } from '../actions/users'

import {
  USER_INFO_UPDATING,
} from '../actionTypes'

Modal.setAppElement('#root')

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '320px',
    maxWidth: '700px',
    padding: '10px 20px 20px 20px'
  }
}

const AdditionalInfoModal = ({ signedIn, userInfo, dispatch }) => {
  console.log('UserInfo', userInfo)
  const [open, setOpen] = useState(false)
  const [phonenumber, setPhonenumber] = useState(
    (userInfo && userInfo.phone_number) || ''
  )
  const [email, setEmail] = useState(
    (userInfo && userInfo.email) || ''
  )
  const [allowMarketing, setAllowMarketing] = useState(
    (userInfo && userInfo.allow_marketing) || false
  )
  const [shake, setShake] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(false)
  const [logout, setLogout] = useState(false)

  useEffect(() => {
    const tel = (userInfo && userInfo.phone_number) || false
    const email2 = (userInfo && userInfo.email) || false

    if (signedIn && (!tel || !email2)) {
      setOpen(true)
    }
  }, [signedIn, userInfo])

  useEffect(() => {
    const timeout = setTimeout(() => setShake(false), 820)

    return () => clearTimeout(timeout)
  }, [shake])

  const handleSubmit = e => {
    e.preventDefault()

    if (logout) {
      dispatch(signOut())
      setOpen(false)
      return
    }

    setFetching(true)

    API.PUT(`customers/${userInfo.id}`, {
      phone_number: phonenumber,
      email: email,
      allow_marketing: Boolean(allowMarketing)
    })
      .then(({ data }) => {
        const phone_number = data.customer && data.customer.phone_number
        const email = data.customer && data.customer.email
        const allow_marketing =
          data.customer && data.customer.allow_marketing
        dispatch({
          type: USER_INFO_UPDATING,
          allow_marketing,
          phone_number,
          email
        })
        setOpen(false)
      })
      .catch(e => {
        setFetching(false)
        setError(true)
        console.log(e);
      })
  }

  const validateInput = () => {
    return phonenumber !== '' && email !== ''
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShake(true)}
      style={customStyles}
      contentLabel="Login Modal"
    >
      <div className={shake ? 'shake' : ''}>
        <h1>
          <Translate value="additional_info_form.update_information" />
        </h1>

        <div>
          <Translate value="additional_info_form.info_missing" />
        </div>

        <div className="break-md" />
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="tel">
              <Translate value="user.phone_number" />
            </label>

            <input
              type="number"
              id="tel"
              value={phonenumber}
              onChange={e => {
                setPhonenumber(e.target.value)
              }}
              className="user-info"
              autoComplete="tel"
            />
          </div>
          <div className="break-lg" />
          <div>
            <label htmlFor="email">
              <Translate value="user.email" />
            </label>

            <input
              type="email"
              id="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
              className="user-info"
              autoComplete="email"
            />
          </div>
          <div className="break-lg" />
          <div className="check-terms-container">
            <label htmlFor="marketing-input">
              <input
                id="marketing-input"
                type="checkbox"
                checked={allowMarketing}
                onChange={e => {
                  setAllowMarketing(e.target.checked)
                }}
              />
              <Translate value="registration.confirm_marketing_licence" />
            </label>
          </div>

          <div className="break-lg" />

          {!fetching && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button disabled={!validateInput()} type="submit">
                  <Translate value="additional_info_form.save" />
                </button>

                <button onClick={() => setLogout(true)}>
                  <Translate value="additional_info_form.logout" />
                </button>
              </div>
            </div>
          )}

          {fetching && (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <GlobalLoadingIndicator />
            </div>
          )}

          {error && (
            <div className="error-message">
              <div className="break-md"></div>
              <div>
                <Translate value="user_page.info.user_info_update_failed" />
              </div>
            </div>
          )}
        </form>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    signedIn: state.users.signedIn,
    userInfo: state.users.userInfo
  }
}

export default connect(mapStateToProps)(AdditionalInfoModal)
