import React from 'react'


const TestPage = () => {
    return (
      <div>
        <h2>Mitä kuuluu? :)</h2>
        
      </div>
    )
  }
  





export default TestPage