import React from "react";
import { Translate } from "react-redux-i18n";
import moment from "moment";
import RepresentMoney from "../../utility/represent-money";

const HistoryTicketItem = ({
  name,
  sale_start_date,
  price,
  status,
  sell_date,
  event,
  original_code
}) => (
  <React.Fragment>
    <div className="history-ticket-container">
      <div className="row">
        <div className="col-sm-12 bold">{name}</div>

        <div className="col-sm-12">
          <Translate
            value="tickets.event_date"
            className="history-info-title"
          />
          <br />
          {moment(event?.end_date)?.format("D.M.YYYY")}
        </div>

        <div className="col-sm-12">
          <Translate
            value="tickets.original_code"
            className="history-info-title"
          />
          <br />
          {original_code}
        </div>

        <div className="col-sm-12">
          <Translate
            value="tickets.sale_start_date"
            className="history-info-title"
          />
          <br />
          {moment(sale_start_date)?.format("D.M.YYYY")}
        </div>

        <div className="col-sm-12">
          <Translate value="tickets.sell_date" className="history-info-title" />
          <br />
          {moment(sell_date)?.format("D.M.YYYY")}
        </div>

        <div className="col-sm-12">
          <Translate
            value="tickets.asking_price"
            className="history-info-title"
          />
          <br />
          <RepresentMoney sum={price} eurSuffix />
        </div>

        <div className="col-sm-12">
          <Translate
            value="tickets.asking_price_without_commission"
            className="history-info-title"
          />
          <br />
          <RepresentMoney sum={price * 0.9} eurSuffix />
        </div>
      </div>

      {status === "SOLD" && (
        <div className="history-sold">
          <Translate value="tickets.sold" />
        </div>
      )}
    </div>
    <hr />
  </React.Fragment>
);

export default HistoryTicketItem;
