import React from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import InsellTicket from './userpage-insell-ticket'
import GlobalLoadingIndicator from '../../components/global-loading-indicator'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

const INSELL = 'INSELL'
const SALE_DATE_EXPIRED = 'SALE_DATE_EXPIRED'
const EVENT_DATE_EXPIRED = 'EVENT_DATE_EXPIRED'

const Insell = ({
  submitted,
  updateTicket,
  userHistoryFetch,
  changePath,
  removeTicketFromSell,
  ticketUpdateFetching
}) => {
  const inSellTickets = status => {
    let forSaleTickets = []
    if (status === INSELL) {
      forSaleTickets = sortBy(
        submitted.filter(ticket => {
          return (
            ticket.status === 'FOR_SALE' &&
            moment(`${ticket.last_sell_date}`).isSameOrAfter(moment.now(), 'day') &&
            moment(ticket.last_sales_time).isAfter(moment.now())
          )
        }),
        ['last_sell_date']
      )
    } else if (status === SALE_DATE_EXPIRED) {
      forSaleTickets = sortBy(
        submitted.filter(
          ticket =>
            ticket.status === 'FOR_SALE' &&
            moment(`${ticket.last_sell_date}`).isBefore(moment.now(), 'day') &&
            moment(ticket.last_sales_time).isAfter(moment.now())
        ),
        ['last_sell_date']
      )
    } else if (status === EVENT_DATE_EXPIRED) {
      forSaleTickets = sortBy(
        submitted.filter(ticket =>
          moment(ticket.last_sales_time).isSameOrBefore(moment.now())
        ),
        ['last_sell_date']
      )
    }

    if (forSaleTickets.length === 0) {
      return status === INSELL ? (
        <div className="col-sm-12">
          <h3>
            <Translate value="insell.no_tickets" />
          </h3>
        </div>
      ) : (
        <div />
      )
    }

    return (
      <React.Fragment>
        <div className="col-sm-12">
          {status === INSELL && <h2>{I18n.t('insell.insell_tickets')}</h2>}
          {status === SALE_DATE_EXPIRED && (
            <h2>{I18n.t('insell.tickets_last_sale_date_expired')}</h2>
          )}
          {status === EVENT_DATE_EXPIRED && (
            <h2>{I18n.t('insell.event_date_expired')}</h2>
          )}
        </div>
        <div className="col-sm-6">
          {forSaleTickets.map(
            (ticket, i) =>
              i % 2 === 0 && (
                <React.Fragment key={ticket.id}>
                  <InsellTicket
                    ticket={ticket}
                    updateTicket={updateTicket}
                    ticketUpdateFetching={ticketUpdateFetching}
                    changePath={changePath}
                    removeTicketFromSell={removeTicketFromSell}
                    expired={status === EVENT_DATE_EXPIRED}
                  />
                </React.Fragment>
              )
          )}
        </div>
        <div className="col-sm-6">
          {forSaleTickets.map(
            (ticket, i) =>
              i % 2 !== 0 && (
                <React.Fragment key={ticket.id}>
                  <InsellTicket
                    ticket={ticket}
                    updateTicket={updateTicket}
                    ticketUpdateFetching={ticketUpdateFetching}
                    changePath={changePath}
                    removeTicketFromSell={removeTicketFromSell}
                    expired={status === EVENT_DATE_EXPIRED}
                  />
                </React.Fragment>
              )
          )}
        </div>
      </React.Fragment>
    )
  }

  if (userHistoryFetch) {
    return <GlobalLoadingIndicator center />
  }

  return (
    <React.Fragment>
      {submitted ? inSellTickets(INSELL) : <div />}
      {submitted ? inSellTickets(SALE_DATE_EXPIRED) : <div />}
      {submitted ? inSellTickets(EVENT_DATE_EXPIRED) : <div />}
    </React.Fragment>
  )
}

export default Insell
