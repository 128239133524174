import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { setCategoriesCounter } from '../actions/events'
import { push } from 'connected-react-router'
import { addSearchCategory } from '../actions/search'
import MostViewedButton from '../components/most-viewed-button'

class MostViewedContainer extends React.Component {
  componentDidMount() {
    const catCounter = this.props.categoriesCounter || []
    if (
      catCounter.categories.length === 0 ||
      catCounter.expires.isAfter(moment())
    ) {
      this.props.dispatch(setCategoriesCounter(this.countCategoriesCounter()))
    }
  }

  countCategoriesCounter = () => {
    const categoriesCounter = {}
    this.props.events.forEach(e => {
      e.categories.forEach(cat => {
        if (categoriesCounter[cat.id]) {
          categoriesCounter[cat.id].n++
        } else {
          cat.n = 1;
          categoriesCounter[cat.id] = cat
        }
      })
    })

    const ret = { expires: moment().add(1, 'day'), categories: [] }
    // eslint-disable-next-line no-unused-vars
    Object.entries(categoriesCounter).forEach(([_id, category]) => {
      ret.categories.push(category)
    })

    return ret
  }

  render() {
    const { dispatch, locale } = this.props
    const topFive = this.props.categoriesCounter.categories.sort((a, b) => {
      if (a.n > b.n) return 1;
      if (b.n > a.n) return -1;

      return 0;
    })
      .slice(-4)
      .map(e => e)

    console.log(topFive);

    const topFiveButtons = topFive
      ? topFive.map(cat => (
          <MostViewedButton
            key={cat.id}
            text={cat[`name_${locale}`] && cat[`name_${locale}`] !== "" ? cat[`name_${locale}`] : cat.name}
            onClick={() => {
              dispatch(addSearchCategory({id: cat.id, name: cat[`name_${locale}`] && cat[`name_${locale}`] !== "" ? cat[`name_${locale}`] : cat.name}, true))
              dispatch(push('/events'))
            }}
          />
        ))
      : []

    return <div>{topFiveButtons}</div>
  }
}

export default connect(state => ({
  events: state.events.events,
  categoriesCounter: state.events.categoriesCounter,
  locale: state.i18n.locale
}))(MostViewedContainer)
