import React from 'react'
import { connect } from 'react-redux'
import { changeTab } from '../actions/user-page-ui'
import { push } from 'connected-react-router'
import UserPageHistory from '../components/user-page/userpage-history'
import UserPageInfo from '../components/user-page/userpage-info'
import UserPageUpdate from '../components/user-page/userpage-update'
import Insell from '../components/user-page/userpage-insell'
import { TABS } from '../actions/user-page-ui'
import {
  fetchUserHistory,
  deleteTicketFromSell,
  updateTicket
} from '../actions/users'
import { Translate } from 'react-redux-i18n'
import moment from 'moment'

class UserPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchUserHistory(this.props.userInfo.id))
  }

  removeTicketFromSell = dispatch => {
    return ticket_id => {
      dispatch(deleteTicketFromSell(ticket_id))
    }
  }

  updateTicket = dispatch => ticket => {
    dispatch(updateTicket(ticket))
  }

  changePath = () => {
    return path => this.props.dispatch(push(path))
  }

  ticketsInSale = () => {
    try {
      const tickets = this.props.userHistory?.submitted ?? []
      // console.log(tickets)
      return tickets.filter(ticket => {
        // console.log(ticket);
        // console.log(moment(`${ticket.last_sell_date}`).isSameOrAfter(moment.now(), 'day'));
        // console.log(moment(ticket.last_sales_time).isAfter(moment.now()));
        return (
          ticket.status === 'FOR_SALE' &&
          moment(`${ticket.last_sell_date}`).isSameOrAfter(moment.now(), 'day') &&
          moment(ticket.last_sales_time).isAfter(moment.now())
        )
      }).length
    } catch (e) {
      console.log(e)
      return 0
    }
  }

  render() {
    const {
      tab,
      dispatch,
      userInfo,
      userHistory,
      userInfoUpdating,
      userInfoUpdateError,
      userHistoryFetch
    } = this.props

    const renderContainerContent = () => {
      switch (tab) {
        case TABS.info:
          return (
            <div className="userpage-info-container">
              <div className="userpage-info-window">
                <UserPageInfo
                  {...userInfo}
                  changeToUpdate={() => dispatch(changeTab(TABS.update))}
                  adminConsole={() => dispatch(push('/admin'))}
                />
              </div>
            </div>
          )
        case TABS.history:
          return (
            <UserPageHistory
              {...userHistory}
              userHistoryFetch={userHistoryFetch}
            />
          )
        case TABS.update:
          return (
            <div className="userpage-info-container">
              <div className="userpage-info-window">
                <UserPageUpdate
                  {...userInfo}
                  dispatch={dispatch}
                  userInfoUpdating={userInfoUpdating}
                  userInfoUpdateError={userInfoUpdateError}
                />
              </div>
            </div>
          )
        case TABS.insell:
          return (
            <Insell
              {...userHistory}
              updateTicket={this.updateTicket(dispatch)}
              userHistoryFetch={userHistoryFetch}
              changePath={this.changePath()}
              removeTicketFromSell={this.removeTicketFromSell(dispatch)}
            />
          )
        default:
          return null
      }
    }

    return (
      <div>
        <div className="row">
          <div className="col-sm-7 col-sm-offset-1">
            <h1>
              <Translate
                value="user.hello"
                name={this.props.userInfo.first_name}
              />
            </h1>
            <div className="setting-label">
              {this.props.userInfo.first_name &&
                this.props.userInfo.last_name && (
                  <span>{`${this.props.userInfo.first_name} ${this.props.userInfo.last_name
                    }`}</span>
                )}
            </div>
            <div>{`${this.props.userInfo.email}`}</div>
          </div>
          <div className="col-sm-3">
            <div className="tickets-for-sale">
              <Translate value="user.tickets_in_sale" />
              {this.ticketsInSale <= 1 ? (
                <Translate
                  value="user.tickets_for_sale_pc"
                  tickets={this.ticketsInSale()}
                  className="tickets-for-sale-pc"
                />
              ) : (
                <Translate
                  value="user.tickets_for_sale_pcs"
                  tickets={this.ticketsInSale()}
                  className="tickets-for-sale-pc"
                />
              )}
            </div>
          </div>
          <div className="break-lg" />

          <div className="col-sm-10 col-sm-offset-1">
            <div className="header-tabs">
              <div className="row">
                <div
                  className={`col-sm-4 cursor-pointer ${tab === TABS.info || tab === TABS.update
                    ? 'tab-header-active'
                    : 'tab-header'
                    }`}
                  onClick={() => {
                    dispatch(changeTab(TABS.info))
                  }}
                >
                  <div className="tab-header-button">
                    <i className="fa-solid fa-user" color={`${tab === TABS.info ? '#8e44ad' : '#696969'}`} />
                    <Translate
                      value="user.own_infos"
                      style={{ marginLeft: '15px' }}
                    />
                  </div>
                </div>

                <div
                  className={`col-sm-4 cursor-pointer ${tab === TABS.insell ? 'tab-header-active' : 'tab-header'
                    }`}
                  onClick={() => {
                    dispatch(changeTab(TABS.insell))
                  }}
                >
                  <div className="tab-header-button">
                    <i className="fa-solid fa-ticket-simple" color={`${tab === TABS.insell ? '#8e44ad' : '#696969'}`} />
                    <Translate
                      value="user.tickets_for_sale"
                      style={{ marginLeft: '15px' }}
                    />
                  </div>
                </div>

                <div
                  className={`col-sm-4 cursor-pointer ${tab === TABS.history ? 'tab-header-active' : 'tab-header'
                    }`}
                  onClick={() => {
                    dispatch(changeTab(TABS.history))
                  }}
                >
                  <div className="tab-header-button">
                    <i className="fa-solid fa-clock-rotate-left" color={`${tab === TABS.history ? '#8e44ad' : '#696969'}`} />
                    <Translate
                      value="user.sold_and_bought"
                      style={{ marginLeft: '15px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="break-lg" />
            <div className="row">{renderContainerContent()}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    tab: state.userPageUI.tab,
    userHistory: state.users.userHistory,
    userInfo: state.users.userInfo,
    signedIn: state.users.signedIn,
    userInfoUpdating: state.users.userInfoUpdating,
    ticketUpdateFetching: state.users.ticketUpdateFetching,
    userHistoryFetch: state.users.userHistoryFetch,
    userInfoUpdateError: state.users.userInfoUpdateError
  }
}

export default connect(mapStateToProps)(UserPage)
