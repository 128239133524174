import {
  TICKET_CODE_NOT_FOUND_FAILURE,
  TICKET_CODE_FOUND_SUCCESS,
  TICKET_CODE_CHANGED,
  TICKET_CODE_IS_VALID,
  TICKET_CODE_IS_INVALID,
  START_VALIDATION_FETCH,
  ADD_TICKET_TO_SELL,
  SET_EVENT_INFO,
  DELETE_TICKET,
  EVENTS_FETCH_SUCCESS,
  REMOVE_TICKET_FROM_LISTS,
  TICKET_SELL_SUCCESS,
  KILL_SALES
} from '../actionTypes'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

export default function tickets(
  state = {
    ticketCode: '',
    valid: false,
    validationError: false,
    isFetching: false,
    inSell: [],
    ticketInfo: {},
    eventInfo: null,
    newest: { events: [], tickets: [] },
    validationMessage: null,
    namedInfos: {},
    persistExpiresAt: moment()
      .add(1, 'h')
      .format()
  },
  action
) {
  switch (action.type) {
    case REMOVE_TICKET_FROM_LISTS:
      const newTickets = state.newest.tickets.filter(
        ticket => ticket.id !== action.ticketId
      )

      return {
        ...state,
        newest: { ...state.newest, tickets: newTickets }
      }
    case EVENTS_FETCH_SUCCESS:
      const newest = action.newest

      const events = []
      const eventIds = {}
      newest.events.forEach(event => {
        if (!eventIds[event.id]) {
          events.push(event)
          eventIds[event.id] = true
        }
      })

      newest.events = events
      sortBy(newest, ['event.sale_start_date'], ['desc'])
      return {
        ...state,
        newest
      }
    case DELETE_TICKET:
      const codeToRemove = action.ticketCode
      return {
        ...state,
        inSell: state.inSell.filter(ticket => ticket.code !== codeToRemove)
      }
    case KILL_SALES:
      return {
        ...state,
        inSell: []
      }
    case ADD_TICKET_TO_SELL:
      return {
        ...state,
        ticketCode: '',
        inSell: [...state.inSell, state.ticketInfo],
        ticketInfo: {},
        valid: false
      }
    case SET_EVENT_INFO:
      return {
        ...state,
        eventInfo: action.eventInfo
      }
    case START_VALIDATION_FETCH:
      return {
        ...state,
        isFetching: true
      }
    case TICKET_CODE_NOT_FOUND_FAILURE:
      return {
        ...state,
        validationError: true,
        isFetching: false,
        validationMessage: action.message
      }
    case TICKET_CODE_FOUND_SUCCESS:
      return {
        ...state,
        valid: true,
        isFetching: false,
        ticketInfo: action.body.ticket,
        validationError: false
      }
    case TICKET_CODE_CHANGED:
      return {
        ...state,
        ticketCode: action.ticketCode,
        validationError: false
      }
    case TICKET_CODE_IS_VALID:
      return {
        ...state,
        valid: true
      }
    case TICKET_CODE_IS_INVALID:
      return {
        ...state,
        valid: false
      }
    case TICKET_SELL_SUCCESS:
      return {
        ...state,
        inSell: []
      }
    default:
      return state
  }
}
