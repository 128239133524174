import React from 'react'
import './styles.scss'

const EventInfoBox = (props) => {
  const { title, value } = props

  return (
    <div className="event-info-box">
      <div className="event-info-box--title">{title}</div>
      <div className="event-info-box--value">{value}</div>
    </div>
  )
}

export default EventInfoBox
