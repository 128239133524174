import React from 'react'

const BankButton = props => {
  const fields = props.parameters

  const hiddenFields = Object.keys(fields).map(key => (
    <input key={key} type="hidden" name={fields[key].name} value={fields[key].value || ''} />
  ))

  return (
    <div className="col-sm-2 col-xs-6">
      <div className="bank-button cursor-pointer">
        <form action={props.url} method="post">
          {hiddenFields}
          <input type="image" src={props.icon} alt={props.name} value="" />
        </form>
      </div>
    </div>
  )
}

export default BankButton
