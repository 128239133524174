import React, { useState } from 'react'
import * as API from '../../utility/api'
import UserRow from './user-row'

const BASE_URL = 'admin/customers'
const ADMINS_URL = 'admin/customers/admins'

const UsersManagement = () => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [market, setMarket] = useState(false)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [exportData, setExportData] = useState([])
  const [showExportData, setShowExportData] = useState(false)

  const submit = e => {
    e.preventDefault()
    setLoading(true)
    setError(false)
    setShowExportData(false)

    API.GET(BASE_URL, { name, email, market })
      .then(({ data }) => {
        setLoading(false)
        setUsers(data)
      })
      .catch(({ response }) => {
        setError(response.data.message)
        setLoading(false)
      })
  }

  const generateUserRows = () =>
    users.map(user => <UserRow key={`user-row-${user.Username}`} user={user} />)

  const getAdmins = () => {
    setShowExportData(false)
    setLoading(true)

    API.GET(ADMINS_URL)
      .then(({ data }) => {
        setLoading(false)
        setUsers(data)
      })
      .catch(e => {
        setLoading(false)
        console.log(e);
      })
  }

  const handleExportUsers = () => {
    setLoading(true)
    setShowExportData(true)

    API.GET(BASE_URL + '/export')
      .then(({ data }) => {
        setLoading(false)
        setExportData(data)
      })
      .catch(e => {
        setLoading(false)
        setError(e)
      })
  }

  const generateExportData = () => {
    const tableData = exportData.map(user => {

      return (
        <tr key={user.sub}>
          <td>{user.first_name || 'Ei tiedossa'}</td>
          <td>{user.last_name || 'Ei tiedossa'}</td>
          <td>{user.iban || 'Ei tiedossa'}</td>
          <td>{user.allow_marketing || 'Ei tiedossa'}</td>
          <td>{user.email|| 'Ei tiedossa'}</td>
          <td>{user.phone_number || 'Ei tiedossa'}</td>
        </tr>
      )
    })

    return (
      <table>
        <thead>
          <tr>
            <th>Etunimi</th>
            <th>Sukunimi</th>
            <th>IBAN</th>
            <th>markkinointi</th>
            <th>email</th>
            <th>puhelin</th>
          </tr>
        </thead>
        <tbody>{tableData}</tbody>
      </table>
    )
  }

  return (
    <div>
      <h4>Etsi käyttäjä</h4>

      <div className="row">
        <div className="col-sm-6">
          <form onSubmit={submit}>
            <label>
              Email
              <input
                type="text"
                style={{ marginLeft: '5px', width: '300px' }}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </label>

            <label>
              Sukunimi
              <input
                type="text"
                style={{ marginLeft: '5px', width: '300px' }}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </label>

            <label>
              Vain markkinoinnin hyväksyneet
              <input
                type="checkbox"
                checked={market}
                onChange={e => setMarket(e.target.checked)}
              />
            </label>

            <button type="submit" disabled={loading}>
              {loading ? 'Haetaan...' : 'Hae'}
            </button>
          </form>
        </div>

        <div
          className="col-sm-6"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <button onClick={handleExportUsers} disabled={loading}>
            Asiakasrekisteri export
          </button>
        </div>
      </div>

      <div className="break-sm" />

      <button onClick={() => getAdmins()} disabled={loading}>
        Hae admin-käyttäjät
      </button>

      <div className="break-lg" />

      {error && <div>{error}</div>}
      {users && !error && <div>{generateUserRows()}</div>}
      {showExportData && !error && <div>{generateExportData()}</div>}
    </div>
  )
}

export default UsersManagement
