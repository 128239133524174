import { SET_BASKET, SET_ERROR, CLEAR_ERRORS } from '../actionTypes'

export default function payment(
  state = {
    basket: null,
    errors: []
  },
  action
) {
  switch (action.type) {
    case SET_BASKET:
      return {
        ...state,
        basket: action.basket
      }
    case SET_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.error]
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: []
      }
    default:
      return state
  }
}
