import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  TABS,
  changeAdminTab,
  updateTranslation,
  setModifiedTranslations,
  addFaqRow
} from '../actions/admin'
import * as API from '../utility/api'
import Stats from '../components/admin-console/stats'
import Exports from '../components/admin-console/exports'
import BasketManagement from '../components/admin-console/basket-management'
import SepaPage from '../components/admin-console/sepa-page'
import UsersManagement from '../components/admin-console/users-management'
import TicketsManagement from '../components/admin-console/tickets-management'
import Translations from '../components/admin-console/translations'
import { loadTranslations } from 'react-redux-i18n'
import TestPage from '../components/admin-console/test-page'

class AdminConsole extends Component {
  state = {
    stats: null,
    loading: false
  }

  componentDidMount() {
    if (!this.props.modifiedTranslations) {
      this.props.dispatch(setModifiedTranslations())
    }

    this.setState({ loading: true })

    API.GET('admin/stats').then(({ data }) => {
      this.setState({ stats: data, loading: false })
    })
  }

  changeTab = tab => {
    const { dispatch } = this.props

    dispatch(changeAdminTab(tab))
  }

  translationRowOnChange = (lang, topic, key, value, path) => {
    this.props.dispatch(updateTranslation(lang, topic, key, value, path))
  }

  dispatchAddFaqRow = lang => {
    this.props.dispatch(addFaqRow(lang))
  }

  dispatchLoadTranslations = translations => {
    this.props.dispatch(loadTranslations(translations))
  }

  render() {
    const { tab } = this.props.admin
    let { modifiedTranslations } = this.props

    if (modifiedTranslations && modifiedTranslations.expires) {
      delete modifiedTranslations.expires
    }

    const bottom = () => {
      switch (tab) {
        case TABS.STATS:
          return <Stats loading={this.state.loading} stats={this.state.stats} />
        case TABS.TRANSLATIONS:
          return (
            <Translations
              modifiedTranslations={modifiedTranslations}
              translationRowOnChange={this.translationRowOnChange}
              dispatchAddFaqRow={this.dispatchAddFaqRow}
              saveTranslations={this.saveTranslations}
              dispatchLoadTranslations={this.dispatchLoadTranslations}
            />
          )
        case TABS.RAPORTS:
          return <Exports />
        case TABS.MANAGE:
          return <BasketManagement />
        case TABS.USERS:
          return <UsersManagement />
        case TABS.TICKETS:
          return <TicketsManagement />
        case TABS.SEPA:
          return <SepaPage />

        case TABS.TEST:
          return <TestPage />
        default:
          return <div />
      }
    }

    return (
      <div className="tl-container">
        <h1>Admin paneeli</h1>
        <div
          className="text-center"
          style={{
            display: 'flex',
            justifyContent: 'baseline'
          }}
        >
          <AdminNav onClick={this.changeTab} active={tab} />
        </div>
        <div className="break-md" />
        {bottom()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.users.userInfo,
  admin: state.admin,
  modifiedTranslations: state.admin.modifiedTranslations
})

export default connect(mapStateToProps)(AdminConsole)

const AdminNav = ({ onClick, active }) => {
  return (
    <React.Fragment>
      <div
        className={`admin-page-nav ${active === TABS.STATS ? 'active' : ''}`}
        onClick={() => onClick(TABS.STATS)}
      >
        Statistiikka
      </div>

      <div
        className={`admin-page-nav ${active === TABS.RAPORTS ? 'active' : ''}`}
        onClick={() => onClick(TABS.RAPORTS)}
      >
        Raportit
      </div>

      <div
        className={`admin-page-nav ${active === TABS.SEPA ? 'active' : ''}`}
        onClick={() => onClick(TABS.SEPA)}
      >
        SEPA
      </div>

      <div
        className={`admin-page-nav ${
          active === TABS.TRANSLATIONS ? 'active' : ''
        }`}
        onClick={() => onClick(TABS.TRANSLATIONS)}
      >
        Käännökset
      </div>

      <div
        className={`admin-page-nav ${active === TABS.TICKETS ? 'active' : ''}`}
        onClick={() => onClick(TABS.TICKETS)}
      >
        Liput
      </div>

      <div
        className={`admin-page-nav ${active === TABS.MANAGE ? 'active' : ''}`}
        onClick={() => onClick(TABS.MANAGE)}
      >
        Tilaukset
      </div>

      <div
        className={`admin-page-nav ${active === TABS.USERS ? 'active' : ''}`}
        onClick={() => onClick(TABS.USERS)}
      >
        Asiakasrekisteri
      </div>

      <div
        className={`admin-page-nav ${active === TABS.TEST ? 'active' : ''}`}
        onClick={() => onClick(TABS.TEST)}
      >
        Testi
      </div>
    </React.Fragment>
  )
}
