import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Link } from 'react-router-dom'

const EventMenu = props => {
  const { selectedAction, ticketsClick } = props

  return (
    <div className="header-tabs">
      <div className="row no-margin">
        <div
          className={`col-sm-12 col-md-2 tab-header cursor-pointer ${
            selectedAction === 'tickets' ? 'tab-header-active' : ''
          }`}
          onClick={() => ticketsClick()}
        >
          <div className="tab-header-button">
            <Translate
              value="events.choose_ticket"
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div>

        {/* <div
          className={`col-sm-12 col-md-2 tab-header cursor-pointer ${
            selectedAction === 'info' ? 'tab-header-active' : ''
          }`}
          onClick={() => infoClick()}
        >
          <div className="tab-header-button">
            <Translate
              value="events.info"
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div> */}

        <div className="col-sm-12 col-md-2 tab-header cursor-pointer main-sell-tickets">
          <Link to="/tickets/sell" className="tab-header-link">
            <div className="cursor-pointer tab-header-button">
              <Translate
                value="events.sell_ticket"
                style={{ textTransform: 'uppercase' }}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EventMenu
