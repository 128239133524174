import React from 'react'
import GlobalLoadingIndicator from '../../components/global-loading-indicator'
import RepresentMoney from '../../utility/represent-money'

const Stats = props => {
  const { loading, stats } = props

  // const events = () => {
  //   const eventTable = Object.entries(stats.events).map((entry, i) => {
  //     const entryInfo = entry[1]

  //     return (
  //       <tr key={i}>
  //         <td className="tl-event-table__column--l">{entryInfo.ticket_name}</td>
  //         <td className="tl-event-table__column--r">
  //           <RepresentMoney sum={entryInfo.total_amount} eurSuffix />
  //         </td>
  //         <td className="tl-event-table__column--r">{entryInfo.total_count}</td>
  //       </tr>
  //     )
  //   })

  //   return (
  //     <div className="tl-event-table">
  //       <table>
  //         <thead>
  //           <tr>
  //             <th className="tl-event-table__column--l">Tapahtuman nimi</th>
  //             <th className="tl-event-table__column--r">Summa yhteensä</th>
  //             <th className="tl-event-table__column--r">
  //               Lippuja myyty yhteensä
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody>{eventTable}</tbody>
  //       </table>
  //     </div>
  //   )
  // }

  return (
    <React.Fragment>
      <div className="row bold">
        <div className="col-sm-12">Statistiikkaa</div>
      </div>

      <div className="break-md" />

      {loading && (
        <div>
          <GlobalLoadingIndicator center />
        </div>
      )}

      {stats && !loading && (
        <div>
          <div className="row">
            <div className="col-sm-12">
              Lippuja myynnissä: {stats.ticket_counts.for_sale}
            </div>
          </div>

          <div className="break-sm" />

          <div className="row">
            <div className="col-sm-12">
              Lippujen myyntimäärä yhteensä:{' '}
              <RepresentMoney sum={stats.total_sales.total_amount} eurSuffix />
            </div>
          </div>

          <div className="break-sm" />

          <div className="row">
            <div className="col-sm-12">
              Lippujen myyntimäärä yhteensä:{' '}
              {stats.total_sales.sold_tickets_count} kpl
            </div>
          </div>

          <div className="break-sm" />

          <div className="row">
            <div className="col-sm-12">
              Kaikkien myyntien keskiarvohinta:{' '}
              <RepresentMoney
                sum={stats.total_sales.average_payment_amount}
                eurSuffix
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Stats
