import React from 'react'
import orderBy from 'lodash/orderBy'

const withSortHeader = Header => {
  return WrappedComponent => {
    return class extends React.Component {
      state = {
        sortKey: 'bargain'
      }

      handleSortKey = sortKey => {
        this.setState({
          sortKey
        })
      }

      sortByCheapestTicket(event) {
        return event.tickets.reduce(
          (a, c) => (c.price_number < a ? c.price_number : a),
          99999
        )
      }

      sortByBestBargain(event) {
        return event.tickets.reduce((a, c) => {
          const org_price = parseInt(c.original_price, 10)

          const percent = (org_price - c.price_number) / org_price

          return percent > a ? percent : a
        }, 0.0)
      }

      getSorter = () => {
        const sorters = {
          bargain: { sort: this.sortByBestBargain, order: 'desc' },
          price: { sort: this.sortByCheapestTicket, order: 'asc' }
        }

        return sorters[this.state.sortKey]
      }

      render() {
        const { tickets, ...rest } = this.props

        const sorter = this.getSorter()
        const sortedTickets = orderBy(tickets, [sorter.sort], [sorter.order])

        return (
          <React.Fragment>
            {Header(this.handleSortKey, this.state.sortKey)}
            <WrappedComponent
              tickets={sortedTickets}
              {...rest}
              sortKey={this.state.sortKey}
            />
          </React.Fragment>
        )
      }
    }
  }
}

export default withSortHeader
