import React, { useState, useEffect } from 'react'
import * as API from '../../utility/api'
import moment from 'moment'

const GET_NEWEST_URL = 'admin/sepa/latest'
const GET_RUNNING_EXPORT = 'admin/sepa'
const CREATE_ORGANIZER_RECEIPT = 'admin/sepa/organizers'

const RunningExports = () => {
  const [mostRecent, setMostRecent] = useState({
    running: null,
    organizer: null,
    organizer_exports: [],
    running_exports: [],
    organizer_count: 0
  })
  const [fetching, setFetching] = useState({ running: false, org: false })
  const [organizerCount, setOrganizerCount] = useState(0)
  const [eventEndDate, setEventEndDate] = useState(null)
  const [settlementPaymentDate, setSettlementPaymentDate] = useState(moment().format('YYYY-MM-DD'))
  const [organizerIDs, setOrganizerIDs] = useState('')

  useEffect(() => {
    API.GET(GET_NEWEST_URL)
      .then(({ data }) => {
        updateMostRecent(data)
      })
      .catch(e => console.error(e))
  }, [])

  const handleRunningClick = () => {
    setFetching({ ...fetching, running: true })

    API.POST(GET_RUNNING_EXPORT)
      .then(() => {
        setFetching({ ...fetching, running: false })

        setMostRecent({
          running: moment(new Date(), 'X').fromNow(),
          organizer: mostRecent.organizer,
          organizer_exports: mostRecent.organizer_exports,
          running_exports: mostRecent.running_exports,
          organizer_count: mostRecent.organizer_count
        })
      })
      .catch(e => {
        setFetching({ ...fetching, running: false })
        console.log(e);
      })
  }

  const handleOrganizationClick = () => {
    setFetching({ ...fetching, org: true })
    let event_end_date = null;
    let event_ended_days_ago = 0;
    let settlement_payment_date = moment(settlementPaymentDate).format('YYYY-MM-DD');
    if (eventEndDate) {
      event_end_date = moment(eventEndDate).format('YYYY-MM-DD');
      if (event_end_date > moment().subtract(2, 'days').format('YYYY-MM-DD')) {
        event_end_date = null;
        alert('Tapahtuman loppupäivämäärä ei voi olla enempää kuin 2 päivää sitten.');
        setFetching({ ...fetching, org: false });
        return;
      }
      event_ended_days_ago = moment().diff(moment(eventEndDate), 'days');
    }

    if (moment().diff(moment(settlementPaymentDate), 'days') > 0) {
      alert('Tilityksen maksupäivä ei voi olla menneisyydessä.');
      setFetching({ ...fetching, org: false });
      return;
    } else {
      settlement_payment_date = moment(settlementPaymentDate).format('YYYY-MM-DD');
    }

    API.POST(CREATE_ORGANIZER_RECEIPT, {
      organizer_count: parseInt(organizerCount),
      event_ended_days_ago: event_ended_days_ago,
      settlement_payment_date: settlement_payment_date,
      organizer_ids: organizerIDs
    })
      .then(({data}) => {
        console.log(data);
        setFetching({ ...fetching, org: false })
        if (data.success === false) {
          if (data.message) {
            API.apiNotification({ value: data.message, status: 'error' })
          }
          
        } else {
          setMostRecent({
            organizer: moment(new Date(), 'X').fromNow(),
            running: mostRecent.running,
            organizer_exports: mostRecent.organizer_exports,
            running_exports: mostRecent.running_exports,
            organizer_count: mostRecent.organizer_count
          })
        }        
      })
      .catch(e => {
        setFetching({ ...fetching, org: false })
        console.log(e);
        alert('Raportin muodostaminen epäonnistui.');
      })
  }

  const updateMostRecent = data => {
    const { running: r, organizer: o, organizer_exports, running_exports, organizer_count: c } = data

    const running = r?.created_at ? moment(r.created_at).fromNow() : 'Ei luotu'
    const organizer = o?.created_at ? moment(o.created_at).fromNow() : 'Ei luotu'
    const organizer_count = c.organizer_count ? c.organizer_count : 0;

    setMostRecent({ running, organizer, organizer_exports, running_exports, organizer_count})
  }

  const exportTable = (exports) => {
    if(exports.length === 0) {
      return null;
    }
    return (
      <table>
        <thead>
          <tr>
            <th>Luotu</th>
            <th style={{ paddingLeft: '10px' }}>Lippuja</th>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          {exports.map((e, i) => (
            <tr key={i}>
              <td style={{ textAlign: 'right' }}>{moment(e.created_at).format("DD.MM.YYYY HH:mm")}</td>
              <td style={{ paddingLeft: '10px', textAlign: 'right' }}>
              {e.ticket_count}
              </td>
              <td style={{ paddingLeft: '10px', textAlign: 'right' }}>{e.id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const RunningSepaContainer = () => (
    <div style={{ minWidth: '330px' }}>
      <div>Viimeksi luotu: {mostRecent.running}</div>
      <div className="break-sm" />
      <button onClick={handleRunningClick} disabled={fetching.running}>
        {fetching.running ? 'Luodaan...' : 'Luo juokseva sepa'}
      </button>
      <h3>Viimeisimmät raportit</h3>
      {mostRecent.running_exports ? exportTable(mostRecent.running_exports ) : 'Ei raportteja'}
    </div>
  )

  const OrganizerSepaContainer = () => (
    <div style={{ minWidth: '330px' }}>
      
      <div className="break-sm" />
      <div>
        <label>Järjestäjien määrä (0 = kaikki)</label>
        <input
          type="number"
          value={organizerCount}
          onChange={e => setOrganizerCount(e.target.value)}
        />
      </div>
      <div>
        <label>Tapahtuman loppupvm</label>
        <input
          type="date"
          value={eventEndDate || moment().subtract(2,'days').format('YYYY-MM-DD')}
          onChange={e => setEventEndDate(e.target.value)}
        />
      </div>
      <div>
        <label>Tilityksen maksupäivä</label>
        <input
          type="date"
          value={settlementPaymentDate || moment().format('YYYY-MM-DD')}
          onChange={e => setSettlementPaymentDate(e.target.value)}
        />
      </div>
      <div>
        <label>Järjestäjä ID:t (erottele pilkulla)</label>
        <input
          type="text"
          value={organizerIDs || ''}
          onChange={e => setOrganizerIDs(e.target.value)}
        />
      </div>
      <div className="break-sm" />
      <button onClick={handleOrganizationClick} disabled={fetching.org}>
        {fetching.org ? 'Luodaan...' : 'Luo järjestäjien sepa ja kuitit'}
      </button>
      <div>Viimeksi luotu: {mostRecent.organizer}</div>
      <div>Järjestäjiä, joille tulossa tilityksiä: {mostRecent.organizer_count}</div>
      <h3>Viimeisimmät raportit</h3>
      {mostRecent.organizer_exports ? exportTable(mostRecent.organizer_exports ) : 'Ei raportteja'}
    </div>
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '70%'
      }}
    >
      <RunningSepaContainer />
      <OrganizerSepaContainer />
    </div>
  )
}

export default RunningExports
