import { CHANGE_TAB } from '../actionTypes'
import { TABS } from '../actions/user-page-ui'

export default function userPageUI(
  state = {
    tab: TABS.info
  },
  action
) {
  switch (action.type) {
    case CHANGE_TAB:
      return {
        ...state,
        tab: action.tab
      }
    default:
      return state
  }
}
