import React from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { connect } from 'react-redux'
import { addSearchDates } from '../../actions/search'
import { I18n } from 'react-redux-i18n'

class SearchpageDatePicker extends React.Component {
  state = {
    focusedInput: null
  }

  render() {
    moment.locale('fi')
    const { dispatch, startDate, endDate } = this.props

    return (
      <DateRangePicker
        startDate={startDate}
        startDateId={moment(startDate).format('l')}
        endDate={endDate}
        endDateId={moment(endDate).format('l')}
        onDatesChange={({ startDate, endDate }) =>
          dispatch(addSearchDates(startDate, endDate))
        }
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={I18n.t('datepicker.start_date')}
        endDatePlaceholderText={I18n.t('datepicker.end_date')}
        phrases={{
          closeDatePicker: I18n.t('datepicker.close'),
          clearDates: I18n.t('datepicker.clear')
        }}
        numberOfMonths={1}
      />
    )
  }
}

const mapStateToProps = state => ({
  startDate: state.search.startDate,
  endDate: state.search.endDate
})

export default connect(mapStateToProps)(SearchpageDatePicker)
