import React, { Component } from 'react'
import HeaderButton from '../components/header-button'
import Logo from '../components/logo'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { signOut } from '../actions/users'
import { push } from 'connected-react-router'
import { setLocale } from 'react-redux-i18n'
import { changeView, views } from '../actions/users'
import { toggleModal } from '../actions/modal'
import { getAllEvents } from '../actions/events'
import moment from 'moment'
import ShoppingBasketTimer from '../components/shopping-basket-timer'
import Select from 'react-select'

class Header extends Component {
  lastScrollTop = 0

  lastDirection = 'up'

  state = {
    hidden: false
  }

  componentDidMount() {
    const loc = this.currentScroll()
    this.lastScrollTop = loc

    this.listenScroll()
  }

  currentScroll = () => {
    return window.pageYOffset || document.documentElement.scrollTop
  }

  listenScroll = () => {
    window.addEventListener(
      'scroll',
      () => {
        const loc = this.currentScroll()

        if (loc > this.lastScrollTop) {
          if (this.lastDirection === 'up') {
            this.setState({ hidden: true })
            this.closeNavOnMobile()
          }
          this.lastDirection = 'down'
        } else {
          if (this.lastDirection === 'down') this.setState({ hidden: false })
          this.lastDirection = 'up'
        }

        // For Mobile or negative scrolling
        this.lastScrollTop = loc <= 0 ? 0 : loc
      },
      false
    )
  }

  closeBasketOnMobile = () => {
    const basketBox = document.getElementById('shopping-toggle')
    basketBox.checked = false
  }

  closeNavOnMobile = () => {
    const hamburger = document.getElementById('header-right-toggle')
    hamburger.checked = false
  }

  render() {
    const {
      user,
      dispatch,
      shoppingBasket,
      locale,
      endTime
      // location,
    } = this.props
    const { hidden } = this.state

    const signedIn = user && user.signedIn
    const basketLength = shoppingBasket.ticketList
      ? shoppingBasket.ticketList.length
      : 0

    const handleChange = event => {
      dispatch(setLocale(event.value))
      dispatch(getAllEvents(event.value, true))
      moment.locale(event.value)
    }

    const languageOptions = [
      { value: 'fi', label: 'Suomi' },
      { value: 'en', label: 'English' }
    ]

    const basket = () => {
      if (!signedIn || !basketLength) return null

      return (
        <div className="shopping-basket-container">
          <label htmlFor="shopping-toggle" className="shopping-toggle-label">
            <div className="shopping-toggle-cart">
              <i className="fa-regular fa-cart-shopping"></i>
            </div>
            <div>
              {`${basketLength} `}
              {basketLength > 1 ? (
                <Translate value="basket.multiple.pcs" />
              ) : (
                <Translate value="basket.one.pcs" />
              )}
            </div>
            <div>
              <ShoppingBasketTimer
                endTime={endTime}
                dispatch={dispatch}
                className="shopping-toggle-counter"
              />
            </div>
          </label>
          <input
            type="checkbox"
            className="shopping-toggle"
            id="shopping-toggle"
          />
          <div
            className="shopping-basket"
            onClick={() => {
              dispatch(push('/basket'))
              this.closeBasketOnMobile()
            }}
          >
            <h2 className="shopping-basket__header">
              <Translate value="shopping_basket.shopping_basket" />
            </h2>
            <p>
              {`${basketLength} `}
              {basketLength > 1 ? (
                <Translate value="basket.multiple.pcs" />
              ) : (
                <Translate value="basket.one.pcs" />
              )}
            </p>
            <div className="shopping-counter-title">
              <Translate value="shopping_basket.basket_time_left" />
            </div>
            <ShoppingBasketTimer
              endTime={endTime}
              dispatch={dispatch}
              className="shopping-counter"
            />

            <div className="basket-button">
              <Translate value="shopping_basket.pay" />
            </div>
          </div>
        </div>
      )
    }

    return (
      <nav id="header" className={`header ${hidden ? 'header-hidden' : ''}`}>
        <div className="header-bar">
          <Logo />

          <label
            htmlFor="header-right-toggle"
            className="header-right-toggle-label"
          >
            <span />
            <span />
            <span />
          </label>
          <input
            type="checkbox"
            className="header-right-toggle"
            id="header-right-toggle"
          />
          <div className="header-right">
            <div
              className="language-select"
              style={{ width: '100%', minWidth: '12em' }}
            >
              <Select
                className="react-select--language"
                classNamePrefix="react-select--language"
                value={languageOptions.filter(
                  language => language.value === locale
                )}
                onChange={handleChange}
                options={languageOptions}
                isSearchable={false}
              />
            </div>

            <HeaderButton
              onClick={() => {
                this.closeNavOnMobile()
              }}
              path="/faq"
            >
              <Translate
                style={{ textTransform: 'uppercase' }}
                value="header.faq"
              />
            </HeaderButton>

            {!signedIn && (
              <React.Fragment>
                <HeaderButton
                  onClick={() => {
                    dispatch(changeView(views.register))
                    this.closeNavOnMobile()
                  }}
                  path="/register"
                >
                  <Translate
                    style={{ textTransform: 'uppercase' }}
                    value="header.register"
                  />
                </HeaderButton>
                <HeaderButton
                  onClick={() => {
                    dispatch(toggleModal(true))
                    this.closeNavOnMobile()
                  }}
                >
                  <Translate
                    style={{ textTransform: 'uppercase' }}
                    value="header.sign_in"
                  />
                </HeaderButton>

                <HeaderButton
                  className="header-sell-tickets"
                  onClick={() => {
                    dispatch(toggleModal(true, '/tickets/sell'))
                    this.closeNavOnMobile()
                  }}
                  border
                >
                  <Translate
                    style={{ textTransform: 'uppercase' }}
                    value="header.sell_ticket"
                  />
                </HeaderButton>
              </React.Fragment>
            )}

            {signedIn && (
              <React.Fragment>
                <HeaderButton
                  onClick={() => {
                    this.closeNavOnMobile()
                  }}
                  path="/user"
                >
                  <Translate
                    style={{ textTransform: 'uppercase' }}
                    value="header.own_page"
                  />
                </HeaderButton>

                <HeaderButton
                  onClick={() => {
                    dispatch(signOut())
                    this.closeNavOnMobile()
                  }}
                  aria="click to log out"
                >
                  <Translate
                    style={{ textTransform: 'uppercase' }}
                    value="header.sign_out"
                  />
                </HeaderButton>

                <HeaderButton
                  className="header-sell-tickets"
                  onClick={() => {
                    this.closeNavOnMobile()
                  }}
                  path="/tickets/sell"
                  border
                >
                  <Translate
                    style={{ textTransform: 'uppercase' }}
                    value="header.sell_ticket"
                  />
                </HeaderButton>
              </React.Fragment>
            )}
          </div>

          {basket()}
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.users,
    shoppingBasket: state.shoppingBasket,
    endTime: state.shoppingBasket.endTime,
    locale: state.i18n.locale,
    location: state.router.location
  }
}

export default connect(mapStateToProps)(Header)
