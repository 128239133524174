import {
  CHANGE_ADMIN_TAB,
  UPDATE_TRANSLATION,
  SET_MODIFIED_TRANSLATIONS,
  ADD_FAQ_ROW
} from '../actionTypes'
import { TABS } from '../actions/admin'
import produce from 'immer'
import { set } from 'lodash'

export default function admin(
  state = {
    tab: TABS.STATS,
    modifiedTranslations: null
  },
  action
) {
  switch (action.type) {
    case ADD_FAQ_ROW:
      const { lang } = action
      const faqAmount = Math.max(
        ...Object.keys(state.modifiedTranslations[lang].faq)
          .map(f => f.split('_')[1])
          .filter(f => f)
      )

      const nextFaqIndex = faqAmount + 1

      const faqQuestionKey = 'q_' + nextFaqIndex
      const faqAnswerKey = 'a_' + nextFaqIndex

      return produce(state, draft => {
        draft.modifiedTranslations[lang].faq[faqQuestionKey] = ''
        draft.modifiedTranslations[lang].faq[faqAnswerKey] = ''
      })
    case SET_MODIFIED_TRANSLATIONS:
      return {
        ...state,
        modifiedTranslations: action.translations
      }
    case UPDATE_TRANSLATION: {
      const { lang, topic, key, value, path } = action

      return produce(state, draft => {
        let target = draft.modifiedTranslations[lang]
        if (target?.[topic]?.[key]) {
          if (path.length) {
            path.forEach(p => {
              target = target[p]
            })
          }
          target[topic][key] = value
        } else {
          let pathString = ''
          if (path.length) {
            path.forEach(p => {
              pathString += `${p}.`
            })
          }
          set(draft.modifiedTranslations[lang], `${pathString}${topic}.${key}`, value)
        }
      })
    }
    case CHANGE_ADMIN_TAB:
      return {
        ...state,
        tab: action.tab
      }
    default:
      return state
  }
}
