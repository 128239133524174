import React from 'react'
import moment from 'moment'
import { Translate } from 'react-redux-i18n'
import './newest-ticket-item.scss'

moment.locale('fi')

const NewestTicketItem = ({
  eventName,
  eventInfo,
  numberOfTickets,
  onEventClick,
  onClick
}) => {
  const event_image = eventInfo.image

  return (
    <div className="newest-ticket-item">
      <div className="row newest-ticket-item-container">
        <div className="col-sm-12 col-md-2 col-xs-3">
          <div
            className="event-image cursor-pointer"
            onClick={() => onEventClick()}
          >
            <img
              alt={eventName}
              src={event_image}
              className="event-list-image"
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div
            className="newest-ticket-item-title newest-ticket-item-content cursor-pointer"
            onClick={() => onEventClick()}
          >
            <h3>{eventName}</h3>
          </div>
        </div>

        <div className="new-ticket-mobile-divider" />

        <div className="col-sm-2 newest-ticket-item-content">
          {eventInfo && moment(eventInfo.start_date).format('DD.MM.YYYY')}
        </div>
        <div className="col-sm-1 newest-ticket-item-content">
          <Translate
            value="tickets.number_of_tickets"
            amount={numberOfTickets}
          />
        </div>

        <div className="new-ticket-mobile-divider" />

        <div className="col-sm-3 col-xs-12 newest-ticket-item-content">
          <div className="newest-ticket-button-holder">
            <button onClick={() => onClick()}>
              <Translate value="tickets.show_newest_tickets" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewestTicketItem
