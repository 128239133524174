import React from 'react'
import ButtonWithConfirmation from '../../components/button-with-confirmation'
import { Translate } from 'react-redux-i18n'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import GlobalLoadingIndicator from '../../components/global-loading-indicator'
import ValidatedInput from '../../components/validated-input'
import RepresentMoney from '../../utility/represent-money'
import { I18n } from 'react-redux-i18n'

class InsellTicket extends React.Component {
  state = {
    lastSellDate: this.props.ticket.last_sell_date,
    price: this.props.ticket.price,
    datePickerFocused: false,
    valid: {
      price: false
    },
    open: false
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  handleUpdateTicket = () => {
    const price = parseInt(this.state.price, 10) || 0
    this.props.updateTicket({
      ...this.props.ticket,
      price: price,
      last_sell_date: this.state.lastSellDate
    })
  }

  render() {
    const {
      ticket,
      ticketUpdateFetching,
      changePath,
      expired,
      removeTicketFromSell
    } = this.props
    const {
      name,
      id,
      batch,
      original_code,
      event,
    } = ticket

    let image = '/static/liputon-768x470.png'
    if (event?.image) {
      image = event.image
    } else if(event?.parent?.image) {
      image = event.parent.image
    }

    const getTicketDate = () => {
      try {
        if (moment(event.start_date).isSame(moment(event.end_date), 'day')) {
          return moment(event.end_date).format('dd DD.M.YYYY')
        }
        let start = moment(event.start_date).format('dd DD')
        let end = moment(event.end_date).format('dd DD.M.YYYY')
        start = start.charAt(0).toUpperCase() + start.slice(1)
        end = end.charAt(0).toUpperCase() + end.slice(1)
        return `${start}. - ${end}`
      } catch (e) {
        return ''
      }
    }

    const shareUrl = `/tickets/${batch.id}`
    const { price, lastSellDate, datePickerFocused } = this.state
    const currentPrice = price === '' ? '' : price / 100

    const maxPrice = Math.round(parseInt(ticket.original_price, 10) * 1.2)

    return (
      <div className="user-ticket-container">
        <div className="user-ticket-window">
          <div className="row">
            <div className="col-lg-5">
              <img src={image} className="ticket-image" alt="event" />
            </div>
            <div className="col-lg-7">
              <div className="user-ticket-info-container">
                <div className="user-ticket-title">{name}</div>
                <div className="user-ticket-date">{getTicketDate()}</div>
                <div className="user-ticket-location">
                  {`${event && event.location && event.location.name ? `${event.location.name} / ` : ''} ${
                    event && event.location && event.location.city ? event.location.city : ''
                  }`}
                </div>
                <div className="user-ticket-last-sale-date">
                  <Translate
                    value="tickets.sell_end_date"
                    date={moment(this.state.lastSellDate).format('DD.MM')}
                  />
                </div>
                <div className="user-ticket-price-container">
                  <Translate value="tickets.sell_price" />
                  <RepresentMoney eurSuffix sum={this.state.price} />
                </div>
                {!expired && (
                  <div className="ticket-links-container">
                    <div
                      onClick={() => changePath(shareUrl)}
                      className="user-ticket-modify-button"
                    >
                      <Translate value="tickets.batch_sell_url" />
                    </div>

                    <div
                      className="user-ticket-modify-button"
                      onClick={this.toggleOpen}
                    >
                      {!this.state.open && <Translate value="tickets.modify" />}
                      {this.state.open && (
                        <Translate value="tickets.close_modify" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.open && !ticketUpdateFetching && (
            <div className="edit-ticket-container">
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="original-code">
                    <span>
                      <Translate value="tickets.original_code" />
                    </span>
                  </label>
                  {original_code}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="original-price">
                    <span>
                      <Translate value="tickets.original_price" />
                    </span>
                  </label>
                  <RepresentMoney sum={ticket.original_price} eurSuffix />
                </div>
                <div className="break-md" />
                <div className="col-lg-12">
                  <label htmlFor="price">
                    <span>
                      <Translate value="tickets.asking_price" />
                    </span>
                  </label>
                  <ValidatedInput
                    onValid={() => {
                      this.setState({
                        valid: {
                          price: true
                        }
                      })
                    }}
                    inValid={() => {
                      this.setState({
                        valid: {
                          price: false
                        }
                      })
                    }}
                    validator={
                      price <= parseInt(ticket.original_price, 10) * 1.2
                    }
                    valid={this.state.valid.price}
                    value={this.state.price}
                    describe={I18n.t('tickets.price_cant_be_over', {
                      price: maxPrice / 100
                    })}
                  >
                    <input
                      style={{ padding: '5px 10px', marginRight: '5px' }}
                      type="number"
                      value={currentPrice}
                      onChange={e => {
                        const newPrice = e.target.value * 100
                        if (e.target.value === '') {
                          this.setState({ price: '' })
                        } else if (newPrice <= maxPrice) {
                          this.setState({ price: newPrice })
                        }
                      }}
                      min={0}
                      max={Math.round(
                        parseInt(ticket.original_price, 10) * 1.2
                      )}
                      className="user-ticket-input"
                    />
                  </ValidatedInput>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="last-sale-date">
                    <span>
                      <Translate value="tickets.last_sell_date" />
                    </span>
                  </label>
                  <SingleDatePicker
                    date={moment(lastSellDate)}
                    onDateChange={date => {
                      if (date !== null) {
                        this.setState({
                          lastSellDate: date
                        })
                      }
                    }}
                    focused={datePickerFocused}
                    onFocusChange={({ focused }) => {
                      this.setState({
                        datePickerFocused: focused
                      })
                    }}
                    isOutsideRange={date => {
                      return moment(date).isAfter(event.start_date)
                    }}
                    hideKeyboardShortcutsPanel
                    block
                  />
                </div>
                <div className="col-lg-12">
                  <button
                    disabled={!this.state.valid.price}
                    onClick={this.handleUpdateTicket}
                    className="button-wide"
                  >
                    <Translate value="user.update" />
                  </button>
                </div>
                <div className="col-lg-12">
                  <ButtonWithConfirmation
                    buttonText={<Translate value="tickets.delete_from_sell" />}
                    yesText={I18n.t('user.confirm_remove_ticket')}
                    noText={I18n.t('general.cancel')}
                    onClick={() => removeTicketFromSell(id)}
                    className="secondary-button button-wide"
                  />
                </div>
              </div>
            </div>
          )}
          {ticketUpdateFetching && (
            <div className="row">
              <GlobalLoadingIndicator center />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default InsellTicket
