import React from 'react'

const InfoRow = ({ first, second, third }) => (
  <React.Fragment>
    <div className="basket--management--row">
      {first[1] && (
        <label style={{ flex: 1 }}>{first[0]}
          <div className='content'>{first[1]}</div>
        </label>
      )}
      {!first[1] && (
        <label style={{ flex: 1 }}>
          <div />
        </label>
      )}

      {second[1] && (
        <label style={{ flex: 1 }}>
          {second[0]}
          <div className='content'>{second[1]}</div>
        </label>
      )}
      {!second[1] && (
        <label style={{ flex: 1 }}>
          <div />
        </label>
      )}

      {third[1] && (
        <label style={{ flex: 1 }}>
          {third[0]}
          <div className='content'>{third[1]}</div>
        </label>
      )}
      {!third[1] && (
        <label style={{ flex: 1 }}>
          <div />
        </label>
      )}
    </div>
    <div className="break-sm" />
  </React.Fragment>
)

export default InfoRow
