import React from 'react'
import debounce from 'lodash/debounce'

class TranslationRowInput extends React.Component {
  state = {
    value: this.props.value,
    rows: this.props.value.split('\n').length + 1
  }

  submitChange = () => {
    const { lang, topic, transKey, path } = this.props

    this.props.updateTranslation(lang, topic, transKey, this.state.value, path)
  }

  submit = debounce(() => this.submitChange(), 1000)

  handleChange = e => {
    this.cursor = e.target.selectionStart;
    this.setState({
      value: e.target.value,
      rows: e.target.value.split('\n').length + 1
    })
    this.submit()
  }

  render() {
    const { transKey } = this.props

    return (
      <div className="tl-translation-row">
        <div className="tl-translation-row__key">{transKey}</div>
        <textarea
          rows={this.state.rows}
          onChange={this.handleChange}
          onFocus={(e) => {
            e.target.selectionStart = this.cursor;
          }}
          className="tl-translation-row__input"
        >{this.state.value}</textarea>
      </div>
    )
  }
}

export default TranslationRowInput
