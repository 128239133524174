import {
  CHANGE_BANK_ACCOUNT_NUMBER,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  START_FETCH,
  SIGN_OUT,
  CHANGE_VIEW,
  REFRESH_TOKENS,
  INITIATE_PASSWORD_RECOVERY,
  START_USER_INFO_UPDATE,
  STOP_USER_INFO_UPDATE,
  USER_HISTORY_FETCH,
  USER_INFO_UPDATING,
  START_USER_HISTORY_FETCH,
  INITIATE_VERIFICATION,
  VERIFICATION_SUCCESS,
  VERIFICATION_FAILURE,
  DELETE_TICKET_FROM_SELL,
  TICKET_UPDATE_SUCCESS,
  INITIATE_TICKET_UPDATE,
  SET_FORCED_LOGIN_FROM,
  SUBSCRIPTION_SUCCESS,
  USER_SUBSCRIPTIONS_FETCH,
  REMOVE_SUBSCRIPTION
} from '../actionTypes'
import { views } from '../actions/users'
import produce from 'immer'
import IBAN from 'iban'

export default function users(
  state = {
    signedIn: false,
    signUpError: false,
    signInError: false,
    isFetching: false,
    tokens: {},
    //defaults locale to fi
    locale: 'fi',
    view: views.login,
    userHistory: { submitted: [], bought: [] },
    userHistoryFetch: false,
    userInfo: {},
    userInfoUpdating: false,
    verificationEmail: '',
    verificationFetching: false,
    verificationMessage: false,
    ticketUpdateFetching: false,
    forcedLoginFrom: null,
    userSubscriptions: [],
    verificationError: null,
    userInfoUpdateError: null
  },
  action
) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        return {
          ...action.payload.users,
          isFetching: false,
          signInError: false,
          verificationFetching: false,
          verificationMessage: false,
          ticketUpdateFetching: false
        }
      }

      return state
    case REMOVE_SUBSCRIPTION:
      const id = action.eventId
      const newSubs = state.userSubscriptions.filter(sub => sub !== id)

      return {
        ...state,
        userSubscriptions: newSubs
      }
    case USER_SUBSCRIPTIONS_FETCH:
      return {
        ...state,
        userSubscriptions: action.data.subscriptions
      }
    case SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        userSubscriptions: [...state.userSubscriptions, action.eventId]
      }
    case SET_FORCED_LOGIN_FROM:
      return {
        ...state,
        forcedLoginFrom: action.path
      }
    case INITIATE_TICKET_UPDATE:
      return {
        ...state,
        ticketUpdateFetching: true
      }
    case TICKET_UPDATE_SUCCESS:
      const updatedTicket = action.ticket
      const updatedSubmitted = [
        ...state.userHistory.submitted.filter(ticket => {
          return ticket.id !== updatedTicket.id
        }),
        updatedTicket
      ]

      return {
        ...state,
        ticketUpdateFetching: false,
        userHistory: {
          ...state.userHistory,
          submitted: updatedSubmitted
        }
      }
    case CHANGE_BANK_ACCOUNT_NUMBER:
      const IBANvalidity = action.iban && action.iban !== "" ? IBAN.isValid(action.iban) : false

      return {
        ...state,
        bankAccountNumber: action.iban,
        bankAccountValid: IBANvalidity
      }
    case DELETE_TICKET_FROM_SELL:
      const newTickets = state.userHistory.submitted.filter(
        ticket => ticket.id !== action.ticket_id
      )

      return produce(state, draft => {
        draft.userHistory.submitted = newTickets
      })
    case INITIATE_VERIFICATION:
      return {
        ...state,
        verificationFetching: true
      }
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationMessage: true,
        verificationError: null,
        verificationFetching: false
      }
    case VERIFICATION_FAILURE:
      return {
        ...state,
        verificationFetching: false,
        verificationError: action.message
      }
    case USER_HISTORY_FETCH:
      return {
        ...state,
        userHistory: action.data,
        userHistoryFetch: false
      }
    case START_USER_HISTORY_FETCH:
      return {
        ...state,
        userHistoryFetch: true
      }
    case INITIATE_PASSWORD_RECOVERY:
      return {
        ...state
      }
    case START_FETCH:
      return {
        ...state,
        isFetching: true,
        verificationEmail: action.email
      }
    case SIGN_IN_FAILURE:
      return {
        ...state,
        signInError: true,
        isFetching: false
      }
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        signInError: false,
        tokens: action.tokens,
        signedIn: true,
        isFetching: false,
        userInfo: action.userInfo
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpError: false,
        isFetching: false,
      }
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signUpError: action.message,
        isFetching: false
      }
    case SIGN_OUT:
      return {}
    case CHANGE_VIEW:
      return {
        ...state,
        view: action.view
      }
    case REFRESH_TOKENS:
      return {
        ...state,
        tokens: action.newTokens
      }
    case USER_INFO_UPDATING:
      return {
        ...state,
        userInfoUpdating: false,
        userInfo: {
          ...state.userInfo,
          email: action.email,
          first_name: action.first_name,
          last_name: action.last_name,
          phone_number: action.phone_number,
          allow_marketing: action.allow_marketing,
          iban: action.iban
        },
        userInfoUpdateError: null
      }
    case STOP_USER_INFO_UPDATE:
      return {
        ...state,
        userInfoUpdating: false,
        userInfoUpdateError: action.message
      }
    case START_USER_INFO_UPDATE:
      return {
        ...state,
        userInfoUpdating: true
      }
    default:
      return state
  }
}
