import React, { Fragment } from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import Slider from 'rc-slider'
import InputNumber from 'rc-input-number'
import RepresentMoney from '../../utility/represent-money'
import {
  changeSliderValue,
  changePricingPolicy,
  pricingPolicies
} from '../../actions/ticket-sale-steps'
import 'rc-slider/assets/index.css'

class BasePrice extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      sellPrice,
      ticketPrice,
      ticketCode,
      tickets,
      sellIndividually,
      pricingPolicy
    } = this.props
    if (!sellPrice) {
      sellIndividually
        ? dispatch(changeSliderValue(ticketPrice, ticketCode))
        : tickets.forEach(ticket => {
          dispatch(changeSliderValue(ticketPrice, ticket.code))
        })
    }
    if (!pricingPolicy) {
      sellIndividually
        ? dispatch(changePricingPolicy(pricingPolicies.flat, ticketCode))
        : tickets.forEach(ticket => {
          dispatch(changePricingPolicy(pricingPolicies.flat, ticket.code))
        })
    }
  }

  render() {
    const {
      visible,
      dispatch,
      sellPrice,
      sellIndividually,
      ticketCode,
      tickets,
      ticketMaxPrice,
      ticketMinPrice
    } = this.props

    const representMoneyValue = (sellPriceAmount) => {
      let value = !isNaN(sellPriceAmount) ? sellPriceAmount : 0;
      if (value > ticketMaxPrice) value = ticketMaxPrice
      if (value < ticketMinPrice) value = ticketMinPrice
      return value * 100
    }

    const handleSellPriceChange = value => {
      sellIndividually
        ? dispatch(changeSliderValue(value, ticketCode))
        : tickets.forEach(ticket => {
          dispatch(changeSliderValue(value, ticket.code))
        })
    }

    const visibleSellPrice = sellPrice && !isNaN(sellPrice) ? (
      <RepresentMoney sum={representMoneyValue(sellPrice)} eurSuffix />
    ) : (
      I18n.t('tickets.set_price')
    )
    const isNotValidSellPrice = isNaN(sellPrice) || (ticketMinPrice > sellPrice || ticketMaxPrice < sellPrice)

    const formattedPrice = sum => (parseInt(sum, 10) / 100)
      .toFixed(2)
      .toString()
      .replace('.', ',')

    const visibleContent = (
      <Fragment>
        <div className="break-sm" />

        <div className="ticket-sell-price">
          <div className="ticket-sell-price--title">
            <Translate value="tickets.base_price" />
          </div>
          <div className="ticket-sell-price--price">{visibleSellPrice}</div>
        </div>

        <div className="break-md" />
        <div className="text-center">
          <div className="break-sm" />
          <div className="ticket-sell-detail-description">
            <Translate value="tickets.slider_or_input" />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="input-number-container">
              <InputNumber
                className="input-number-container-input"
                min={ticketMinPrice}
                max={ticketMaxPrice}
                parser={input => input.replace(/[^\d.-]/g, '')}
                step={0.1}
                value={!!sellPrice ? sellPrice : ticketMinPrice}
                onChange={handleSellPriceChange}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="input-number-container-input-error">
              {isNotValidSellPrice &&
                <span className="input-number-container-input-error-text">
                  <Translate value="tickets.price_between" min={formattedPrice(ticketMinPrice * 100)} max={formattedPrice(ticketMaxPrice * 100)}></Translate>
                </span>
              }
            </div>
          </div>
          <div className="col-sm-12">
            <Slider
              min={ticketMinPrice}
              max={ticketMaxPrice}
              step={0.1}
              value={sellPrice}
              onChange={value => {
                sellIndividually
                  ? dispatch(changeSliderValue(value, ticketCode))
                  : tickets.forEach(ticket => {
                    dispatch(changeSliderValue(value, ticket.code))
                  })
              }}
            />
            <div className="slider-number-container-input-values">
              <div className="text-left">
                <div className="ticket-sell-min-max-price">
                  {`${ticketMinPrice.toFixed(2)} €`}
                </div>
              </div>
              <div className="text-right">
                <div className="ticket-sell-min-max-price">
                  {`${ticketMaxPrice.toFixed(2)} €`}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <div className="break-sm" />
          <div className="ticket-sell-detail-description">
            <Translate value="tickets.base_price_max" />
          </div>
          <div className="ticket-sell-detail-description">
            <Translate value="tickets.own_share_description" />
          </div>
        </div>

        {/*

        THIS CODE IS FOR DIFFERENT PRICING PLANS
        FUNCTIONALITY WAS IMPLEMENTED BUT LATER CHOOSED
        TO HIDE

        CODE LEFT HERE IN CASE THIS WILL BE RETURNED

        <p className="text-left">
          <Translate value="tickets.pricing_model" />
        </p>

        <div className="row">
          <div className="col-sm-4">
            <div
              className={`${
                pricingPolicy === pricingPolicies.flat
                  ? 'ticket-sell-price-plan-box-selected'
                  : 'ticket-sell-price-plan-box'
              } cursor-pointer`}
              onClick={() => {
                sellIndividually
                  ? dispatch(
                      changePricingPolicy(pricingPolicies.flat, ticketCode)
                    )
                  : tickets.forEach(ticket => {
                      dispatch(
                        changePricingPolicy(pricingPolicies.flat, ticket.code)
                      )
                    })
              }}
            >
              <FontAwesomeIcon icon={faLongArrowAltRight} size="3x" />
              <h3>
                <Translate value="tickets.flat" />
              </h3>
              <p>
                <Translate value="tickets.flat_desc" />
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className={`${
                pricingPolicy === pricingPolicies.rising
                  ? 'ticket-sell-price-plan-box-selected'
                  : 'ticket-sell-price-plan-box'
              } cursor-pointer`}
              onClick={() => {
                sellIndividually
                  ? dispatch(
                      changePricingPolicy(pricingPolicies.rising, ticketCode)
                    )
                  : tickets.forEach(ticket => {
                      dispatch(
                        changePricingPolicy(pricingPolicies.rising, ticket.code)
                      )
                    })
              }}
            >
              <FontAwesomeIcon
                icon={faLongArrowAltRight}
                size="3x"
                rotation={315}
              />
              <h3>
                <Translate value="tickets.rising" />
              </h3>
              <p>
                <Translate value="tickets.rising_desc" />
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className={`${
                pricingPolicy === pricingPolicies.declining
                  ? 'ticket-sell-price-plan-box-selected'
                  : 'ticket-sell-price-plan-box'
              } cursor-pointer`}
              onClick={() => {
                sellIndividually
                  ? dispatch(
                      changePricingPolicy(pricingPolicies.declining, ticketCode)
                    )
                  : tickets.forEach(ticket => {
                      dispatch(
                        changePricingPolicy(
                          pricingPolicies.declining,
                          ticket.code
                        )
                      )
                    })
              }}
            >
              <FontAwesomeIcon
                icon={faLongArrowAltRight}
                rotation={45}
                size="3x"
              />
              <h3>
                <Translate value="tickets.declining" />
              </h3>
              <p>
                <Translate value="tickets.declining_desc" />
              </p>
            </div>
          </div>
        </div>

        <div className="break-md" />*/}

        <div className="break-sm" />
        <div className="row ticket-sell-step-border" />
      </Fragment >
    )
    return <Fragment>{visible && visibleContent}</Fragment>
  }
}

export default BasePrice
