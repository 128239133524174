import React, { useState } from 'react'
import * as API from '../../utility/api'
import BasketBox from './basket-box'

const Baskets = ({ baskets }) => {
  const sortedBaskets = []
  console.log('baskets', baskets)
  for(const basket of baskets) {
    if(basket.payments && basket.payments.length > 0) {
      basket.payments.sort((a,b) => {
        const date1 = new Date(a.payed_at)
        const date2 = new Date(b.payed_at)
        return date1 - date2
      })
      basket.payment = basket.payments[0]
    } else {
      basket.payment = {}
    }
    sortedBaskets.push(basket)
  }

  return sortedBaskets.map(basket => (
    <BasketBox key={basket.id} {...basket} />
  ))
}

const BASKETS_URL = 'admin/orders'

const FindBaskets = () => {
  const [data, setData] = useState(null)
  const [reference, setReference] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [tel, setTel] = useState('')
  const [eventId, setEventId] = useState('')
  const [paymentStatus, setPaymentStatus] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState([])

  const getData = () => {
    setLoading(true)

    API.GET(BASKETS_URL, {
      reference,
      code,
      name,
      email,
      tel,
      paymentStatus,
      eventId
    })
      .then(({data}) => {
        console.log(data)
        setData(data.orders)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(['Tilausten hakeminen epäonnistui', ...error])
      })
  }

  const submitForm = e => {
    e.preventDefault()

    getData()
  }

  return (
    <div>
      <div>
        <h2>Etsi tilauksia</h2>

        <form onSubmit={submitForm}>
          <div
            className="management--form--box"
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'baseline'
            }}
          >
            <div>
              <h3>Ostoskorin tiedot</h3>
            </div>

            <p>Määrittele, millaisia tilauksia haluat hakea.</p>

            <div style={{ display: 'flex' }}>
              <div>
                <label>Lippukoodi</label>
                <input
                  type="text"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                />
              </div>

              <div>
                <label>Checkout referenssi</label>
                <input
                  type="text"
                  value={reference}
                  onChange={e => setReference(e.target.value)}
                />
              </div>

              <div>
                <label>Tapahtuma ID</label>
                <input
                  type="number"
                  value={eventId}
                  onChange={e => setEventId(e.target.value)}
                />
              </div>

              <div>
                <label>Maksun status</label>
                <select
                  value={paymentStatus}
                  onChange={e => setPaymentStatus(e.target.value)}
                >
                  <option value="" />
                  <option value="REJECTED">Hylätty</option>
                  <option value="PAID">Maksettu</option>
                  <option value="VERIFIED">Vahvistettu</option>
                  <option value="PENDING">Ei maksettu</option>
                  <option value="CANCELLED">Peruttu</option>
                  <option value="FAILED">Epäonnistunut</option>
                  <option value="REFUNDED">Hyvitetty</option>
                </select>
              </div>
              {/* 
              <div>
                <label>SEPA-status</label>
                <select
                  value={sepaStatus}
                  onChange={e => setSepaStatus(e.target.value)}
                >
                  <option value="" />
                  <option value="1">Tilitetty</option>
                  <option value="0">Tilittämätön</option>
                </select>
              </div> */}
            </div>

            <div>
              <h3>Käyttäjän tiedot</h3>
            </div>

            <p>
              Määrittele kenen tilauksia haluat hakea. Jos kentät ovat tyhjät,
              tulee kaikki tilaukset.
            </p>

            <div style={{ display: 'flex' }}>
              <div>
                <label>Nimi</label>
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>

              <div>
                <label>Sähköposti</label>
                <input
                  type="text"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label>Puhelinnumero</label>
                <input
                  type="number"
                  value={tel}
                  onChange={e => setTel(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="break-md" />

          <div>
            <button disabled={loading}>
              {loading ? 'haetaan tilausta' : 'etsi tilaus'}
            </button>
          </div>
        </form>
      </div>

      <div className="break-lg" />

      {data && (
        <div>
          <Baskets baskets={data} />
        </div>
      )}

      {data && data.length === 0 && <div>Tilauksia ei löytynyt</div>}
    </div>
  )
}

const BasketManagement = () => {
  return <div>{FindBaskets()}</div>
}

export default BasketManagement
