import React from 'react'
import { Translate } from 'react-redux-i18n'
import moment from 'moment'
import RepresentMoney from '../../utility/represent-money'

const SummaryFooter = props => {
  const { sellPrice, selectedDate, ticketMinPrice, ticketMaxPrice } = props

  // const pricingPolicies = ['flat', 'rising', 'declining']

  const representMoneyValueWithModifier = (mod) => {
    let value = !isNaN(sellPrice) ? sellPrice : 0;
    if (value > ticketMaxPrice) value = ticketMaxPrice
    if (value < ticketMinPrice) value = ticketMinPrice
    return value * mod * 100;
  }

  return (
    <div className="row">
      <div className="break-md" />
      <div className="col-sm-4">
        <div className="summary-title">
          <Translate value="tickets.total_price" />
        </div>
        <div className="summary-field">
          <RepresentMoney sum={representMoneyValueWithModifier(1)} eurSuffix />
        </div>
      </div>

      <div className="col-sm-4">
        <div className="summary-title">
          <Translate value="tickets.own_share" />*
        </div>
        <div className="summary-field">
          <RepresentMoney sum={representMoneyValueWithModifier(0.9)} eurSuffix />
        </div>
      </div>

      {/*

        FOR PRICE PLANS WHICH ARE HIDDEN FOR THE TIME BEING

      <div className="col-sm-3">
        <div className="row summary-title">Hinnoittelumalli</div>
        <div className="row summary-field">
          {pricingPolicies[pricingPolicy] && (
            <Translate value={'tickets.' + pricingPolicies[pricingPolicy]} />
          )}
        </div>
      </div>



        <div className="col-sm-3">
        <div className="row summary-title">Maalihinta</div>
        <div className="row summary-field">
          {maxOrMinPrice && maxOrMinPrice + ' €'}
        </div>
      </div>
      */}

      <div className="col-sm-4">
        <div className="summary-title">
          <Translate value="tickets.last_sale_date" />
        </div>
        <div className="summary-field">
          {selectedDate ? moment(selectedDate).format('D.MM.YYYY') : ''}
        </div>
      </div>
    </div>
  )
}

export default SummaryFooter
