import React from 'react'

class ButtonWithConfirmation extends React.Component {
  state = {
    pressed: false
  }

  handleCleanClick = () => {
    this.setState({
      pressed: true
    })
  }

  handleCancel = () => {
    this.setState({
      pressed: false
    })
  }

  render() {
    const { onClick, yesText, noText, buttonText, className } = this.props

    const cleanContent = (
      <button
        onClick={this.handleCleanClick}
        className={this.props.className ? className : ''}
      >
        {buttonText}
      </button>
    )

    const dirtyContent = (
      <React.Fragment>
        <button onClick={onClick} className="button-wide">
          {yesText}
        </button>
        <button
          className="btn-blank secondary-button button-wide"
          onClick={this.handleCancel}
        >
          {noText}
        </button>
      </React.Fragment>
    )

    return this.state.pressed ? dirtyContent : cleanContent
  }
}

export default ButtonWithConfirmation
