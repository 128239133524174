import React from 'react'
import { connect } from 'react-redux'
import {socialSignIn, socialSignInError} from '../actions/users'

class SocialLogin extends React.Component {

  render() {
    const pathParams = this.props.location.hash;
    if (pathParams) {
      const params = pathParams.split('&');
      if (params && params[0] && params[1]) {
        const access_token = params[0].split('=')[1];
        const id_token = params[1].split('=')[1];
        this.props.dispatch(socialSignIn({ access_token, id_token }));
        return <div />
      }
    }
    this.props.dispatch(socialSignInError());
    return <div />
  }
}

export default connect()(SocialLogin)
