import { I18n, Translate } from "react-redux-i18n";

import React from "react";
import SubmitButton from "./../submit-button";
import ValidatedInput from "./../validated-input";
import { connect } from "react-redux";
import { signUp } from "../../actions/users";
import { push } from "connected-react-router";

class RegisterForm extends React.Component {
  state = {
    email: "",
    password: "",
    passwordConfirm: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    valid: {
      password: false,
      passwordConfirm: false,
    },
    termsAccepted: false,
  };

  handleChange = target => {
    return e => {
      this.setState({
        [target]: e.target.value,
      });
    };
  };

  handleSubmit = e => {
    e.preventDefault();

    const { dispatch } = this.props;
    const { email, password, birthdate, phoneNumber, firstName, lastName, allowMarketing, termsAccepted} = this.state;

    dispatch(signUp(email, password, birthdate, phoneNumber, firstName, lastName, allowMarketing, termsAccepted));

    this.setState({
      email: "",
      password: "",
      allowMarketing: false,
    });
  };

  validPassword = () => {
    this.setState({
      valid: { ...this.state.valid, password: true },
    });
  };

  inValidPassword = () => {
    this.setState({
      valid: { ...this.state.valid, password: false },
    });
  };

  validPasswordConfirmation = () => {
    this.setState({
      valid: { ...this.state.valid, passwordConfirm: true },
    });
  };

  inValidPasswordConfirmation = () => {
    this.setState({
      valid: { ...this.state.valid, passwordConfirm: false },
    });
  };

  preventDefault = e => {
    e.preventDefault();
  };

  checkTerms = () => {
    this.setState({ termsAccepted: !this.state.termsAccepted });
  };

  checkMarketing = e => {
    this.setState({ allowMarketing: e.target.checked });
  };

  render() {
    const { email, password, lastName, firstName, phoneNumber, passwordConfirm, valid, termsAccepted } = this.state;
    const { error, dispatch } = this.props;
    const facebookAuthUrl = process.env.REACT_APP_FACEBOOK_AUTH_URL;

    return (
      <div className="user-info-form">
        <h2>
          <Translate value="registration.title" />
        </h2>

        {error && (
          <div className="error-message">
            <Translate value={`registration.${error}`} />
          </div>
        )}

        <form onSubmit={this.handleSubmit}>
          <div className="break-sm" />

          <label htmlFor="loginEmail">
            <Translate value="registration.email" />
          </label>
          <input
            type="email"
            id="loginEmail"
            value={email}
            className="user-info"
            onChange={this.handleChange("email")}
            required
          />

          <div className="break-md" />

          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="firstName">
                <Translate value="registration.given_name" />
              </label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                className="user-info"
                onChange={this.handleChange("firstName")}
                required
              />
            </div>

            <div className="col-sm-6">
              <label htmlFor="lastName">
                <Translate value="registration.family_name" />
              </label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                className="user-info"
                onChange={this.handleChange("lastName")}
                required
              />
            </div>
          </div>

          <div className="break-md" />

          <label htmlFor="phoneNumber">
            <Translate value="user.phone_number" />
          </label>

          <input
            type="number"
            id="phoneNumber"
            value={phoneNumber}
            className="user-info"
            onChange={this.handleChange("phoneNumber")}
            required
            autoComplete={"tel"}
          />

          <div className="break-md" />

          <label htmlFor="registerPassword">
            <Translate value="registration.password" />
          </label>
          <ValidatedInput
            onValid={this.validPassword}
            inValid={this.inValidPassword}
            validator={new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})").test(password)}
            valid={valid.password}
            value={password}
            describe={I18n.t("registration.password_requirements")}
          >
            <input
              type="password"
              id="registerPassword"
              value={password}
              className="user-info"
              onChange={this.handleChange("password")}
              onCopy={this.preventDefault}
              required
              autoComplete={"new-password"}
            />
          </ValidatedInput>

          <div className="break-md" />

          <label htmlFor="registerPasswordConfirmation">
            <Translate value="registration.confirm_password" />
          </label>
          <ValidatedInput
            onValid={this.validPasswordConfirmation}
            inValid={this.inValidPasswordConfirmation}
            validator={password.length > 0 && passwordConfirm.length > 0 && password === passwordConfirm}
            valid={valid.passwordConfirm}
            value={passwordConfirm}
            describe={I18n.t("registration.passwords_dont_match")}
          >
            <input
              type="password"
              id="registerPasswordConfirmation"
              value={passwordConfirm}
              onCopy={this.handleCopy}
              className="user-info"
              onChange={this.handleChange("passwordConfirm")}
              required
              autoComplete={"new-password"}
            />
          </ValidatedInput>

          <div className="break-lg" />
          <div className="check-terms-container">
            <label htmlFor="marketing-input">
              <input id="marketing-input" type="checkbox" onChange={this.checkMarketing} />
              <Translate value="registration.confirm_marketing_licence" />
            </label>
          </div>

          <div className="check-terms-container">
            <label htmlFor="terms-input">
              <input id="terms-input" type="checkbox" onChange={this.checkTerms} checked={termsAccepted} />
              <Translate value="registration.confirm_terms" />
              <a
                href={this.props.locale === "fi" ? "/static/tietosuojaseloste.pdf" : "/static/privacypolicy.pdf"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Translate value="registration.privacy_policy" />
              </a>
              <Translate value="registration.and" />
              <a href="/termsofuse" target="_blank" rel="noopener noreferrer">
                <Translate value="registration.terms_of_use" />
              </a>
            </label>
          </div>

          <div className="break-lg" />

          <SubmitButton disabled={!(valid.password && valid.passwordConfirm && termsAccepted)} full={true}>
            <Translate value="registration.register" />
          </SubmitButton>
        </form>

        <div className="break-md" />

        <div className="">
          <Translate style={{ textTransform: "uppercase" }} value="registration.or" />
        </div>

        <div className="break-md" />

        <a href={facebookAuthUrl}>
          <button className="loginBtn loginBtn--facebook">
            <Translate value="registration.login_facebook" />
          </button>
        </a>

        <div className="break-md" />

        <div className="row">
          <div
            className="description-text small cursor-pointer"
            onClick={() => dispatch(push("/register/verify"))}
          >
            <Translate value="registration.code_already_available" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.i18n.locale,
});

export default connect(mapStateToProps)(RegisterForm);
